import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Switch.css";

const Switch = ({ name, checked, tabIndex, onChange }) => {
  const handleChange = (event) => {
    const { checked } = event.target;
    // setError(validateCheckbox(validators, checked));
    onChange(checked);
    // console.log("checkbox", checked);
  };
  return (
    <div className="toggle-group">
      <input
        type="checkbox"
        name={name}
        id={name}
        checked={checked}
        tabIndex={tabIndex}
        onChange={handleChange}
      />
      <label htmlFor={name}>
        <span className="aural">Show:</span>
      </label>
      <div className="onoffswitch pull-right" aria-hidden="true">
        <div className="onoffswitch-label">
          <div className="onoffswitch-inner"></div>
          <div className="onoffswitch-switch"></div>
        </div>
      </div>
    </div>
  );
};

Switch.propTypes = {
  checked: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Switch;
