import { FETCH_MAIL_SUCCESS, FETCH_MAIL_FAIL } from "../actions/actionTypes";
  
  const initialState = {
    user: [],
    error: "",
    email: null,
  };
  
  const userMailReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_MAIL_SUCCESS:
        return { ...state, email: true, user: action.data };
      case FETCH_MAIL_FAIL:
        return { ...state, email: false, user: action.data };
      default:
        return state;
    }
  }

  export default userMailReducer;