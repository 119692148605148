import React, { useState, useEffect, useRef } from "react";
import demographicStyles from "./EditPatientDetails.module.css";
import InputField from "../../custom/inputfield/InputField";
import editIcon from "../../../images/edit-icon.svg";
import Button from "../../custom/button/Button";
import Dropdown from "../../custom/dropdown/Dropdown";
import useAxios from "../../../axiosinstance";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useTranslation from "../../customHooks/translations";
import { yearDate } from "../../../utilities/DateFormate";
import { Validators } from "../../../utilities/Validator";
import { UserDateFormat } from "../../../utilities/DateFormate";
import GoogleAddress from "../../custom/google-api-address/GoogleAddress";
import Message from "../../custom/toster/Message";
function EditPatientDetails() {
  const translation = useTranslation();

  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const { akid } = useParams();
  const axiosInstance = useAxios();
  const [address, setAddress] = useState("");
  const [profile, setProfile] = useState([]);
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const [countryCodeErr, setCountryCodeErr] = useState(false);
  const [mobile, setMobile] = useState("");
  const [mobileErr, setMobileErr] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [status, setStatus] = useState("");
  const [patientInfo, setPatientInfo] = useState([]);
  const [kinName, setKinName] = useState("");
  const [kinPhone, setKinPhone] = useState("");
  const [kinPhoneErr, setKinPhoneErr] = useState(false);
  const [kinRelationship, setKinRelationship] = useState("");
  const [gpName, setGpName] = useState("");
  const [gpPhone, setGpPhone] = useState("");
  const [gpPhoneErr, setGpPhoneErr] = useState(false);
  const [gpPractice, setGpPractice] = useState("");
  const [gpAddress, setGpAddress] = useState("");
  const [medicare, setMedicare] = useState("");
  const [medicareErr, setMedicareErr] = useState(false);
  const [medicareRef, setMedicareRef] = useState("");
  const [medicareRefErr, setMedicareRefErr] = useState(false);
  const [medicareExpiry, setMedicareExpiry] = useState("");
  const [medicareExpiryErr, setMedicareExpiryErr] = useState(false);
  const [health, setHealth] = useState("");
  const [membership, setMembership] = useState("");
  const [membershipErr, setMembershipErr] = useState(false);
  const [dva, setDva] = useState("");
  const [defence, setDefence] = useState("");
  const [workCover, setWorkCover] = useState("");
  const [employer, setEmployer] = useState("");
  const [, setThirdPartyClaimNumber] = useState("");
  // const [thirdPartyName, setThirdPartyName] = useState("");
  const [DVANumber, setDVANumber] = useState("");
  const [lastV, setLastV] = useState("");
  const mobileNumRef = useRef();
  const countryCodeRef = useRef();
  const [experr, setHomeerr] = useState(false);
  const [addressError, setaddressError] = useState(false);
  console.log(experr, "experr");
  useEffect(() => {
    const getData = async () => {
      // const response = await axiosInstance.current.get(
      //   `extapp/doctors/getPatientDetailsForDoctor?patient_id=${window.atob(
      //     akid
      //   )}&doctor_id=${login.ing_ak_id}&use_for=doctor`
      // );
      const patient = {
        "account_status": 1,
        "address": "17 Welwyn Cres, Coorparoo QLD 4151, Australia",
        "ak_id": "AKPT000163",
        "country_code": "+61",
        "created_at": "2023-05-29T04:21:53.330Z",
        "dateFormat": "1",
        "deleted_at": "0",
        "dob": "1993-05-09T20:00:00.000Z",
        "email": "jagan.mode259@gmail.com",
        "firstname": "Test",
        "gender": "1",
        "home_phone": "",
        "last_visit": "2023-07-11T02:46:36.273Z",
        "lastname": "Leo",
        "mobile": "9912399123",
        "postal_code": "4151",
        "profile_img": "",
        "sub_id": "2e48954f-429f-4879-9125-6ca73982b85f",
        "timeformat": "2",
        "times_visited": 95,
        "timezone": "Australia/Brisbane",
        "updated_at": "2023-07-11T02:34:13.669Z",
        "user_language": "en",
        "_id": "6473fb0046372d001e6850ff",
      };
      const patientInfo = {
        "ak_id": "AKPT000163",
        "created_at": "2023-05-29T04:21:53.330Z",
        "gp_address": "Welserstraße 17, 90489 Nürnberg, Germany",
        "gp_name": "Test GP",
        "gp_practice_name": "Test Practice",
        "kin_name": "Test",
        "kin_phone": "9912399123",
        "kin_relationship": "Friend",
        "patient_id": "AKPT000163",
        "suburb": "Coorparoo",
        "updated_at": "2023-05-29T04:21:53.330Z",
        "_id": "6474286146372d001e685100",
      };
      setProfile(patient);
      setPatientInfo(patientInfo);
      // setProfile(response.data.data[0].patient);
      // setPatientInfo(response.data.data[0].patientInfo);
    };
    getData();
  }, []);

  useEffect(() => {
    getCountryData();
    if (profile) {
      setEmail(profile.email);
      setCountryCode(profile.country_code);
      setMobile(profile.mobile);
      setAddress(profile.address);
      setLastV(profile.last_visit);
    }

    if (patientInfo) {
      setKinName(patientInfo.kin_name);
      setKinPhone(patientInfo.kin_phone);
      setKinRelationship(patientInfo.kin_relationship);
      setGpName(patientInfo.gp_name);
      setGpPhone(patientInfo.gp_phone);
      setGpPractice(patientInfo.gp_practice_name);
      setGpAddress(patientInfo.gp_address);
      setMedicare(patientInfo.card_number);
      setMedicareExpiry(patientInfo.expiry);
      setMedicareRef(patientInfo.ref);
      setHealth(patientInfo.health_fund);
      setMembership(patientInfo.membership_number);
      selectClaimType(patientInfo.claim_number);
      selectDVA(patientInfo.dva_card);
      selectDefence(patientInfo.defence_personnel);
      setWorkCover(patientInfo.work_cover);
      setEmployer(patientInfo.employer);
      setThirdPartyClaimNumber(patientInfo.claim_number);
      setDVANumber(patientInfo.number);
    }
  }, [profile]);

  const changeAddress = (event) => {
    setAddress(event);
  };
  const handleKinPhone = (e, err) => {
    setKinPhone(e);
    setKinPhoneErr(err);
  };
  // const handleGPPhone = (e, err) => {
  //   setGpPhone(e);
  //   setGpPhoneErr(err);
  // };
  const handleMedicare = (e, err) => {
    setMedicare(e);
    setMedicareErr(err);
  };
  const handleRef = (e, err) => {
    setMedicareRef(e);
    setMedicareRefErr(err);
  };
  const handleMembership = (e, err) => {
    setMembership(e);
    setMembershipErr(err);
  };
  // const changeEmail = (event) => {
  //   setEmail(event);
  // };
  const handleMedicalExp = (e, err) => {
    setMedicareExpiry(e);
    setMedicareExpiryErr(err);
  };
  const changeCountryCode = (event, err) => {
    setCountryCode(event);
    setCountryCodeErr(err);
  };
  const changeMobile = (event, err) => {
    setMobile(event);
    setMobileErr(err);
  };

  const toggleHandler = () => {
    setReadOnly((readOnly) => !readOnly);
  };
  const selectClaimType = (event) => {
    setStatus(event);
  };
  const selectDVA = (event) => {
    setDva(event);
  };
  const selectDefence = (event) => {
    setDefence(event);
  };

  const getCountryData = async () => {
    try {
      const result = await axiosInstance.current.get(`extapp/countrylist`);
      if (result.status == "200") {
        setCountry(result.data.data);
      }
    } catch (err) {
      // setMessage(err.message || "Unexpected Error!");
      console.log(err.Messages);
      // setMessageType("error");
    } finally {
      console.log("api response finally");
    }
  };
  const json = JSON.stringify({
    data: {
      users: {
        mobile: mobile,
        address: address,
        country_code: countryCode,
      },
      user_information: {
        card_number: medicare,
        claim_number: status,
        defence_personnel: defence,
        dva_card: dva,
        number: DVANumber,
        gp_address: gpAddress,
        gp_name: gpName,
        gp_phone: gpPhone,
        gp_practice_name: gpPractice,
        health_fund: health,
        kin_name: kinName,
        kin_phone: kinPhone,
        kin_relationship: kinRelationship,
        membership_number: membership,
        expiry: medicareExpiry,
        ref: medicareRef,
        work_cover: workCover,
        employer: employer,
      },
    },
  });

  const handleSaveTask = async () => {
    try {
      const result = await axiosInstance.current.put(
        `extapp/doctors/updatePatientProfile?patient_id=${window.atob(
          akid
        )}&doctor_id=${login.ing_ak_id}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (result) {
        setReadOnly((readOnly) => !readOnly);


        setMessage('Update successfuly'
        );
        setMessageType("success");
        setTimeout(() => {
          setMessage(false);
        }, 3000);
      }
    } catch (err) {
      console.log(err.message);
      // setMessage(err.message);
      // setMessageType("error");
      setMessage('Something went wrong'
        );
        setMessageType("error");
        setTimeout(() => {
          setMessage(false);
        }, 3000);
    } finally {
      console.log("api response finally");
    }
  };

  const handleSubmit = () => {
    // alert("hello");
    if (!mobile) {
      mobileNumRef.current.errUpdate();
    }
    if (!mobile || mobileErr) {
      mobileNumRef.current.errUpdate();
    }
    if (countryCode === "") {
      countryCodeRef.current.errUpdate();
    }
    if (mobile === "") {
      mobileNumRef.current.errUpdate();
    }
    if (address === "") {
      setaddressError(true);
    }
    if (experr) {
      return
    }
    // console.log(medicareExpiry, "medicareExpiry");
    // if (medicareExpiry === "") {
    //   setHomeerr(false);
    // } else if (experr) {
    //   setHomeerr(true);
    //   // return;
    // }

    if (
      countryCode === "" ||
      !mobile ||
      mobileErr ||
      countryCodeErr ||
      kinPhoneErr ||
      gpPhoneErr ||
      medicareErr ||
      medicareRefErr ||
      medicareExpiryErr ||
      // experr ||
      membershipErr
    ) {
      // countryCodeRef.current.errUpdate()
    } else {
      handleSaveTask();
    }
  };

  let expdate;
  const expriy_format = (value) => {
    // setexpdate(value)
    if (value === undefined) {
      expdate = "";
    } else {
      expdate = value;
    }
    const expDateFormatter =
      expdate.replace(/\//g, "").substring(0, 2) +
      (expdate.length > 2 ? "/" : "") +
      expdate.replace(/\//g, "").substring(2, 4);

    return expDateFormatter;
  };
  let x =
    medicareExpiry === undefined
      ? medicareExpiry === undefined
      : medicareExpiry.length === 0;
  const onlyNumbersAndParentheses = /^(0[1-9]|1[0-2])\/[0-9]{2}$/;
  useEffect(() => {
    if (onlyNumbersAndParentheses.test(medicareExpiry) || x == true) {
      setHomeerr(false);
    } else {
      setHomeerr(true);
    }
  }, [medicareExpiry]);

  let exp = medicareExpiry === undefined ? "04/23" : medicareExpiry;
  let expiry1 = exp.slice(0, 5);
  const [expiryMonth, expiryYear] = expiry1.split("/");
  const expiryYearNumber = parseInt(`20${expiryYear}`);
  const expiryDateObj = new Date(expiryYearNumber, expiryMonth - 1);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const earliestExpiryYear =
    currentYear + (currentDate.getMonth() > expiryMonth - 1 ? 1 : 0);

  useEffect(() => {
    if (medicareExpiry !== "" && medicareExpiry !== undefined) {

      if (expiryYearNumber < earliestExpiryYear) {
        setHomeerr(true);
      } else {
        setHomeerr(false);
      }
    }
  }, [medicareExpiry]);

  console.log(readOnly, "res");
  return (
    <div
      className={`patient_demographic ${demographicStyles.patient_demographic
        } ${readOnly ? "form_disabled" : null}`}
    >
      <div className="whitebox">
        {message && <Message message={message} type={messageType} />}

        <div className="box-header row">
          <div className="col-md-6 col-sm-6">
            <p className=" f_12">
              <strong className="f_700 ">
                {"Last Visit"}•{" "}
                {UserDateFormat(
                  lastV,
                  login.ing_date_formate,
                  login.ing_timezone
                )}
              </strong>{" "}
            </p>
          </div>
          <div className="col-md-6 col-sm-6 text-end">
            {/* <Button
              value="Sync Info"
              width={`110px`}
              height={`36px`}
              buttonStyle={`btn_fill mr_20`}
            /> */}
            <span onClick={toggleHandler}>
              <img className={``} src={editIcon} alt="Icon" />
            </span>
          </div>
        </div>
        <div className="box-content1">
          <div className="row">
            <div className="col-md-7 col-sm-7">
              <div className="row mrg_0">
                <div
                  className={`col-md-6 col-sm-6 ${demographicStyles.profile__left__sec}`}
                >
                  <span className={`${demographicStyles.profile_img}`}>
                    <label
                      className={`user-letter large text-capitalize ${demographicStyles.user_letter}`}
                    >
                      {profile.firstname && profile.firstname.slice(0, 1)}
                    </label>
                  </span>
                  <div>
                    {/* <p className={`${demographicStyles.red_txt}`}>
                      *Patient has not consent
                    </p> */}
                    <label className={`${demographicStyles.profile_name}`}>
                      {profile.firstname} {profile.lastname}
                    </label>
                    <label className={`${demographicStyles.profile_age}`}>
                      <strong>Age: {yearDate(profile.dob)} Year</strong>
                    </label>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className={`${demographicStyles.detail__txt1}`}>
                    <strong>{"DOB"}: </strong>{" "}
                    {UserDateFormat(
                      profile.dob,
                      login.ing_date_formate,
                      login.ing_timezone
                    )}
                  </div>
                  <div className={`${demographicStyles.detail__txt1}`}>
                    <strong>{"Sex"}:</strong>{" "}
                    {String(profile.gender) === "1" && "Male"}
                    {String(profile.gender) === "2" && "Female"}
                    {String(profile.gender) === "3" &&
                      "Do not want to disclose"}
                  </div>
                  <div className={`${demographicStyles.detail__txt1}`}>
                    <strong>{"AKPT ID"}: </strong>
                    {profile.ak_id}
                  </div>
                  <div className={`${demographicStyles.detail__txt1}`}>
                    <strong>{"Account Status"} : </strong>
                    {profile.account_status === 1 && "Activated "}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-5 col-sm-5 sec__details_three">
              <div className={`${demographicStyles.detail__txt}`}>
                <strong>{"Mobile"}:</strong>
                <div className="d__flex">
                  {readOnly ? (
                    <span className="ms_3 me_2">{countryCode} </span>
                  ) : (
                    <Dropdown
                      data={country}
                      styleClass="mxw_100 br_10 w-auto me-3 ms-1 "
                      value={countryCode}
                      ref={countryCodeRef}
                      placeholder="Select"
                      onChange={changeCountryCode}
                      disabled={readOnly}
                      validators={[
                        {
                          check: Validators.required,
                          message: "Please enter the required field",
                        },
                      ]}
                    />
                  )}

                  <p className="mb-0">
                    {readOnly ? (
                      mobile
                    ) : (
                      <InputField
                        label=""
                        value={mobile}
                        type="text"
                        placeholder="_ _ _ _ _ _ _ _ _ "
                        ref={mobileNumRef}
                        formGroupClass="d-inline-block mobile__num"
                        onChange={changeMobile}
                        disabled={readOnly}
                        validators={[
                          {
                            check: Validators.required,
                            message: "Please enter the required field",
                          },
                          {
                            check: Validators.number,
                            message: "Only Numbers are allowed",
                          },
                          {
                            check: Validators.maxmobile,
                            message: "Maximum 10 Numbers are allowed",
                          },
                          // {
                          //   check: Validators.minmobile,
                          //   message: "Maximum 10 Numbers are allowed",
                          // },
                        ]}
                      />
                    )}
                  </p>
                </div>
              </div>
              <div className={`${demographicStyles.detail__txt}`}>
                <strong>{"Email"}:</strong>
                <p className="mb-0 ms-3">{email}</p>
              </div>
              <div
                className={`${demographicStyles.detail__txt}`}
                style={{ marginTop: "0px" }}
              >
                <strong>{"Address"}: </strong>
                {readOnly ? (
                  <span className="ms-3 me-2">{address} </span>
                ) : (
                  <div className="posit__parent" style={{ width: "63%" }}>
                    <GoogleAddress
                      onAddressChange={changeAddress}
                      valid={addressError}
                      value={address}
                      styleClass={"mxw_100 ms-2"}
                    />
                  </div>
                )}
                {/* <InputField
                  label=""
                  value={address}
                  type="text"
                  placeholder=""
                  onChange={changeAddress}
                  disabled={readOnly}
                  styleClass="mxw_100 ms-2"
                  formGroupClass="mxw_100"
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${demographicStyles.all__grid_detail}`}>
        <div
          className={`${demographicStyles.grid__item} ${demographicStyles.grid_item_30}`}
        >
          <div className={`row ${demographicStyles.card__head}`}>
            <div className="col-md-8 col-sm-8">
              <h3 className={`row ${demographicStyles.graphic__title}`}>
                {"Next of Kin"}
              </h3>
            </div>
            <div className="col-md-4 col-sm-4"></div>
          </div>
          <div className={`row ${demographicStyles.card__content}`}>
            <div className={`${demographicStyles.detail__txt}`}>
              <strong>{"Name"}: </strong>{" "}
              <InputField
                label=""
                value={kinName}
                type="text"
                placeholder=""
                onChange={setKinName}
                disabled={readOnly}
              />
            </div>
            <div className={`${demographicStyles.detail__txt}`}>
              <strong>{"Phone"}: </strong>{" "}
              <InputField
                label=""
                value={kinPhone}
                type="text"
                placeholder=""
                onChange={handleKinPhone}
                disabled={readOnly}
                validators={[
                  {
                    check: Validators.number,
                    message: "Only Numbers are allowed",
                  },
                  {
                    check: Validators.maxmobile,
                    message: "Maximum 10 Numbers are allowed",
                  },
                  // {
                  //   check: Validators.minmobile,
                  //   message: "Maximum 10 Numbers are allowed",
                  // },
                ]}
              />
            </div>
            <div className={`${demographicStyles.detail__txt}`}>
              <strong>{"Relationship"}: </strong>{" "}
              <InputField
                label=""
                value={kinRelationship}
                type="text"
                placeholder=""
                onChange={setKinRelationship}
                disabled={readOnly}
              />
            </div>
          </div>
        </div>
        <div
          className={`${demographicStyles.grid__item} ${demographicStyles.grid_item_40}`}
        >
          <div className={`row ${demographicStyles.card__head}`}>
            <div className="col-md-8 col-sm-8">
              <h3 className={`row ${demographicStyles.graphic__title}`}>
                {"GP Details"}
              </h3>
            </div>
            <div className="col-md-4 col-sm-4"></div>
          </div>
          <div className={`row ${demographicStyles.card__content}`}>
            <div className={`${demographicStyles.detail__txt}`}>
              <strong>{"GP Name"}: </strong>{" "}
              <InputField
                label=""
                value={gpName}
                type="text"
                placeholder=""
                onChange={setGpName}
                disabled={readOnly}
              />
            </div>
            <div className={`${demographicStyles.detail__txt}`}>
              <strong>{"Practice"} </strong>{" "}
              <InputField
                label=""
                value={gpPractice}
                type="text"
                placeholder=""
                onChange={setGpPractice}
                disabled={readOnly}
              />
            </div>
            {/* <div className={`${demographicStyles.detail__txt}`}>
              <strong>{translation.phone}: </strong>{" "}
              <InputField
                label=""
                value={gpPhone}
                type="text"
                placeholder=""
                onChange={handleGPPhone}
                disabled={readOnly}
                validators={[
                  {
                    check: Validators.number,
                    message: "Only Numbers are allowed",
                  },
                  {
                    check: Validators.maxmobile,
                    message: "Maximum 10 Numbers are allowed",
                  },
                  // {
                  //   check: Validators.minmobile,
                  //   message: "Maximum 10 Numbers are allowed",
                  // },
                ]}
              />
            </div> */}
            <div className={`${demographicStyles.detail__txt}`}>
              <strong>{"Address"}: </strong>{" "}
              <InputField
                label=""
                value={gpAddress}
                type="text"
                placeholder=""
                onChange={setGpAddress}
                disabled={readOnly}
              />
            </div>
          </div>
        </div>
        <div
          className={`${demographicStyles.grid__item} ${demographicStyles.grid_item_30}`}
        >
          <div className={`row ${demographicStyles.card__head}`}>
            <div className="col-md-8 col-sm-8">
              <h3 className={`row ${demographicStyles.graphic__title}`}>
                {"Medicare"}
              </h3>
            </div>
            <div className="col-md-4 col-sm-4"></div>
          </div>
          <div className={`row ${demographicStyles.card__content}`}>
            <div className={`${demographicStyles.detail__txt}`}>
              <strong>{"Medicare"}:</strong>{" "}
              <InputField
                label=""
                value={medicare}
                type="text"
                placeholder=""
                onChange={handleMedicare}
                disabled={readOnly}
                validators={[
                  {
                    check: Validators.number,
                    message: "Only Numbers are allowed",
                  },
                  {
                    check: Validators.maxmobile,
                    message: "Maximum 10 Numbers are allowed",
                  },
                  // {
                  //   check: Validators.minmobile,
                  //   message: "Maximum 10 Numbers are allowed",
                  // },
                ]}
              />
            </div>
            <div className={`${demographicStyles.detail__txt}`}>
              <strong>{"Ref"}: </strong>{" "}
              <InputField
                label=""
                value={medicareRef}
                type="text"
                placeholder=""
                onChange={handleRef}
                disabled={readOnly}
                validators={[
                  {
                    check: Validators.number,
                    message: "Only Numbers are allowed",
                  },
                  {
                    check: Validators.max_refnumber,
                    message: "Maximum 1 digit are allowed",
                  },
                ]}
              />
            </div>
            <div className={`${demographicStyles.detail__txt}`}>
              <strong>{"Expiry"}: </strong>{" "}
              <InputField
                label=""
                value={expriy_format(medicareExpiry)}
                type="text"
                placeholder="MM/YY"
                onChange={handleMedicalExp}
                disabled={readOnly}
              />
            </div>

            {experr && (
              <span className="ml_105 text-danger">Invalid Date</span>
            )}


          </div>
        </div>
        <div
          className={`${demographicStyles.grid__item} ${demographicStyles.grid_item_30}`}
        >
          <div className={`row ${demographicStyles.card__head}`}>
            <div className="col-md-8 col-sm-8">
              <h3 className={`row ${demographicStyles.graphic__title}`}>
                {"Private"}
              </h3>
            </div>
            <div className="col-md-4 col-sm-4"></div>
          </div>
          <div className={`row ${demographicStyles.card__content}`}>
            <div className={`${demographicStyles.detail__txt}`}>
              <strong>{"Health Fund"}: </strong>{" "}
              <InputField
                label=""
                value={health}
                type="text"
                placeholder=""
                onChange={setHealth}
                disabled={readOnly}
              />
            </div>
            <div className={`${demographicStyles.detail__txt}`}>
              <strong>{"Membership Number"}: </strong>{" "}
              <InputField
                label=""
                value={membership}
                type="text"
                placeholder=""
                onChange={handleMembership}
                disabled={readOnly}
                validators={[
                  {
                    check: Validators.number,
                    message: "Only Numbers are allowed",
                  },
                  {
                    check: Validators.maxmobile,
                    message: "Maximum 10 Numbers are allowed",
                  },
                  // {
                  //   check: Validators.minmobile,
                  //   message: "Maximum 10 Numbers are allowed",
                  // },
                ]}
              />
            </div>
          </div>
        </div>
        <div
          className={`${demographicStyles.grid__item} ${demographicStyles.grid_item_40}`}
        >
          <div className={`row ${demographicStyles.card__head}`}>
            <div className="col-md-8 col-sm-8">
              <h3 className={`row ${demographicStyles.graphic__title}`}>
                {"Other"}
              </h3>
            </div>
            <div className="col-md-4 col-sm-4"></div>
          </div>
          <div className={`row ${demographicStyles.card__content}`}>
            <div className={`${demographicStyles.detail__txt}`}>
              <strong>{"Claim Type"}: </strong>{" "}
              <Dropdown
                data={[
                  { value: "0", label: "Not Applicable" },
                  { value: "1", label: "Work Cover" },
                  {
                    value: "2",
                    label: "Third Party claim Number",
                  },
                ]}
                styleClass=""
                value={status}
                placeholder="Select"
                onChange={(e) => selectClaimType(e)}
                disabled={readOnly}
              />
            </div>

            {status === "1" && (
              <>
                <div className={`${demographicStyles.detail__txt}`}>
                  <strong>{"Work Cover"}: </strong>{" "}
                  <InputField
                    label=""
                    value={workCover}
                    type="text"
                    placeholder=""
                    onChange={setWorkCover}
                    disabled={readOnly}
                  />
                </div>

                <div className={`${demographicStyles.detail__txt}`}>
                  <strong>{"Employeer"}: </strong>{" "}
                  <InputField
                    label=""
                    value={employer}
                    type="text"
                    placeholder=""
                    onChange={setEmployer}
                    disabled={readOnly}
                  />
                </div>
              </>
            )}
            {status === "2" && (
              <>
                <div className={`${demographicStyles.detail__txt}`}>
                  <strong>{"Third Party Claim Number"}: </strong>{" "}
                  <InputField
                    label=""
                    value={workCover}
                    type="text"
                    placeholder=""
                    onChange={setWorkCover}
                    disabled={readOnly}
                  />
                </div>

                <div className={`${demographicStyles.detail__txt}`}>
                  <strong>{"Third Party Name"}: </strong>{" "}
                  <InputField
                    label=""
                    value={employer}
                    type="text"
                    placeholder=""
                    onChange={setEmployer}
                    disabled={readOnly}
                  />
                </div>
              </>
            )}

            <div className={`${demographicStyles.detail__txt}`}>
              <strong>{"DVA"}: </strong>{" "}
              <Dropdown
                data={[
                  { value: 0, label: "Not Available" },
                  { value: 1, label: "Available" },
                ]}
                styleClass=""
                value={dva}
                placeholder="Select"
                onChange={(e) => selectDVA(e)}
                disabled={readOnly}
              />
            </div>
            {dva === "1" && (
              <div className={`${demographicStyles.detail__txt}`}>
                <strong>{"DVA Number"}: </strong>{" "}
                <InputField
                  label=""
                  value={DVANumber}
                  type="text"
                  placeholder=""
                  onChange={setDVANumber}
                  disabled={readOnly}
                />
              </div>
            )}

            <div className={`${demographicStyles.detail__txt}`}>
              <strong>{"Defence Personnel"}: </strong>{" "}
              <Dropdown
                data={[
                  { value: "0", label: "No" },
                  { value: "1", label: "Yes" },
                ]}
                styleClass=""
                value={defence}
                placeholder="Select"
                onChange={(e) => selectDefence(e)}
                disabled={readOnly}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 text-center mt_30">
          <Button
            onClick={toggleHandler}
            value="Cancel"
            width={`200px`}
            buttonStyle={`btn_outline ${readOnly ? "btn_light_disabled" : ""}`}
          />
          <Button
            onClick={() => handleSubmit()}
            value="Update"
            width={`200px`}
            buttonStyle={`btn_fill ml_15 ${readOnly ? "btn_light_disabled" : ""}`}
          />
        </div>
      </div>
    </div>
  );
}

export default EditPatientDetails;
