import React from "react";
import  "./Plans.css";
import PendingSpecialityList from "./PendingSpecialityList";

function Pending() {
  return (
    <>
      <div>
        <PendingSpecialityList />
      </div>
    </>
  );
}

export default Pending;
