import React from "react";
import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import "./ApproveConfirmation.css";
import ApproveIcon from "../../../images/delete-red.svg";

const ApproveConfirmation = ({
  showModal,
  hideModal,
  confirmModal,
  id,
  type,
  message,
  btnLeftLabel,
  btnRightLabel
}) => {
  return (
    <Modal
      show={showModal}
      onHide={hideModal}
      className={`approve_modal_main`}
      centered
    >
      <Modal.Body>
        <div className="text-center">
          <img src={ApproveIcon} className="approve_pp_icon" alt="icon" />
          <h4 className="center_title">
            {message}
          </h4>
        </div>
      </Modal.Body>
      <Modal.Footer className="text-center">
        <Button variant="default" className="btn_outline" onClick={hideModal}>
          {btnLeftLabel}
        </Button>
        <Button
          variant="danger"
          className="btn_danger"
          onClick={() => confirmModal(type, id)}
        >
          {btnRightLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};


ApproveConfirmation.propTypes = {
  btnLeftLabel: PropTypes.string,
  btnRighhtLabel: PropTypes.string,
};

ApproveConfirmation.defaultProps = {
  btnLeftLabel: "Cancel",
  btnRightLabel: "Delete",
};

export default ApproveConfirmation;
