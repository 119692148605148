import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { Multiselect } from "multiselect-react-dropdown";
import { useSelector } from "react-redux";
import { MultiSelect } from "react-multi-select-component";
import "react-datepicker/dist/react-datepicker.css";
import InputField from "../custom/inputfield/InputField";
import { Validators, spacialCharReplace } from "../../utilities/Validator";
import Button from "../custom/button/Button";
import Dropdown from "../custom/dropdown/Dropdown";
import useAxios from "../../axiosinstance";
import Message from "../custom/toster/Message";
import Checkbox from "../custom/checkbox/Checkbox";
import RecentPatientTable from "./RecentPatientTable";
import RecentPatientTable2 from "./RecentPatientTable2";

function EditGroupDetails() {
  const [isLoading, setIsLoading] = useState(true);
  const axiosInstance = useAxios();
  const [groupTitle, setGroupTitle] = useState("");
  const [formVaild, setFormVaild] = useState(false);
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [requried, setRequried] = useState([]);
  const [requried1, setRequried1] = useState([]);
  const [orgapiData, setOrgapiData] = useState([]);
  // const [orgapiTargetData, setOrgapiTargetData] = useState([]);
  const { groupId } = useParams();
  const [groupTypeData, setGroupTypeData] = useState([
    {
      value: 0,
      label: "Sending Group",
    },
    {
      value: 1,
      label: "Receiving Group",
    },
  ]);
  const [groupTypeSelected, setGroupTypeSelected] = useState("");
  const [akouId, setAkouId] = useState("");
  const [akouIdTarget, setAkouIdTarget] = useState("");
  const [userRole, setUserRole] = useState("");
  const [assignTo, setAssignTo] = useState(-1);
  const [usersErr, setUsersErr] = useState(false);
  const [resourceErr, setResourceErr] = useState(false);
  const [optionsRequried, setOptionsRequried] = useState([]);
  const [optionsRequried1, setOptionsRequried1] = useState([]);
  const [disable, setDisable] = useState(false)
  const [disable1, setDisable1] = useState(false)
  const [totaldata1, setTotaldata1] = useState("");

  const [hcps, setHcps] = useState("")
  const [patients, setPatients] = useState("")
  const [users, setUsers] = useState("")
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const[user_id,setUserId]=useState("")
  const[user_id1,setUserId1]=useState("")
  const [lastTypingTime, setLastTypingTime] = useState(null);
  const [lastTypingTime1, setLastTypingTime1] = useState(null);

  const [limit, setLimit] = useState(25)
  const [offset, setOffset] = useState(25)
  const [loader1, setloader1] = useState(false);
  const [patientdata, setPatientData] = useState([])
  const [usersData, setUsersData] = useState([])
  const [selected, setSelected] = useState([]);
  const [selected1, setSelected1] = useState([]);
  const [flag, setFlag] = useState(false)
  const [totaldata, setTotaldata] = useState("");
  const [selectd, setSelectd] = useState("")
  const [loader2, setloader2] = useState(false);

  const [userRoleData, setuserRoleData] = useState([
    // { value: -1, label: "All Users" },
    // { value: 3, label: "Only HCP" },
    { value: 2, label: "Patient of HCPs" },
    { value: 1, label: "Only Patient" },
  ]);
  const [userRoleData1, setuserRoleData1] = useState([
    // { value: -1, label: "All Users" },
    { value: 3, label: "Only HCP" },
    // { value: 2, label: "Patient of HCPs" },
  ]);
  const [assignToData, setAssignToData] = useState([]);
  const [selectedResourceData, setSelectedResourceData] = useState([]);
  const [resourceData, setResourceData] = useState([]);
  const [externalGroup, setExternalGroup] = useState("");
  const orgIdRef = useRef();
  const orgTargetIdRef = useRef();
  const groupTypeRef = useRef();
  const userRoleRef = useRef();
  const assignToRef = useRef();
  const groupTitleRef = useRef();
  const navigate = useNavigate();
  let patient_ids = [];
  // const[hcp_ids,sethcp_ids]=useState([])
  let hcp_ids = [];
  let new_hcp_ids = [];

  const [Patienterr, setPatienterr] = useState(false)
  let new_patient_ids = [];
  const login = useSelector(
    (state) =>
      state.mainReducer.loginData.arr && state.mainReducer.loginData.arr[0]
  );

  const handleGroupTitle = (e, err) => {
    setGroupTitle(e);
  };

  const handleContinue = (e) => {
    // setMessage(false);let json;
    e.preventDefault();

    // if (!groupTypeSelected) {
    //   groupTypeRef.current.errUpdate();
    // }
    if (!akouId) {
      orgIdRef.current.errUpdate();
    }
    if (!groupTitle) {
      groupTitleRef.current.errUpdate();
    }
    console.log("comming 1", userRole)
    if (!userRole) {
      userRoleRef.current.errUpdate();
    }
    console.log("comming 2", userRole)

    if (userRole !== "") {
      if (assignTo.length === 0 && assignTo === "") {
        assignToRef.current.errUpdate();
        return

      }
    }
    console.log("comming 3")

    if (Number(assignTo) === 2 && Number(userRole) === 1) {
      console.log("for_pt", selectd, selected)
      if (selectd !== -1) {
        if (selectd.length === 0 && selected.length === 0 || selectd.length === 0) {
          console.log("for_pt", selectd, selected)
          setPatienterr(true)
          return
        }
      }
      
    }
    console.log("comming 4")

    if (Number(assignTo) === 2 && Number(userRole) === 3) {
      console.log("selectdselectdselectdselectdselectdselectdselectd", selectd, selected)
      if (selectd !== -1) {
        if (selectd.length === 0 && selected.length === 0 || selectd.length === 0 ) {
          console.log("selectdselectdselectdselectdselectdselectdselectd", selectd, selected)
          setPatienterr(true)
          return
        }
      }
    }
    console.log("comming 5")

    if (Number(assignTo) === 0 && Number(userRole) === 2) {
      if (selected.length === 0 || selectd.length === 0) {
        setPatienterr(true)
        return

      }
    }
    console.log("comming 6")

    if (
      !akouId ||
      !groupTitle || !userRole || assignTo === "" //here add !assignTo--I removed this from here but pushing time add please
    ) {
      console.log("comming7", assignTo, userRole)
    } else {
      setDisable1(true)

      let json;

      if (userRole === "-1" || userRole === -1) {
        json = JSON.stringify({
          group_type: Number(groupTypeSelected) === 0 ? "source" : "target",
          akou_id: akouId,
          group_name: groupTitle,
          target_user_role: Number(userRole),
          hcp_ids: [],
          assign_to: -1,
          patient_ids: [],
          external_group: (externalGroup ? 1 : 0),
        });
      } else if (userRole === "1" || userRole === 1) {
        json = JSON.stringify({
          group_type: Number(groupTypeSelected) === 0 ? "source" : "target",
          akou_id: akouId,
          group_name: groupTitle,
          target_user_role: Number(userRole),
          assign_to: Number(assignTo),
          hcp_ids: [],
          patient_ids: selectd ? selectd : selected.length !== 0 ? selected.map((ele) => (ele.value)) : -1,
          // patient_ids:selectd,
          external_group: (externalGroup ? 1 : 0),
        });
      } else if (userRole === "3" || userRole === 3) {
        json = JSON.stringify({
          group_type: Number(groupTypeSelected) === 0 ? "source" : "target",
          akou_id: akouId,
          group_name: groupTitle,
          target_user_role: Number(userRole),
          assign_to: Number(assignTo),
          hcp_ids: selectd ? selectd : selected.length !== 0 ? selected.map((ele) => (ele.value)) : -1,
          // hcp_ids: Number(assignTo) === -1 ? -1 : hcp_ids,
          patient_ids: [],
          external_group: (externalGroup ? 1 : 0),
        });
      } else if (userRole === "2" || userRole === 2) {
        json = JSON.stringify({
          group_type: Number(groupTypeSelected) === 0 ? "source" : "target",
          akou_id: akouId,
          group_name: groupTitle,
          target_user_role: Number(userRole),
          assign_to: 0,
          // hcp_ids: hcp_ids,
          hcp_ids: selected.length !== 0 ? selected.map((ele) => (ele.value)) : -1,
          patient_ids: selectd,
          external_group: (externalGroup ? 1 : 0),
        });
      }

      console.log("jsonn", json, userRole)
      axiosInstance.current
        .put(`extorg/group/updateGroup/${login.ak_id}/${groupId}`, json, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          setMessageType("success");
          setMessage("Group Updated successfully");
          setTimeout(() => {
            navigate("/groups-list");
            setMessage(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err.response.data.message);
          setMessage(err.message);
          setMessageType("error");
        });

    }
  };




  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const handleRequired = (e) => {
    setRequried(e);
    patient_ids.push([])
    hcp_ids.push([])
  };
  const handleRequired1 = (e) => {
    setRequried1(e);
    new_patient_ids.push([])
    hcp_ids.push([])
  };
  useEffect(() => {

    if (selectd[0] === -1) {
      setSelectd(-1)
    }
  }, [selectd])

  console.log("selectdselectd", selectd)


  const handleResourceData = (e) => {
    setSelectedResourceData(e);
  };
  const handleOrgModle = (e) => {
    setAkouId(e);
  };
  const handleTargetOrgModle = (e) => {
    setAkouIdTarget(e);
  };
  const handleGroupType = (e) => {
    console.log("eeeee", e)

    if (Number(e) === 1 || Number(e) === 0) {
      setAssignTo(0)
      setUserRole("")
      console.log("heyyy comming here")
      setHcps([])
      setSelected([])
      setPatients("")
      setSelectd("")
      setPatienterr(false)
    }
    setSelected([])
    setAssignTo("")
    setExternalGroup(false)
    setSelected([])
    setGroupTypeSelected(e);
  };
  const handleUserRole = (e) => {

    if (Number(e) === 2) {
      setAssignTo(0)
      setHcps([])
      setSelected([])
      setPatients("")
      setSelectd("")
      setPatienterr(false)
    }
    setUserRole(e);
  };
  const handleAssignTo = (e) => {
    setAssignTo(e);
    if (Number(e) === -1) {
      console.log("comming3")
      setSelected([])
    }
    patient_ids = []
    hcp_ids = []
    setSelectd("")
    setPatients([])
    setHcps([])
  };
  const handleGroupChange = (e) => {
    setExternalGroup(e);
  };



  useEffect(() => {
    const getAllOrgList = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/admin/orgs?ak_id=${login.ak_id}`
        );

        const newData = response.data.data.map((item) => ({
          value: item.id,
          label: item.o_nm,
          plan_id: item.plan_id,
        }));
        setOrgapiData(newData);
      } catch (err) {
        console.log("An error occurred while fetching data.");
      }
    };
    getAllOrgList();
  }, []);

  useEffect(() => {
    const getAllUsersList = async () => {
      let userTypeRole;
      let response;
      userTypeRole = Number(userRole) === -1 ? "" : Number(userRole);

      if (userRole === "2" || userRole === 2) {
        userTypeRole = 3
      } else if (userRole === "1" || userRole === 1) {
        userTypeRole = 1
      }
      console.log("userTypeRole", userRole)

      try {

        if(Number(userRole)===2 || userRole==="2"){
          response = await axiosInstance.current.get(
            `extorg/admin/getAllUsers/${login.ak_id}?limit=${1000}&offset=0&role=${userTypeRole}&email=${email}&username=&sort=&akou_id=${akouId}&pagination=1&name_filter=1&firstname=${firstName}&lastname=${lastName}&user_id=${user_id1}`
          );
        }else{
          response = await axiosInstance.current.get(
            `extorg/admin/getAllUsers/${login.ak_id}?limit=${limit}&offset=0&role=${userTypeRole}&email=${email}&username=&sort=&akou_id=${akouId}&pagination=1&name_filter=1&firstname=${firstName}&lastname=${lastName}&user_id=${user_id1}`
          );
        }

        if (response.status === 200) {
          setloader2(true)
          setTotaldata1(response.data.totalCount);
          setUsersData(response.data.data)
        }

        const newData = response.data.data.map((item) => (
          {
            value: item.ak_id,
            label: item.firstname === null || item.firstname === undefined || item.firstname === "undefined" || item.firstname === "null" || item.firstname === "" ? "Not yet joined" : item.firstname + ` (${item.ak_id})`,
          }));

        setOptionsRequried(newData);

      } catch (err) {
        console.log("An error occurred while fetching data.");
      }
    };
    if (akouId)
      getAllUsersList();
  }, [akouId, userRole]);



  useEffect(() => {
    const getAllPatientListAccoringToDoc = async () => {

      let json = {
        hcp_ids: selected.map((ele) => (ele.value))
      }
      try {
        const response = await axiosInstance.current.post(`extorg/admin/patientOfHCPs/${login.ak_id}?akou_id=${akouId}&offset=0&limit=${limit}&f_name=${firstName}&l_name=${lastName}&email=${email}&user_id=${user_id}`, json, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          setloader1(true)
          setTotaldata(response.data.totalCount);
          setPatientData(response.data.data)
        }
      } catch (err) {
        console.log("An error occurred while fetching data.");
      }
    };
    if (userRole === "2" || userRole === 2)
      getAllPatientListAccoringToDoc();
  }, [userRole, requried, selected]);




  useEffect(() => {
    if (lastTypingTime) {
      const timer = setTimeout(() => {
        let json = {
          hcp_ids: selected.map((ele) => (ele.value))
        }
        const getAllPatientListAccoringToDoc = async () => {
          try {
            const response = await axiosInstance.current.post(`extorg/admin/patientOfHCPs/${login.ak_id}?akou_id=${akouId}&offset=0&limit=${limit}&f_name=${firstName}&l_name=${lastName}&email=${email}&user_id=${user_id}`, json, {
              headers: {
                "Content-Type": "application/json",
              },
            });
            setTotaldata(response.data.totalCount);
            setPatientData(response.data.data)
          } catch (error) {
            console.log("An error occurred while fetching data.");
          }
        };
        getAllPatientListAccoringToDoc();
      }, 1000);
      return () => clearTimeout(timer)
    }
  }, [firstName, email, lastName , user_id])




  const addMoreData = async () => {
    console.log("comming in scrolling function")
    setOffset(offset + 25);
    let json = {
      hcp_ids: selected.map((ele) => (ele.value))
    }

    const response = await axiosInstance.current.post(`extorg/admin/patientOfHCPs/${login.ak_id}?akou_id=${akouId}&offset=${offset}&limit=${limit}&f_name=${firstName}&l_name=${lastName}&email=${email}&user_id=${user_id}`, json, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const resdata = response.data.data;

    setTimeout(() => {
      setPatientData(patientdata.concat(resdata));
      // setTotaldata(patientdata.length);

    }, 500);
  };


  const fetchMoreData = () => {
    addMoreData()
  };

  const advsearchToFirstName = (e) => {
    setLastTypingTime(new Date().getTime())
    setLastTypingTime1(new Date().getTime())
    setFirstName(e);
  }

  const advsearchTolastName = (e) => {
    setLastTypingTime(new Date().getTime())
    setLastTypingTime1(new Date().getTime())
    setLastName(e);
  };

  const advsearchToMail = (event) => {
    setLastTypingTime(new Date().getTime())
    setLastTypingTime1(new Date().getTime())
    setEmail(event);
  };



  const advsearchToMAk_id = (event) => {
    setLastTypingTime(new Date().getTime())
    setLastTypingTime1(new Date().getTime())
    setUserId(event)
  }


  const advsearchToMAk_idForUsers = (event) => {
    setLastTypingTime1(new Date().getTime())
    setUserId1(event)
  }




  useEffect(() => {
    if (lastTypingTime1) {
      let userTypeRole;
      userTypeRole = Number(userRole) === -1 ? "" : Number(userRole);

      if (userRole === "2" || userRole === 2) {
        userTypeRole = 3
      } else if (userRole === "1" || userRole === 1) {
        userTypeRole = 1
      }
      console.log("userTypeRole", userTypeRole)
      const timer = setTimeout(() => {
        const getAllUsersList = async () => {
          try {

            const response = await axiosInstance.current.get(
              `extorg/admin/getAllUsers/${login.ak_id}?limit=${limit}&offset=${0}&role=${userTypeRole}&email=${email}&username=&sort=&akou_id=${akouId}&pagination=1&name_filter=1&firstname=${firstName}&lastname=${lastName}&user_id=${user_id1}`
            );
            setTotaldata1(response.data.totalCount);
            setUsersData(response.data.data)
          } catch (error) {
            console.log("An error occurred while fetching data.");
          }
        };
        getAllUsersList();
      }, 1000);
      return () => clearTimeout(timer)
    }
  }, [firstName, email, lastName , user_id1])















  const addMoreData2 = async () => {
    setOffset(offset + 25);
    let userTypeRole;
    userTypeRole = Number(userRole) === -1 ? "" : Number(userRole);

    if (userRole === "2" || userRole === 2) {
      userTypeRole = 3
    } else if (userRole === "1" || userRole === 1) {
      userTypeRole = 1
    }
    console.log("userTypeRole", userTypeRole)

    const response = await axiosInstance.current.get(
      `extorg/admin/getAllUsers/${login.ak_id}?limit=${limit}&offset=${offset}&role=${userTypeRole}&email=${email}&username=&sort=&akou_id=${akouId}&pagination=1&name_filter=1&firstname=${firstName}&lastname=${lastName}&user_id=${user_id1}`
    );
    const resdata = response.data.data;

    setTimeout(() => {
      setUsersData(usersData.concat(resdata));
      // setTotaldata1(usersData.length);
    }, 500);
  };




  console.log("usersDatausersDatausersData", groupTypeSelected, userRole)




  const fetchMoreData2 = () => {
    addMoreData2()
  };



































  useEffect(() => {

    if (userRole === "3" || userRole === 3 || userRole === "2" || userRole === 2) {
      if (hcps < 0) {

      } else {
        const selectedItems = optionsRequried && optionsRequried.filter(item => hcps.includes(item.value));
        setSelected(selectedItems);
      }

    } else if (userRole === "1" || userRole === 1) {
      if (patients < 0) {

      } else {
        const selectedItems = optionsRequried && optionsRequried.filter(item => patients.includes(item.value));
        setSelected(selectedItems);
      }

    }


  }, [optionsRequried]);



  if (patients < 0) {
    patient_ids = -1
  } else {
    selected.map((ele) => {
      patient_ids.push(ele.value)
    })
  }


  useEffect(() => {
    if (selected.length !== 0) {
      let array = selected.map((ele) => (ele.value))
      hcp_ids = array
     
      console.log("this is array", array);
    } else {
      hcp_ids = -1
      console.log("-1", "bnbbb");
    }
  }, [hcp_ids])


  useEffect(() => {

    if (userRole === "2" || userRole === 2) {
      const selectedItems = optionsRequried1 && optionsRequried1.filter(item => patients.includes(item.value));
      setSelected1(selectedItems);
    }


  }, [optionsRequried1]);

  selected1.map((ele) => {
    new_patient_ids.push(ele.value)
  })


  useEffect(() => {
    const getGropDetails = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/group/getGroupDetails/${login.ak_id}/${groupId}?limit=10&offset=0&name=&akou_id=&sort=`
        );
        setFlag(true)
        setHcps(response.data.data[0].hcp_ids)
        setPatients(response.data.data[0].patient_ids)

        setExternalGroup(response.data.data[0].external_group === 1 ? true : false);
        setGroupTypeSelected(response.data.data[0].group_type === "source" ? 0 : 1)
        setAkouId(response.data.data[0].akou_id);
        setGroupTitle(response.data.data[0].group_name);
        setAssignTo(response.data.data[0].assign_to);
        setUserRole(response.data.data[0].target_user_role);
        setRequried(response.data.data[0].existingUsers);
        // setOptionsRequried(response.data.data[0].hcp_ids);
        setSelectedResourceData(response.data.data[0].existingResType);
        const mergedResTypes = response.data.data[0].existingResType.concat(response.data.data[0].newResType);
        setResourceData(mergedResTypes);
        setAkouIdTarget(response.data.data[0].target_akou_id);
        // setGroupTypeSelected(response.data.data[0].group_type.toString());
      } catch (err) {
        console.log("An error occurred while fetching data.");
      }
    };
    getGropDetails();
  }, []);



  useEffect(() => {
    if (Number(userRole) === 3 || userRole === "3") {
      setAssignToData([
        { value: -1, label: "All Users" },
        { value: 2, label: "Selected HCPs" },
      ]);
    }
    if (Number(userRole) === 1 || userRole === 1) {
      setAssignToData([
        { value: -1, label: "All Patients" },
        { value: 2, label: "Selected Patients" },
      ]);
    }
  }, [userRole]);

  const customStrings = {
    selectSomeItems: 'Resource types...',
    // allItemsAreSelected: `${selectedResourceData.length} Items selected`,
  };
  const customUserStrings = {
    selectSomeItems: 'Users...',
    // allItemsAreSelected: `${requried.length} Users selected`,
  };

  useEffect(() => {
    if (userRole === "-1" || userRole === "1" || userRole === "3" || userRole === -1 || userRole === 1 || userRole === 3) {
      setAssignTo(assignTo)
    }
  }, [userRole, akouId])

  const handleRemove = () => {
    setRequried([])
  }

  useEffect(() => {
    if (userRole === "2" || userRole === 2) {
      setDisable(true)
    } else {
      setDisable(false)

    }
  }, [userRole])


  console.log("assignTo", assignTo)
  console.log("selectd_pt", selectd)
  console.log("selected_hcps", selected)
  console.log("patient_ids", patients)
  console.log("Users_Role", userRole)

  console.log("patinets__hcpss__", patients, hcps)
  console.log("patinets__hcpss__SELECTEDDDDDDD", selectd, selected, Patienterr)

  useEffect(() => {
    if (selectd === -1) {
      setPatienterr(false)
    }
    else if (selectd.length !== 0 || selected.length !== 0) {
      setPatienterr(false)
    }

  }, [selectd,selected])
  


  const dataOf = (patients, hcps) => {
    if (patients.length > 0) {
      return patients
    }
    else if (patients === -1) {
      return -1
    } else if (hcps.length > 0) {
      return hcps
    } else if (hcps === -1) {
      return -1
    }
  }


  const handleReset = ()=>{
    setFirstName("")
    setLastName("")
    setEmail("")
    setUserId("")
    setUserId1("")
  }




  return (
    <>
      {message && <Message message={message} type={messageType} />}
      <div className="create_group_screen mb_200">
        <div className="row page_header">
          <div className="col-sm-6 col-md-6">
            <h4 className="ph_title">Edit Group</h4>
          </div>
          <div className="col-sm-6 col-md-6 text-end"></div>
        </div>

        <div className="panel">
          {message && <Message message={message} type={messageType} />}
          <div className="panel-body p_25 pt_50 pb_50">
            <div className="dc_reg_inner">
              <div className="">
                <div className="row">
                  <div className="col-sm-5 col-md-5 mb_20">
                    <Dropdown
                      data={groupTypeData}
                      label="Group Type*"
                      value={groupTypeSelected}
                      onChange={handleGroupType}
                      placeholder="Select Type"
                      styleClass="br_10 mxw_285 mb_20"
                      ref={groupTypeRef}
                      validators={[
                        {
                          check: Validators.required,
                          message: "This field is required",
                        },
                      ]}
                    />
                  </div>
                  <div className="col-sm-5 col-md-5 mb_20">
                    <Dropdown
                      data={orgapiData}
                      label="Select Organization*"
                      value={akouId}
                      onChange={handleOrgModle}
                      placeholder="Select Organization"
                      styleClass="br_10 mxw_285 mb_20"
                      ref={orgIdRef}
                      disabled={disable}
                      validators={[
                        {
                          check: Validators.required,
                          message: "This field is required",
                        },
                      ]}
                    />
                  </div>
                </div>

                <div className="row">
                  {/* {groupTypeSelected === "1" && (
                    <div className="col-sm-5 col-md-5 mb_20">
                      <Dropdown
                        data={orgapiData}
                        label="Select Target Organization*"
                        value={akouIdTarget}
                        onChange={handleTargetOrgModle}
                        placeholder="Select Target Organization"
                        styleClass="br_10 mxw_285 mb_20"
                        ref={orgTargetIdRef}
                        validators={[
                          {
                            check: Validators.required,
                            message: "This field is required",
                          },
                        ]}
                      />
                    </div>
                  )} */}
                  <div className="col-sm-5 col-md-5 mb_20">
                    <InputField
                      label="Name*"
                      value={groupTitle}
                      name="groupTitle"
                      type="text"
                      placeholder="Enter group name here"
                      onChange={handleGroupTitle}
                      ref={groupTitleRef}
                      validators={[
                        {
                          check: Validators.required,
                          message: "This field is required",
                        },
                      ]}
                      styleClass="br_10 mxw_285"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-5 col-md-5 mb_20">
                    {
                      Number(groupTypeSelected) === 0 ? (
                        <Dropdown
                          data={userRoleData}
                          label="Target user role*"
                          value={userRole}
                          onChange={handleUserRole}
                          placeholder="Select"
                          styleClass="br_10 mxw_285 mb_20"
                          ref={userRoleRef}
                          validators={[
                            {
                              check: Validators.required,
                              message: "This field is required",
                            },
                          ]}
                        />
                      ) : (
                        <Dropdown
                          data={userRoleData1}
                          label="Target user role*"
                          value={userRole}
                          onChange={handleUserRole}
                          placeholder="Select"
                          styleClass="br_10 mxw_285 mb_20"
                          ref={userRoleRef}
                          validators={[
                            {
                              check: Validators.required,
                              message: "This field is required",
                            },
                          ]}
                        />
                      )
                    }
                  </div>
                  {
                    userRole === "1" || userRole === 1 ? (

                      <div className="col-sm-5 col-md-5 mb_20">
                        <Dropdown
                          data={assignToData}
                          label="Assign To*"
                          value={assignTo}
                          onChange={handleAssignTo}
                          placeholder="Select"
                          styleClass="br_10 mxw_285 mb_20"
                          ref={assignToRef}
                          validators={[
                            {
                              check: Validators.required,
                              message: "This field is required",
                            },
                          ]}
                        />
                      </div>

                    ) : ""
                  }


                  {
                    userRole === "3" || userRole === 3 ? (

                      <div className="col-sm-5 col-md-5 mb_20">
                        <Dropdown
                          data={assignToData}
                          label="Assign To*"
                          value={assignTo}
                          onChange={handleAssignTo}
                          placeholder="Select"
                          styleClass="br_10 mxw_285 mb_20"
                          ref={assignToRef}
                          validators={[
                            {
                              check: Validators.required,
                              message: "This field is required",
                            },
                          ]}
                        />
                      </div>

                    ) : ""
                  }

                  {
                    userRole === "2" || userRole === 2 ? (
                      <div className="col-sm-5 col-md-5 mb_20">
                        <label>Selected HCP's</label>
                        <MultiSelect
                          options={optionsRequried}
                          value={selected}
                          onChange={setSelected}
                          labelledBy={"Select"}
                          className="multi_select_main"
                          isCreatable={true}
                        />

                        {selected.length === 0 && (
                          <>
                            {Patienterr && (
                              <span className="text-danger mt-2">
                                This field is required
                              </span>
                            )}
                          </>
                        )}

                      </div>


                    ) : ""
                  }
                </div>

                {
                  <div className="row">
                    {userRole === "2" || userRole === 2 ? (
                      // <div className="col-sm-5 col-md-5 mb_20">
                      //   <label>Selected Patients</label>
                      //   <MultiSelect
                      //     options={optionsRequried1}
                      //     value={selected1}
                      //     onChange={setSelected1}
                      //     labelledBy={"Select"}
                      //     className="multi_select_main"
                      //     isCreatable={true}
                      //   />
                      //   {usersErr && (
                      //     <span className="text-danger">
                      //       This field is required
                      //     </span>
                      //   )}
                      // </div>
                      <div className={`col-sm-12`}>
                        <div className={`whitebox`}>
                          {/* <div className="box-header">
                            <div className="row">
                              <div className="col-sm-4 col-md-4">
                                <h4 className="box-title f_22">
                                  Recent Patients
                                </h4>
                              </div>
                              <div className="col-sm-8 col-md-8 text-end"></div>
                            </div>
                          </div> */}

                          <div className="patients__list mb_100">
                            {/* <div className="row page_header">
                              <div className="col-sm-6 col-md-6">
                                <h4 className="ph_title">Patients</h4>
                              </div>
                            </div> */}

                            <div className="panel pt_30 ">
                              {/* {totaldata > 0 ? ( */}
                              <>
                                <div className="">
                                  <h4 className="mb_30">Advance Serach</h4>
                                  {/* <form onSubmit={handleSubmit}> */}

                                  <div className="row">
                                    <div className="col-sm-6 col-md-3">

                                      <InputField

                                        label="Patient Name"
                                        value={firstName}
                                        type="text"
                                        name="firstName"
                                        placeholder="First Name"
                                        onChange={advsearchToFirstName}

                                      />
                                      <InputField
                                        label=""
                                        value={lastName}
                                        type="text"
                                        placeholder="Last Name"
                                        onChange={advsearchTolastName}
                                      />
                                    </div>

                                    <div className="col-sm-6 col-md-3">
                                      <InputField
                                        label="Email"
                                        value={email}
                                        type="text"
                                        placeholder="Email Address"
                                        onChange={(e) => advsearchToMail(e)}
                                      />
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                      <InputField
                                        label="Patient Id"
                                        value={user_id}
                                        type="text"
                                        placeholder="Enter AK ID"
                                        onChange={(e) => advsearchToMAk_id(e)}
                                      />
                                    </div>
                                    <div className="col-sm-6 col-md-3 pt_30">
                                      <Button
                                        onClick={handleReset}
                                        value="Reset"
                                        buttonStyle="btn_fill mr_20"
                                      />
                                      {/* <Button onClick={ handleSubmit}
                    value="Submit"
                    buttonStyle="btn_fill" /> */}
                                    </div>
                                  </div>
                                  {/* </form> */}
                                </div>
                                <hr />
                                <div className="row">
                                  <div className="" style={{ marginLeft: "-25px" }}>
                                    <h4 className="mb-3 ml_25">List Of Patients</h4>

                                    <p className="mb_30 ml_25">
                                      {totaldata} {totaldata > 1 ? "Results" : "Result"}
                                    </p>
                                  </div>
                                  <div className="col-sm-6 col-md-6">
                                    {/* <SortDropdown onChange={handleSort} alp={true} /> */}
                                  </div>
                                </div>

                                {!loader1 ? <div className="no_data">
                                  loading...
                                </div> : (
                                  <>
                                    {
                                      patientdata.length > 0 ? (
                                        <RecentPatientTable
                                          patientdata={patientdata}
                                          patient_ids={patients}
                                          setSelectd={setSelectd}
                                          updatePagination={fetchMoreData}
                                        />
                                      ) : (
                                        <div className="no_data">No data found</div>
                                      )}

                                  </>
                                )
                                }
                                {/* <>
                                  {
                                    patientdata.length > 0 ? (
                                      <RecentPatientTable
                                        patientdata={patientdata}
                                        patient_ids={patients}
                                        setSelectd={setSelectd}
                                        updatePagination={fetchMoreData}
                                      />
                                    ) : (
                                      <div className="no_data">No data found</div>
                                    )}

                                </> */}

                              </>

                            </div>
                            {selectd.length === 0 && (
                              <>
                                {Patienterr && (
                                  <span className="text-danger mt-2">
                                    This field is required
                                  </span>
                                )}
                              </>
                            )}
                          </div>

                        </div>

                      </div>

                    ) : (
                      ""
                    )}
                  </div>
                }



                <div className="row">
                  {assignTo === "2" || assignTo === 2 || assignTo === "3" || assignTo === 3 || assignTo === "1" || assignTo === 1 ? (
                    <div className={`col-sm-12`}>
                      <div className={`whitebox`}>
                        {/* <div className="box-header">
                        <div className="row">
                          <div className="col-sm-4 col-md-4">
                            <h4 className="box-title f_22">
                              Recent Patients
                            </h4>
                          </div>
                          <div className="col-sm-8 col-md-8 text-end"></div>
                        </div>
                      </div> */}

                        <div className="patients__list mb_100">
                          {/* <div className="row page_header">
                          <div className="col-sm-6 col-md-6">
                            <h4 className="ph_title">Patients</h4>
                          </div>
                        </div> */}

                          <div className="panel pt_30 ">
                            {/* {totaldata > 0 ? ( */}
                            <>
                              <div className="">
                                <h4 className="mb_30">Advance Serach</h4>
                                {/* <form onSubmit={handleSubmit}> */}

                                <div className="row">
                                  <div className="col-sm-6 col-md-3">

                                    <InputField

                                      label="Users Name"
                                      value={firstName}
                                      type="text"
                                      name="firstName"
                                      placeholder="First Name"
                                      onChange={advsearchToFirstName}

                                    />
                                    <InputField
                                      label=""
                                      value={lastName}
                                      type="text"
                                      placeholder="Last Name"
                                      onChange={advsearchTolastName}
                                    />
                                  </div>

                                  <div className="col-sm-6 col-md-3">
                                    <InputField
                                      label="Email"
                                      value={email}
                                      type="text"
                                      placeholder="Email Address"
                                      onChange={(e) => advsearchToMail(e)}
                                    />
                                  </div>
                                  <div className="col-sm-6 col-md-3">
                                      <InputField
                                        label="User Id"
                                        value={user_id1}
                                        type="text"
                                        placeholder="Enter AK ID"
                                        onChange={(e) => advsearchToMAk_idForUsers(e)}
                                      />
                                    </div>
                                  <div className="col-sm-6 col-md-3 pt_30">
                                    <Button
                                      onClick={handleReset}
                                      value="Reset"
                                      buttonStyle="btn_fill mr_20"
                                    />
                                    {/* <Button onClick={ handleSubmit}
                value="Submit"
                buttonStyle="btn_fill" /> */}
                                  </div>
                                </div>
                                {/* </form> */}
                              </div>
                              <hr />
                              <div className="row">
                                <div className="" style={{ marginLeft: "-25px" }}>
                                  <h4 className="mb-3 ml_25">List Of Users</h4>

                                  <p className="mb_30 ml_25">
                                    {totaldata1} {totaldata1 > 1 ? "Results" : "Result"}
                                  </p>
                                </div>
                                <div className="col-sm-6 col-md-6">
                                  {/* <SortDropdown onChange={handleSort} alp={true} /> */}
                                </div>
                              </div>


                              {!loader2 ? <div className="no_data">
                                loading...
                              </div> : (
                                <>
                                  {
                                    usersData.length > 0 ? (
                                      <RecentPatientTable2
                                        patientdata={usersData}
                                        patient_ids={dataOf(patients, hcps)}
                                        setSelectd={setSelectd}
                                        updatePagination={fetchMoreData2}
                                      />
                                    ) : (
                                      <div className="no_data">No data found</div>
                                    )}

                                </>
                              )
                              }
                            </>

                          </div>
                          {selectd.length === 0 || selected.length === 0 ? (
                            <>
                              {Patienterr && (
                                <span className="text-danger mt-2">
                                  This field is required
                                </span>
                              )}
                            </>
                          ) : ""}
                        </div>

                      </div>

                    </div>
                  ) : (
                    ""
                  )}

                </div>
                {console.log("externalGroupexternalGroup", externalGroup)}
                {
                  groupTypeSelected === 0 || groupTypeSelected === "0" ? ("") : (
                    <div className="row">
                      <div className="col-6">
                        <Checkbox
                          label="Create an external group."
                          selected={externalGroup}
                          onChange={handleGroupChange}
                          styleClass="custom_check_box tick f_400 large"
                          id="feature_external_gp"
                        />
                      </div>
                    </div>
                  )
                }


                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6"></div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <Button
                      onClick={handleContinue}
                      value="Submit"
                      enable={disable1}
                      buttonStyle="btn_fill ms-4 clk_btn_disbled"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditGroupDetails;