import { FETCH_SUBID_SUCCESS, FETCH_SUBID_FAIL } from "../actions/actionTypes";
  
  const initialState = {
    user: [],
    error: "",
    subId: false,
  };
  
  const userIdReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_SUBID_SUCCESS:
        return { ...state, subId: true, user: action.data };
      case FETCH_SUBID_FAIL:
        return { ...state, subId: false, user: action.data };
      default:
        return state;
    }
  }

  export default userIdReducer;