import React from "react";
import ResourceTypeList from "./ResourceTypeList";
import "./ResourceType.css";

function ResourceType() {
  return (
    <>
      <div>
        <ResourceTypeList />
      </div>
    </>
  );
}

export default ResourceType;
