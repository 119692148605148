import React, { useState } from "react";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import sortDropStyles from "./SortDropdown.module.css";
import arrowSmall from "../../../images/arrowbluesm.svg";

const SortDropdown = ({
  value,
  label,
  data,
  placeholder,
  styleClass,
  onChange,
  type,
  alp,
}) => {
  const handleChange = (event) => {
    console.log(event);
    const value = event;
    onChange(value);
  };

  return (
    <>
      <div
        className={`text-end custom_sort_dropdown ${sortDropStyles.sort_dropdown_main}`}
      >
  <Dropdown className={`custom_dropdown ${sortDropStyles.drop_down}`}>
          <Dropdown.Toggle
            className={sortDropStyles.dropdown_button}
            variant="default"
            id="dropdown-basic"
          >
            Sort{" "}
            <span>
              <img className={`arrow_img`} src={arrowSmall} alt="arrow" />
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {type === "opNote" && (
              <>
                <Dropdown.Item href="" onClick={() => handleChange(-1)}>
                  Last Created
                </Dropdown.Item>
                <Dropdown.Item href="" onClick={() => handleChange(1)}>
                  Alphabetically
                </Dropdown.Item>

                <Dropdown.Item href="" onClick={() => handleChange(2)}>
                  Last Modified
                </Dropdown.Item>
              </>
            )}
            {type !== "opNote" && (
              <>
                {alp && (
                  <Dropdown.Item
                    href=""
                    onClick={() => handleChange("frequently_used")}
                  >
                    Frequently used
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  href=""
                  onClick={() => handleChange("last_created")}
                >
                  Last created
                </Dropdown.Item>
                <Dropdown.Item
                  href=""
                  onClick={() => handleChange("alphabetically")}
                >
                  Alphabetically
                </Dropdown.Item>
                <Dropdown.Item
                  href=""
                  onClick={() => handleChange("last_modified")}
                >
                  Last modified
                </Dropdown.Item>
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

SortDropdown.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.array,
  styleClass: PropTypes.string,
  onChange: PropTypes.func,
};

SortDropdown.defaultProps = {
  value: "",
  label: "",
  styleClass: "",
  placeholder: "",
};

export default SortDropdown;
