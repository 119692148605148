import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { MultiSelect } from "react-multi-select-component";
import Dropdown from "../custom//dropdown/Dropdown";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Button from "../custom/button/Button";
import InputField from "../custom/inputfield/InputField";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import useAxios from "../../axiosinstance";
import { Validators, spacialCharReplace } from "../../utilities/Validator";
import { noteRefs } from "../../redux/actions";
import { apiResponseMsgData, apiResponseMsgTypeData } from "../../redux/actions";
import useTranslation from "../customHooks/translations";
import Select from 'react-select';
import Message from "../custom/toster/Message";
import AlertImage from '../../images/alert.svg.png'

const AddOrgAdmin = ({
  showModal,
  hideModal,
  confirmModal,
  id,
  type,
  defaultData,
  userRoleType,
  orgNameSelected,
  organization_id,
  platForm,
  platFormData,
  editPlatformList
}) => {
  const navigate = useNavigate();
  const axiosInstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const [add, setAdd] = useState("");
  const [organization, setOrganization] = useState("");
  const [email, setEmail] = useState([]);
  // const [message, setMessage] = useState([]);
  const [emailListError, setEmailListError] = useState(false);
  const [platError, setPlatError] = useState(false);


  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();

  const [readOnly, setReadOnly] = useState(true);
  const [subLineTxt, setSubLineTxt] = useState("");
  const [dearLineTxt, setDearLineTxt] = useState("");
  const [congratsLine1Txt, setCongratsLine1Txt] = useState("");
  const [platformtypeTxt, setPlatformtypeTxt] = useState("");
  const [congratsLine2Txt, setCongratsLine2Txt] = useState("");
  const [hereLineTxt, setHereLineTxt] = useState("");
  const [signinLinetitleTxt, setSigninLinetitleTxt] = useState("");
  const [signinLine1Txt, setSigninLine1Txt] = useState("");
  const [platformurl, setPlatformurl] = useState("");
  const [platformurlTxt, setPlatformurlTxt] = useState("");
  const [signinLine2Txt, setSigninLine2Txt] = useState("");
  const [signupLinetitleTxt, setSignupLinetitleTxt] = useState("");
  const [signupLine1Txt, setSignupLine1Txt] = useState("");
  const [signupLine21Txt, setSignupLine21Txt] = useState("");
  const [signupLine22Txt, setSignupLine22Txt] = useState("");
  const [signupLine3Txt, setSignupLine3Txt] = useState("");
  const [signupLine4Txt, setSignupLine4Txt] = useState("");
  const [experienceLineTxt, setExperienceLineTxt] = useState("");
  const [thrilledLineTxt, setThrilledLineTxt] = useState("");
  const [regardsLineTxt, setRegardsLineTxt] = useState("");
  const [teamLineTxt, setTeamLineTxt] = useState("");

  const [selectedOption, setSelectedOption] = useState([]);
  const [filterData, setFilterData] = useState([])

  const [errorEmailData, setErrorEmailData] = useState([])
  const [emailSuccesData, setEmailSuccessData] = useState([])


  let responseData = [];
  let responseData2 = []

  const emailRef = useRef();
  const orgNameRef = useRef();
  const login = useSelector(
    (state) =>
      state.mainReducer.loginData.arr && state.mainReducer.loginData.arr[0]
  );
  const translation = useTranslation();
  const dispatch = useDispatch();
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
  const [disble, setDisable] = useState(false)

  const handleCloseEdit = (e) => {
    hideModal(false);
    setEmail([])
    setAdd("")
    setSelectedOption([])
    setEmailListError(false);
    setPlatError(false)
    setErrorEmailData([])
    setEmailSuccessData([])
  };

  const keyNameChange = (e) => {
    setAdd(e);
    setEmailListError(false);
  };

  const newPlatFormData = [
    {
      label: "MED",
      value: 2
    },
    {
      label: "CLINICIAN",
      value: 1
    }
  ]

  useEffect(() => {
    if (userRoleType === 1) {
      setSubLineTxt(translation.Invite_temp_patient_sub_line);
      setDearLineTxt(translation.Invite_temp_dear_line);
      setCongratsLine1Txt(translation.Invite_temp_patient_congratulation_line1);
      setPlatformtypeTxt("Patient");
      setCongratsLine2Txt(translation.Invite_temp_congratulation_line2);
      setHereLineTxt(translation.Invite_temp_here_line);
      setSigninLinetitleTxt(translation.Invite_temp_signin_title_line);
      setSigninLine1Txt(translation.Invite_temp_patient_signin_line1);
      setPlatformurl("https://me.akunah.com/");
      setPlatformurlTxt("me.akunah.com");
      setSigninLine2Txt(translation.Invite_temp_signin_line2);
      setSignupLinetitleTxt(translation.Invite_temp_signup_title);
      setSignupLine1Txt(translation.Invite_temp_signup_line1);
      setSignupLine21Txt(translation.Invite_temp_signup_line21);
      setSignupLine22Txt(translation.Invite_temp_signup_line22);
      setSignupLine3Txt(translation.Invite_temp_signup_line3);
      setSignupLine4Txt(translation.Invite_temp_patient_signup_line4);
      setExperienceLineTxt(translation.Invite_temp_experience_line);
      setThrilledLineTxt(translation.Invite_temp_thrilled_line);
      setRegardsLineTxt(translation.Invite_temp_regards_line);
      setTeamLineTxt(translation.Invite_temp_team_line);
    } else if (userRoleType === 2) {
      setSubLineTxt(translation.Invite_temp_admin_sub_line);
      setDearLineTxt(translation.Invite_temp_dear_line);
      setCongratsLine1Txt(translation.Invite_temp_org_congratulation_line1);
      setPlatformtypeTxt("Organization");
      setCongratsLine2Txt(translation.Invite_temp_congratulation_line2);
      setHereLineTxt(translation.Invite_temp_here_line);
      setSigninLinetitleTxt(translation.Invite_temp_signin_title_line);
      setSigninLine1Txt(translation.Invite_temp_org_signin_line1);
      setPlatformurl("https://org.akunah.com/");
      setPlatformurlTxt("org.akunah.com");
      setSigninLine2Txt(translation.Invite_temp_signin_line2);
      setSignupLinetitleTxt(translation.Invite_temp_signup_title);
      setSignupLine1Txt(translation.Invite_temp_signup_line1);
      setSignupLine21Txt(translation.Invite_temp_signup_line21);
      setSignupLine22Txt(translation.Invite_temp_signup_line22);
      setSignupLine3Txt(translation.Invite_temp_signup_line3);
      setSignupLine4Txt(translation.Invite_temp_org_signup_line4);
      setExperienceLineTxt(translation.Invite_temp_experience_line);
      setThrilledLineTxt(translation.Invite_temp_thrilled_line);
      setRegardsLineTxt(translation.Invite_temp_regards_line);
      setTeamLineTxt(translation.Invite_temp_team_line);
    } else if (userRoleType === 3) {
      setSubLineTxt(translation.Invite_temp_hcp_sub_line);
      setDearLineTxt(translation.Invite_temp_dear_line);
      setCongratsLine1Txt(translation.Invite_temp_hcp_congratulation_line1);
      setPlatformtypeTxt("Clinician");
      setCongratsLine2Txt(translation.Invite_temp_congratulation_line2);
      setHereLineTxt(translation.Invite_temp_here_line);
      setSigninLinetitleTxt(translation.Invite_temp_signin_title_line);
      setSigninLine1Txt(translation.Invite_temp_hcp_signin_line1);
      setPlatformurl("https://clinicians.akunah.com/");
      setPlatformurlTxt("clinicians.akunah.com");
      setSigninLine2Txt(translation.Invite_temp_signin_line2);
      setSignupLinetitleTxt(translation.Invite_temp_signup_title);
      setSignupLine1Txt(translation.Invite_temp_signup_line1);
      setSignupLine21Txt(translation.Invite_temp_signup_line21);
      setSignupLine22Txt(translation.Invite_temp_signup_line22);
      setSignupLine3Txt(translation.Invite_temp_signup_line3);
      setSignupLine4Txt(translation.Invite_temp_hcp_signup_line4);
      setExperienceLineTxt(translation.Invite_temp_experience_line);
      setThrilledLineTxt(translation.Invite_temp_thrilled_line);
      setRegardsLineTxt(translation.Invite_temp_regards_line);
      setTeamLineTxt(translation.Invite_temp_team_line);
    } else {
      setSubLineTxt(translation.Invite_temp_patient_sub_line);
      setDearLineTxt(translation.Invite_temp_dear_line);
      setCongratsLine1Txt(translation.Invite_temp_patient_congratulation_line1);
      setPlatformtypeTxt("Patient");
      setCongratsLine2Txt(translation.Invite_temp_congratulation_line2);
      setHereLineTxt(translation.Invite_temp_here_line);
      setSigninLinetitleTxt(translation.Invite_temp_signin_title_line);
      setSigninLine1Txt(translation.Invite_temp_patient_signin_line1);
      setPlatformurl("https://me.akunah.com/");
      setPlatformurlTxt("me.akunah.com");
      setSigninLine2Txt(translation.Invite_temp_signin_line2);
      setSignupLinetitleTxt(translation.Invite_temp_signup_title);
      setSignupLine1Txt(translation.Invite_temp_signup_line1);
      setSignupLine21Txt(translation.Invite_temp_signup_line21);
      setSignupLine22Txt(translation.Invite_temp_signup_line22);
      setSignupLine3Txt(translation.Invite_temp_signup_line3);
      setSignupLine4Txt(translation.Invite_temp_patient_signup_line4);
      setExperienceLineTxt(translation.Invite_temp_experience_line);
      setThrilledLineTxt(translation.Invite_temp_thrilled_line);
      setRegardsLineTxt(translation.Invite_temp_regards_line);
      setTeamLineTxt(translation.Invite_temp_team_line);
    }
  }, [userRoleType]);
  // const getMessageBody = (role) => {
  //   if (role === 1) {
  //     return translation.Invite_organization_patient_body;
  //   } else if (role === 2) {
  //     return translation.Invite_organization_admin_body;
  //   } else if (role === 3) {
  //     return translation.Invite_organization_doctor_body;
  //   } else {
  //     return "You are invited";
  //   }
  // };

  let json;

  let clinician = {
    akou_id: organization,
    org_name: orgNameSelected,
    email: email,
    role_type: userRoleType,
    sub_line_txt: subLineTxt,
    dear_line_txt: dearLineTxt,
    congrats_line1_txt: congratsLine1Txt,
    platform_type_txt: platformtypeTxt,
    congrats_line2_txt: congratsLine2Txt,
    here_line_txt: hereLineTxt,
    signin_line_title_txt: signinLinetitleTxt,
    signin_line1_txt: signinLine1Txt,
    platform_url: platformurl,
    platform_url_txt: platformurlTxt,
    signin_line2_txt: signinLine2Txt,
    signup_line_title_txt: signupLinetitleTxt,
    signup_line1_txt: signupLine1Txt,
    signup_line21_txt: signupLine21Txt,
    signup_line22_txt: signupLine22Txt,
    signup_line3_txt: signupLine3Txt,
    signup_line4_txt: signupLine4Txt,
    experience_line_txt: experienceLineTxt,
    thrilled_line_txt: thrilledLineTxt,
    regards_line_txt: regardsLineTxt,
    team_line_txt: teamLineTxt,
  }

  let med = {
    akou_id: organization,
    org_name: orgNameSelected,
    email: email,
    role_type: userRoleType,
    sub_line_txt: subLineTxt,
    dear_line_txt: dearLineTxt,
    congrats_line1_txt: congratsLine1Txt,
    // platform_type_txt: platformtypeTxt,
    platform_type_txt: "MED",
    congrats_line2_txt: congratsLine2Txt,
    here_line_txt: hereLineTxt,
    signin_line_title_txt: signinLinetitleTxt,
    signin_line1_txt: signinLine1Txt,
    platform_url: "https://med.akunah.com/",
    platform_url_txt: "med.akunah.com",
    signin_line2_txt: signinLine2Txt,
    signup_line_title_txt: signupLinetitleTxt,
    signup_line1_txt: signupLine1Txt,
    signup_line21_txt: signupLine21Txt,
    signup_line22_txt: signupLine22Txt,
    signup_line3_txt: signupLine3Txt,
    signup_line4_txt: signupLine4Txt,
    experience_line_txt: experienceLineTxt,
    thrilled_line_txt: thrilledLineTxt,
    regards_line_txt: regardsLineTxt,
    team_line_txt: teamLineTxt,
  }

  let org_admin = {
    akou_id: organization,
    org_name: orgNameSelected,
    email: email,
    role_type: userRoleType,
    sub_line_txt: subLineTxt,
    dear_line_txt: dearLineTxt,
    congrats_line1_txt: congratsLine1Txt,
    platform_type_txt: platformtypeTxt,
    congrats_line2_txt: congratsLine2Txt,
    here_line_txt: hereLineTxt,
    signin_line_title_txt: signinLinetitleTxt,
    signin_line1_txt: signinLine1Txt,
    platform_url: platformurl,
    platform_url_txt: platformurlTxt,
    signin_line2_txt: signinLine2Txt,
    signup_line_title_txt: signupLinetitleTxt,
    signup_line1_txt: signupLine1Txt,
    signup_line21_txt: signupLine21Txt,
    signup_line22_txt: signupLine22Txt,
    signup_line3_txt: signupLine3Txt,
    signup_line4_txt: signupLine4Txt,
    experience_line_txt: experienceLineTxt,
    thrilled_line_txt: thrilledLineTxt,
    regards_line_txt: regardsLineTxt,
    team_line_txt: teamLineTxt,
    // message_body: getMessageBody(userRoleType),
  }

  let both = {
    akou_id: organization,
    org_name: orgNameSelected,
    email: email,
    role_type: userRoleType,
    sub_line_txt: subLineTxt,
    dear_line_txt: dearLineTxt,
    congrats_line1_txt: congratsLine1Txt,
    // platform_type_txt: platformtypeTxt,
    platform_type_txt: "BOTH MED & CLINICIAN",
    congrats_line2_txt: congratsLine2Txt,
    here_line_txt: hereLineTxt,
    signin_line_title_txt: signinLinetitleTxt,
    signin_line1_txt: signinLine1Txt,
    // platform_url: "new_url",
    // platform_url_txt: platformurlTxt,
    platform_url: "https://both.akunah.com/",
    platform_url_txt: "both.akunah.com",
    signin_line2_txt: signinLine2Txt,
    signup_line_title_txt: signupLinetitleTxt,
    signup_line1_txt: signupLine1Txt,
    signup_line21_txt: signupLine21Txt,
    signup_line22_txt: signupLine22Txt,
    signup_line3_txt: signupLine3Txt,
    signup_line4_txt: signupLine4Txt,
    experience_line_txt: experienceLineTxt,
    thrilled_line_txt: thrilledLineTxt,
    regards_line_txt: regardsLineTxt,
    team_line_txt: teamLineTxt,
  }


  // console.log("User role", json)

  const addOrgAdminRequest = async () => {
    let apiUrl;
    let successMsg;
    // let faliedMsg;
    const currentUrl = window.location.hostname;

    if (userRoleType === 1) {

      let platform_ids = selectedOption.map((item) => {
        return {
          id: item.value,
          status: 1
        }
      })

      if (platform_ids.some(item => item.id === 1) && platform_ids.some(item => item.id === 2)) {
        json = {
          template: both,
          platform: platform_ids,
          email: email
        };
      } else if (platform_ids.some(item => item.id === 1)) {
        json = {
          template: clinician,
          platform: platform_ids,
          email: email
        };
      } else if (platform_ids.some(item => item.id === 2)) {
        json = {
          template: med,
          platform: platform_ids,
          email: email
        };
      }

      apiUrl = `extorg/admin/sendRequestHP?role=${userRoleType}&ak_id=${login.ak_id}&url=${currentUrl}&akou_id=${organization}`;
      successMsg = "Patient created successfully";
      // faliedMsg = "Patient not created";

      console.log("For request patient ", platform_ids, json)

    } else if (userRoleType === 2) {
      json = {
        template: org_admin,
        platform: [],
        email: email
      }
      console.log("For request orgggg", json)
      apiUrl = `extorg/org/create_org_admin?ak_id=${login.ak_id}&akou_id=${organization}`;
      successMsg = "Organization Admin created";
      // faliedMsg = "Organization Admin not created";
    } else if (userRoleType === 3) {

      let platform_ids = selectedOption.map((item) => {
        return {
          id: item.value,
          status: 1
        }
      })

      if (platform_ids.some(item => item.id === 1) && platform_ids.some(item => item.id === 2)) {
        json = {
          template: both,
          platform: platform_ids,
          email: email
        };
      } else if (platform_ids.some(item => item.id === 1)) {
        json = {
          template: clinician,
          platform: platform_ids,
          email: email
        };
      } else if (platform_ids.some(item => item.id === 2)) {
        json = {
          template: med,
          platform: platform_ids,
          email: email
        };
      }

      console.log("platform_idsplatform_ids", json)
      apiUrl = `extorg/admin/sendRequestHP?role=${userRoleType}&ak_id=${login.ak_id}&url=${currentUrl}&akou_id=${organization}`;
      successMsg = "HCP created successfully";

      console.log("For request HCP...", platform_ids, json)

    } else {
      //   apiUrl = `extorg/org/create_org_admin?ak_id=${login.ak_id}`;
      //   successMsg = "Organization Admin ceated";
      //   faliedMsg = "Organization Admin not created";
      console.log("role not found for user creation");
    }

    try {
      console.log("hhhhhhhhhhhjsonnn", json)
      setDisable(true)
      const response = await axiosInstance.current.post(
        apiUrl,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
        console.log("responseaaa",response)
      if(userRoleType===2){
        setMessage(successMsg)
        setMessageType("success");
        dispatch(noteRefs(!dataRefe))
        hideModal(false);
     
      }else{
        const result = response.data.data
        console.log("result", result)
  
        result.map((ele) => {
  
          if (ele.status === false) {
            console.log("elelele", ele)
            responseData.push(ele)
          } else {
            responseData2.push(ele)
  
          }
        })
        setErrorEmailData(responseData)
        setEmailSuccessData(responseData2)
        dispatch(noteRefs(!dataRefe))
  
        console.log("responnnsnsns", responseData)
  
  
        setTimeout(() => {
          // dispatch(apiResponseMsgData(false));
          setMessage(false)
          if (responseData.length === 0) {
            // navigate("/")
            dispatch(noteRefs(!dataRefe))
            setMessage(successMsg)
            setMessageType("success");
          }
  
        }, 3000);
  
        if (responseData.length === 0) {
          hideModal(false);
        }
        setDisable(false)
        setAdd("");
        // setOrganization("");
        setEmail([]);
      }
      // dispatch(apiResponseMsgData(successMsg));
      // dispatch(apiResponseMsgTypeData("success"));

      
    } catch (error) {
      setDisable(false)
      setMessage(error.response.data.data)
      setMessageType("error");
      setTimeout(() => {
        setMessage(false)
      }, 2000)
      // dispatch(apiResponseMsgData(error.response.data.data));
      // dispatch(apiResponseMsgTypeData("error"));

    }
  };


  console.log("responseDataresponseData", errorEmailData)


  const handleEditRequest = () => {

    if (email.length === 0 || (userRoleType === 3 || userRoleType === 1) && selectedOption.length === 0) {
      setEmailListError(true);
      setPlatError(true)
    }

    else if (organization.length === 0) {
      orgNameRef.current.errUpdate();
    }


    else {
      addOrgAdminRequest();
    }

  };

  const handleOrgChange = (e) => {
    setOrganization(e);
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const addEmails = (e) => {
    if (!isValidEmail(add)) {
      emailRef.current.errUpdate(); // Show error for invalid email
    } else {
      setEmail((oldEmail) => {
        return [...oldEmail, add]; // Add valid email to the list
      });
      setAdd("");
    }
  };
  const handleRemoveItem = (item) => {
    setEmail((prevState) => prevState.filter((prevItem) => prevItem !== item));
    setMessage("");
  };
  useEffect(() => {
    if (defaultData.length > 0) {
      setOrganization(defaultData[0].value);
    }
  }, [defaultData]);


  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  });



  useEffect(() => {
    if (showModal === false) {
      setAdd("")
      setEmail([])
      setSelectedOption([])
      setEmailListError(false);
      setPlatError(false)
      setErrorEmailData([])
      setEmailSuccessData([])

    }
  }, [showModal])


  console.log("selected_new_data", selectedOption)

  console.log("editPlatformList , newPlatFormData", editPlatformList, newPlatFormData)

  useEffect(() => {
    if (userRoleType === 3 || userRoleType === 1) {
      if (platForm !== undefined) {
        setFilterData(platFormData.filter((item) => platForm.includes(item.value)))
      } else {
        setFilterData(newPlatFormData.filter((item) => editPlatformList.includes(item.value)))
      }
    }
  }, [platForm, userRoleType])



  const handleChageErrorData = (id) => {
    const updatedErrorEmailData = errorEmailData.filter(ele => ele.id !== id);
    console.log("updatedErrorEmailData", updatedErrorEmailData)
    setErrorEmailData(updatedErrorEmailData);
  };

  const handleChageRightErrorData = (id)=>{
    const updatedErrorEmailSuccessData = emailSuccesData.filter(ele => ele.id !== id);
    console.log("updatedErrorEmailSuccessData", updatedErrorEmailSuccessData)
    setEmailSuccessData(updatedErrorEmailSuccessData);
  }



  console.log("errorEmailDataerrorEmailData", errorEmailData)
  return (
    <>

      {message && <Message message={message} type={messageType} />}

      <Modal
        show={showModal}
        onHide={hideModal}
        className={``}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-md patient_notes_popup org__type_popup"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            {userRoleType === 1 && <>Invite a Patient</>}
            {userRoleType === 2 && <>Invite an Organization Admin</>}
            {userRoleType === 3 && <>Invite an HCP</>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          {
            userRoleType === 3 || userRoleType === 1 ? (
              <>
                <label>Platforms</label>
                <Select
                  value={selectedOption}
                  isMulti
                  onChange={setSelectedOption}
                  options={filterData}
                  className="multi_select_drp_select_plat"
                  placeholder="Select Platforms"
                />
                {
                  selectedOption.length === 0 && (
                    <>
                      {platError && <span className="text-danger" style={{ marginLeft: "6px" }}>This field is required</span>}
                    </>
                  )
                }
              </>

            ) : ("")
          }
          {
            organization_id ? (
              <InputField
                label="Organization Id *"
                value={organization_id}
                type="text"
                ref={orgNameRef}
                placeholder="Enter org here"
                styleClass="br_10 mxw_100 mb_20"
                onChange={keyNameChange}
                disabled={true}
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                  {
                    check: Validators.email,
                    message: "Only contain Email",
                  },
                ]}
              />
            ) : (
              <Dropdown
                data={defaultData}
                label="Organization Name*"
                value={organization}
                onChange={handleOrgChange}
                placeholder="Select"
                styleClass="mxw_100 br_10 mb_20"
                ref={orgNameRef}
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                ]}
                disabled={readOnly}
              />
            )
          }

          <InputField
            label="Admin User Email*"
            value={add}
            type="text"
            ref={emailRef}
            placeholder="Enter email here"
            styleClass="br_10 mxw_100 mb_20"
            onChange={keyNameChange}
            validators={[
              {
                check: Validators.required,
                message: "This field is required",
              },
              {
                check: Validators.email,
                message: "Not a valid email",
              },
            ]}
          />
          {/* {errorEmailData.length === 0 ? "" : <p>This Email Id's Already Present in this Organization</p>} */}

          {/* <div className="row">
            <div className="col-md-12" >
              {

                errorEmailData && errorEmailData.map((ele) => {
                  return (
                    <>
                      {
                        ele.status === false ? (

                          <div class="alert alert-danger alert-dismissible fade show" role="alert">
                            <strong>Email : {ele.email} , Ak_id: {ele.id} ---- {ele.data}</strong>
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                          </div>

                        ) : ("")
                      }
                    </>
                  )
                })
              }

            </div>
          </div> */}
          <div className="row">
            {errorEmailData.length === 0 ? "" : <p className="emailError">Add/Rejected Email id's for {userRoleType===1 ? "Patients" : userRoleType===3 ? "HCP's" :""} in this organization *</p>}
            <div className="col-md-12 scrollbar_vert" style={{ maxHeight: "27vh" }}>
              {
                emailSuccesData && emailSuccesData.map((ele) => {
                  return (
                    <div class="alert alert-success alert-dismissible fade show" role="alert">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                      </svg>
                      <strong className="ms-2">Email : {ele.email} </strong><br />
                      <strong className="ms-4">Ak_id: {ele.id}</strong>
                      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => handleChageRightErrorData(ele.id)}></button>
                    </div>
                  )
                })
              }
              {
                errorEmailData && errorEmailData.map((ele) => {
                  return (
                    <div class="alert alert-danger alert-dismissible fade show" role="alert">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                        <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
                        <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                      </svg>
                      <strong className="ms-2">Email : {ele.email} </strong><br />
                      <strong className="ms-4">Ak_id: {ele.id}</strong>
                      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => handleChageErrorData(ele.id)}></button>
                    </div>
                  )
                })
              }
            </div>
          </div>



          <div className="text-right">
            <Button
              onClick={addEmails}
              value="+ Add Email"
              buttonStyle="btn_fill mr_20"
            />
          </div>
          {email.length !== 0 && (
            <div className="mt-5 border ">
              <table className="table m-0">
                <thead>
                  {email.map((itemval) => {
                    return (
                      <>
                        <tr>
                          <td>{itemval}</td>
                          <td>
                            {" "}
                            <button
                              className="bg-danger border-0 ms-auto me-1 text-white float-end"
                              onClick={() => handleRemoveItem(itemval)}
                            >
                              x
                            </button>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </thead>
              </table>
            </div>
          )}
          {emailListError && <span className="text-danger">Add at least one email</span>}
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "flex-start" }}>
          <Button
            onClick={handleCloseEdit}
            value="Cancel"
            buttonStyle="btn_nill mr_20"
          />
          <Button
            onClick={handleEditRequest}
            value="Save"
            width="120px"
            enable={disble}
            buttonStyle="btn_fill ms-4 clk_btn_disbled"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddOrgAdmin;
