import React, { useState } from "react";
import { useTable } from "react-table";
import TableSpinner from "../custom/spinner/TableSpinner";
import TableActionItems from "../custom/table-action-items/TableActionItems";
import InfiniteScroll from "react-infinite-scroll-component";
import Switch from "../custom/switch/Switch";
import useAxios from "../../axiosinstance";
import Message from "../custom/toster/Message";
import { useSelector, useDispatch } from "react-redux";
import { noteRefs } from "../../redux/actions";

function VideosListTable({ videosData, updatePagination, refresh, setRefresh ,totalData}) {
  const axiosInstance = useAxios();
  const dispatch = useDispatch();

  // const login = useSelector((state) => state.mainReducer.loginData.arr);
  //   const [doctorID, setDoctorID] = useState(login.ing_ak_id);
  const [isChecked, setIsChecked] = useState(false);
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [enable, setEnable] = useState(false)
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);



  const chanel = (data) => {
    let value = data.channels.filter((item) => item !== null)
    return (
      <>
        <span data-bs-toggle="tooltip">
          {value.map((item, index) => (
            <React.Fragment key={index}>
              {item.name}
              {index < value.length - 1 ? ', ' : ''}
            </React.Fragment>
          ))}
        </span>
      </>
    )
  }

  const actionData = {
    view_url: "patient-view",
    edit_url: "edit-post-details",
    delete_url: "",
  };
  //   function handleEdit(row) {
  //     console.log(row);
  //   }
  const data = videosData;

  const columns = React.useMemo(
    () => [
      {
        Header: "S No.",
        headerClassName: "col-sno",
        accessor: "s_no",
        className: "col-sno",
      },
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Posted By",
        accessor: "posted_by",
      },
      {
        Header: "Title",
        accessor: "title",
        Cell: (row) => (
          <span data-bs-toggle="tooltip" title={row.row.original.title}>
            {row.row.original.title.length > 25
              ? `${row.row.original.title.substring(0, 25)}...`
              : row.row.original.title}
          </span>
        ),
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: (row) => (
          <span data-bs-toggle="tooltip" title={row.row.original.description}>
            {row.row.original.description.length > 25
              ? `${row.row.original.description.substring(0, 25)}...`
              : row.row.original.description}
          </span>
        ),
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Tags",
        accessor: "categoryName",
        Cell: (row) => (
          <span data-bs-toggle="tooltip" title={row.row.original.categoryName.join(", ")}>
            {row.row.original.categoryName.length > 100
              ? `${row.row.original.categoryName.slice(0, 3).join(", ")}...`
              : row.row.original.categoryName.join(", ")}
          </span>
        ),
      },
      {
        Header: "Duration",
        accessor: "duration",
      },
      {
        Header: "Publish",
        accessor: "enabled",
        headerClassName: "col-status",
        className: "col-status",
        Cell: (row) => (
          <span>
            <Switch
              name={`on-off-switch${row.row.original.id}`}
              tabIndex={1}
              checked={row.row.original.enabled}
              onChange={(e) => handleSwitchChange(row.row.original.enabled, row.row.original.id)}
            />
          </span>
        ),
      },
      {
        Header: "Channels",
        accessor: "channels",
        Cell: (row) => (
          chanel(row.row.original)
        ),
      },

      {
        Header: "Video Size",
        accessor: "video_size",
        Cell: ({ value }) => (value === "NaN undefined" ? "" : value),
      },
      {
        Header: "Action",
        headerClassName: "col-action",
        accessor: "action",
        className: "col-action",
        Cell: (row) => (
          <span>
            <TableActionItems
              viewAction="false"
              editAction="false"
              editActionPopup="true"
              feedbackAction="false"
              deleteAction="true"
              arrowAction="false"
              rowName={"videoID"}
              rowId={row.row.original.ea_akpt_id}
              rowData={actionData}
              rowType={row.row.original.type}
              notetitle={row.row.original.title}
              rowOrgId={row.row.original.ea_akpt_id}
              orgId={row.row.original.ea_org_id}
              title={row.row.original.title}
              description={row.row.original.description}
              video_type={row.row.original.typeId}
              tags={row.row.original.objectList}
              channels={row.row.original.channels}
              posted_by={row.row.original.created_by}
              enabled={row.row.original.enabled}
              videoId={row.row.original.id}
              created_on={row.row.original.created_on}
              video_key={row.row.original.video_key}
              typeId={row.row.original.typeId}
              publish_date={row.row.original.publish_date}
              video_duration={row.row.original.duration}
              video_link={row.row.original.link}
              sizeOfVideo={row.row.original.video_size}
              org_id={row.row.original.akou_id}
              large_thumb={row.row.original.large_thumb}
              medium_thumb={row.row.original.medium_thumb}
              type="4"
            />
          </span>
        ),
      },
    ],
    []
  );



  const handleSwitchChange = async (status, value) => {
    let s;
    if (status === 1) {
      s = 0
    } else {
      s = 1
    }

    let json = {
      enabled: s
    }
    await axiosInstance.current.put(
      `extorg/admin/updateVideoStatus?id=${value}`, ///Not Done
      json,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      setMessage("Status Updated")
      setMessageType("success");
      dispatch(noteRefs(new Date().getSeconds()))
      setTimeout(() => {
        setMessage(false)
      }, 3000);
    }).catch((err) => {
      console.log("err", err)
      setMessage("Status Not Update")
      setMessageType("error");
    });






  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });
  console.log(videosData.length,"videosData");
  return (
    <>
      {message && <Message message={message} type={messageType} />}

      <div className="tableContainer" id="rpScrollableDiv" style={{minHeight: "1700px"}}>
        <InfiniteScroll
          dataLength={rows.length}
          next={updatePagination}
          hasMore={true}
          scrollableTarget="rpScrollableDiv"
          // loader={<TableSpinner />}
          loader={ videosData.length >= 25 && videosData.length !== totalData  ? <TableSpinner /> : null}
        >
          <table {...getTableProps()} className="custom-tbl">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps([
                        { className: column.headerClassName },
                      ])}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps([
                            { className: cell.column.className },
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </>
  );
}

export default VideosListTable;
