export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const FETCH_SUBID_SUCCESS = "FETCH_SUBID_SUCCESS";
export const FETCH_SUBID_FAIL = "FETCH_SUBID_FAIL";
export const FETCH_MAIL_SUCCESS = "FETCH_MAIL_SUCCESS";
export const FETCH_MAIL_FAIL = "FETCH_MAIL_FAIL";
export const UPDATE_ADULT_KNEE_DATA = "UPDATE_ADULT_KNEE_DATA";
export const UPDATE_ADULT_KNEE_PAIN_FIELD_DATA = "UPDATE_ADULT_KNEE_PAIN_FIELD_DATA";
export const UPDATE_ADULT_KNEE_PAIN_DATA = "ADULT_KNEE_PAIN_DATA";
export const UPDATE_ADULT_KNEE_SYMPTOMS_DATA_SINGLE = "UPDATE_ADULT_KNEE_SYMPTOMS_DATA_SINGLE";
export const UPDATE_ADULT_KNEE_SYMPTOMS_DATA = "UPDATE_ADULT_KNEE_SYMPTOMS_DATA";
export const UPDATE_ADULT_KNEE_FUNCTION_FIELD_DATA = "UPDATE_ADULT_KNEE_FUNCTION_FIELD_DATA";
export const UPDATE_ADULT_KNEE_FUNCTION_DATA = "UPDATE_ADULT_KNEE_FUNCTION_DATA";
export const UPDATE_ADULT_KNEE_QOL_FIELD_DATA = "UPDATE_ADULT_KNEE_QOL_FIELD_DATA";
export const UPDATE_ADULT_KNEE_QOL_DATA = "UPDATE_ADULT_KNEE_QOL_DATA";
export const UPDATE_ADULT_KNEE_SATISFACTION_FIELD_DATA = "UPDATE_ADULT_KNEE_SATISFACTION_FIELD_DATA";
export const UPDATE_ADULT_KNEE_SATISFACTION_DATA= "UPDATE_ADULT_KNEE_SATISFACTION_DATA";
export const UPDATE_ADULT_KNEE_TOTAL_SCORES= "UPDATE_ADULT_KNEE_TOTAL_SCORES";
export const API_MESSAGE = "API_MESSAGE";
export const API_MESSAGETYPE = "API_MESSAGETYPE";


//channel list//
export const GET_CHANNEL_LIST= "GET_CHANNEL_LIST";
export const GET_TAGS_LIST= "GET_TAGS_LIST";

export const GET_CHANNEL_KEYWORD= "GET_CHANNEL_KEYWORD";
export const SET_CHANNEL_ORG_LIST ="SET_CHANNEL_ORG_LIST"


