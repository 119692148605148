import React, { useState, useEffect, useRef, useMemo } from "react";
import AddOrganization from "./AddOrganization";

function CreateOrganization() {
  return (
    <div className="create_org_screen">
      <div className="row page_header">
        <div className="col-sm-6 col-md-6">
          <h4 className="ph_title">Create Organization</h4>
        </div>
        <div className="col-sm-6 col-md-6 text-end"></div>
      </div>
      <AddOrganization />
    </div>
  );
}

export default CreateOrganization;
