import React from "react";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import sortDropStyles from "./SortDropdownAlphabet.module.css";
import arrowSmall from "../../../images/arrowbluesm.svg";

const SortDropDownAlphabet = ({
  // value,
  // label,
  // data,
  // placeholder,
  // styleClass,
  onChange
}) => {
  const handleChange = (event) => {
    console.log(event)
    const value = event;
    onChange(value);
  };

  return (
    <>
      <div
        className={`text-end custom_sort_dropdown ${sortDropStyles.sort_dropdown_main}`}
      >
        <Dropdown className={`custom_dropdown ${sortDropStyles.drop_down}`}>
          <Dropdown.Toggle
            className={sortDropStyles.dropdown_button}
            variant="default"
            id="dropdown-basic"
          >
            Sort{" "}
            <span>
              <img className={`arrow_img`} src={arrowSmall} alt="arrow" />
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu className="mt-1 miku" >
            <Dropdown.Item href="" onClick={() => handleChange(0)}  > Ascending</Dropdown.Item>
            <Dropdown.Item href="" onClick={() => handleChange(1)}  > Descending</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

SortDropDownAlphabet.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.array,
  styleClass: PropTypes.string,
  onChange: PropTypes.func,
};

SortDropDownAlphabet.defaultProps = {
  value: "",
  label: "",
  styleClass: "",
  placeholder: "",
};

export default SortDropDownAlphabet;
