import React, { useState, useSelector } from "react";
import { useTable } from "react-table";
import TableSpinner from "../custom/spinner/TableSpinner";
import TableActionItems from "../custom/table-action-items/TableActionItems";
import InfiniteScroll from "react-infinite-scroll-component";
function PatientsTable({ patientsData, updatePagination }) {
  // const login = useSelector((state) => state.mainReducer.loginData.arr);
  //   const [doctorID, setDoctorID] = useState(login.ing_ak_id);

  const actionData = {
    view_url: "patient-view",
    edit_url: "edit-patient-details",
    delete_url: "",
  };
  //   function handleEdit(row) {
  //     console.log(row);
  //   }
  const data = patientsData;

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        id: "1",
        headerClassName: "col-img",
        className: "col-img",
        accessor: "patientName",
        Cell: (row) => (
          <span>
            <label className="user-letter small">
              {row.value.charAt(0).toUpperCase()}
            </label>
          </span>
        ),
      },
      {
        Header: "Patient ID",
        accessor: "patientId",
      },
      {
        Header: "Patient Name",
        accessor: "patientName",
      },
      {
        Header: "Email",
        accessor: "email",
        className: "text-lowercase w-50",
      },
      {
        Header: "Action",
        headerClassName: "col-action",
        accessor: "action",
        className: "col-action",
        Cell: (row) => (
          <span>
            <TableActionItems
              viewAction="false"
              editAction="true"
              feedbackAction="false"
              deleteAction="true"
              arrowAction="false"
              rowName={"patientID"}
              rowId={row.row.original.ea_akpt_id}
              rowData={actionData}
              rowType="patients"
              notetitle={row.row.original.ea_name}
              rowOrgId={row.row.original.ea_akpt_id}
              orgId={row.row.original.ea_org_id}
            />
          </span>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });
  return (
    <>
      <div className="tableContainer" id="rpScrollableDiv">
        <InfiniteScroll
          dataLength={rows.length}
          next={updatePagination}
          hasMore={true}
          scrollableTarget="rpScrollableDiv"
          loader={<TableSpinner />}
        >
          <table {...getTableProps()} className="custom-tbl">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps([
                        { className: column.headerClassName },
                      ])}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps([
                            { className: cell.column.className },
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </>
  );
}

export default PatientsTable;
