import React, { useState, useEffect, useRef } from "react";
import LoadingSpinner from "../../custom/loader/LoadingSpinner";
import EditOrganization from "./EditOrganization";

function EditOrganizationDetails() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="edit_org_screen mb_100">
          <div className="row page_header">
            <div className="col-sm-6 col-md-6">
              <h4 className="ph_title">View Organization Details</h4>
            </div>
          </div>

          <EditOrganization />
        </div>
      )}
    </>
  );
}

export default EditOrganizationDetails;
