import React from "react";
import { Modal, Button } from "react-bootstrap";
import "../delete-confirmation/DeleteConfirmation.css";
import ActiveIcon from "../../../images/Active.png";

const ConfirmPopup = ({
  showModal,
  hideModal,
  confirmModal,
  id,
  type,
  message,
}) => {
  return (
    <Modal
      show={showModal}
      onHide={hideModal}
      className={`delete_modal_main confirm_modal`}
      centered
    >
      <Modal.Body>
        <div className="text-center">
          <img src={ActiveIcon} className="delete_pp_icon" alt="icon" />
          <h4 className="center_title">
            {message}
          </h4>
        </div>
      </Modal.Body>
      <Modal.Footer className="text-center">
        <Button variant="default" className="btn_outline" onClick={hideModal}>
          Cancel
        </Button>
        <Button
          variant=""
          className="btn_green"
          onClick={() => confirmModal(type, id)}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmPopup;
