import React, { useState } from "react";
import { useTable } from "react-table";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TableSpinner from "../../custom/spinner/TableSpinner";
import InfiniteScroll from "react-infinite-scroll-component";
import useAxios from "../../../axiosinstance";
import Button from "../../custom/button/Button";
import Confirmation from "../../custom/confirmation-popup/ConfirmPopup";
import ApproveConfirmation from "../../custom/approve-confirm/ApproveConfirmation";
import Message from "../../custom/toster/Message";
import { useDispatch, useSelector } from "react-redux";
import { noteRefs } from "../../../redux/actions";

function HcpPendingTable({ pendingOrgData, updatePagination, orgId ,totalData}) {
  const login = useSelector(
    (state) =>
      state.mainReducer.loginData.arr && state.mainReducer.loginData.arr[0]
  );
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );
  const [displayRejectModal, setDisplayRejectModal] = useState(false);
  const [rowType, setRowType] = useState("approveList");
  const [approveId, setApproveId] = useState("");
  const [approveStatus, setApproveStatus] = useState("");
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const actionData = {
    view_url: "patient-view",
    edit_url: "patient-demographic",
    delete_url: "",
  };
  const data = pendingOrgData;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axiosInstance = useAxios();

  function handleReject(id, status) {
    setApproveId(id);
    setApproveStatus(status);
    setDisplayRejectModal(true);
  }
  // Hide the modal
  const hideRejectModal = () => {
    setDisplayRejectModal(false);
  };

  const rejectRequest = async () => {
    if (rowType === "approveList") {
      const response = await axiosInstance.current.put(
        `extorg/org/updateuser?ak_id=${approveId}&status=${approveStatus}`
      );
      if (response.status === 200) {
        setMessage("User Rejected Successfully");
        setMessageType("success");
        dispatch(noteRefs(!dataRefe));
        setTimeout(() => {
          setMessage(false);
        }, 3000);
      } else {
        setMessage("Api issue");
        setMessageType("error");
      }
    }
    setDisplayRejectModal(false);
  };

  const handleApprove = async (id, status) => {
    setApproveId(id);
    setApproveStatus(status);
    setDisplayConfirmationModal(true);
  };
  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item based on type
  const approveRequest = async () => {
    if (rowType === "approveList") {
      const response = await axiosInstance.current.put(
        // `extorg/admin/approve?ak_id=${login.ak_id}&org_id=${orgId}&status=${approveStatus}&id=${approveId}`
        `extorg/org/updateuser?ak_id=${approveId}&status=${approveStatus}`
      );
      if (response.status === 200) {
        setMessage("User Approved Successfully");
        setMessageType("success");
        dispatch(noteRefs(!dataRefe));
        setTimeout(() => {
          setMessage(false);
        }, 3000);
      } else {
        setMessage("Api issue");
        setMessageType("error");
      }
    }
    setDisplayConfirmationModal(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        id: "1",
        headerClassName: "col-img",
        className: "col-img",
        accessor: "name",
        Cell: (row) => (
          <span>
            <label className="user-letter small">
            {row.value.substring(0, 2).toLocaleUpperCase() === "NA" ? row.value.substring(0, 2).toLocaleUpperCase() : row.value.charAt(0).toUpperCase()}

            </label>
          </span>
        ),
      },
      {
        Header: "User Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: (row) => (
          <>
          {
            <p className="c_pointer org_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.row.original.email}>{row.row.original.email}</p>
          }
          </>
        )
      },
      {
        Header: "Doctor ID",
        accessor: "ak_id",
      },
      // {
      //   Header: "Org Id",
      //   accessor: "org_id",
      //   headerClassName: "col-user-ids",
      //   className: "col-user-ids",
      //   Cell: ({ cell }) => (
      //     <>
      //       {cell.value.map((id) => (
      //         <span key={id} style={{display:'flex', paddingRight:'10px'}}>{id}</span>
      //       ))}
      //     </>
      //   ),
      // },
      {
        Header: "Action",
        headerClassName: "col-action",
        accessor: "action",
        className: "col-action",
        Cell: (row) => (
          <>
            <Button
              onClick={() => handleApprove(row.row.original.ak_id, 1)}
              value="Approve"
              buttonStyle="btn_fill btn_green wpx_100 mr_15 br_15"
            />
            <Button
              onClick={() => handleReject(row.row.original.ak_id, -1)}
              value="Reject"
              buttonStyle="btn_fill wpx_100 btn_danger br_15"
            />
          </>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });
  return (
    <>
      {message && <Message message={message} type={messageType} />}
      <div
        className="tableContainer approve_list_tbl"
        id="approveScrollableDiv"
      >
        <InfiniteScroll
          dataLength={rows.length}
          next={updatePagination}
          hasMore={true}
          scrollableTarget="approveScrollableDiv"
          // loader={<TableSpinner />}
          loader={ pendingOrgData.length >= 25 && pendingOrgData.length !== totalData  ? <TableSpinner /> : null}

        >
          <table {...getTableProps()} className="custom-tbl">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps([
                        { className: column.headerClassName },
                      ])}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps([
                            { className: cell.column.className },
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
      <Confirmation
        showModal={displayConfirmationModal}
        confirmModal={approveRequest}
        hideModal={hideConfirmationModal}
        message={"Are you sure want to Approve?"}
        type={rowType}
        //    id={rowId}
      />
      <ApproveConfirmation
        showModal={displayRejectModal}
        confirmModal={rejectRequest}
        hideModal={hideRejectModal}
        message={"Are you sure want to reject a Request?"}
        type={rowType}
        btnRightLabel={"Reject"}
        //    id={rowId}
      />
    </>
  );
}

export default HcpPendingTable;
