import React, { useState, useRef, useEffect } from "react";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";

function GoogleAddress({ onAddressChange, label, resetLocation, valid,value, setPostCode, setCity }) {
  const [location, setLocation] = useState("");
  const [addressError, setAddressError] = useState(valid);
  const inputRef = useRef(null);
  let YOUR_API_KEY = "AIzaSyAb4s2rhkg_dDms-SKZx16Ka5zDcU6aEIs";
  //   console.log(addressError);
//   function isGoogleMapsReady() {
//     return !!window.google && !!window.google.maps && !!window.google.maps.places;
//   }
  function handlePlaceChanged() {
    const place = inputRef.current.getPlaces()[0];
    if (place) {
      setLocation(place.formatted_address);
      onAddressChange(place.formatted_address);
      place.address_components.map((ele) => {
        if (ele.types.includes("postal_code")) {
          // console.log(ele.long_name);
          setPostCode(ele.long_name)
        }
      });
      place.address_components.map((ele) => {
        if (ele.types.includes("locality")) {
          // console.log(ele.long_name);
          setCity(ele.long_name)
        }
      });
    }
  }

  function handleLocation(value) {
    setAddressError(false);
    onAddressChange(value);
    setLocation(value);
  }

  //   function validateAddress() {
  //     if (location.trim() === "") {
  //       setAddressError(true);
  //       return false;
  //     }
  //     return true;
  //   }

  // Function to reset the location state
  const resetLocationState = () => {
    setLocation("");
  };
  useEffect(() => {
    resetLocationState();
  }, [resetLocation]);

  return (
    <>
      {label && <label htmlFor="app-input-field">{label} </label>}
      <LoadScript
        googleMapsApiKey={YOUR_API_KEY}
        libraries={["places"]}
        onLoad={() => console.log("LoadScript loaded")}
      >
        <StandaloneSearchBox
          onLoad={(ref) => (inputRef.current = ref)}
          onPlacesChanged={handlePlaceChanged}
        >
          <input
            type="text"
            className="form-control mxw_100 br_10"
            placeholder="* Enter Address"
            value={value?value:location}
            onChange={(e) => handleLocation(e.target.value)}
          />
        </StandaloneSearchBox>
      </LoadScript>

      {valid && <p className="text-danger">This Field required</p>}
    </>
  );
}
export default GoogleAddress;
