
const initialState = {
  channelsData: [],
  org_channel: [],
  keyword: ""
}

const channeldata = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CHANNEL_LIST':
      return { ...state, channelsData: action.data };

    case 'SET_CHANNEL_ORG_LIST':
      return {
        ...state,
        org_channel: action.payload
      };


    case 'GET_CHANNEL_KEYWORD':
      return { ...state, keyword: action.key };

    default:
      return state;
  }
}

export default channeldata;