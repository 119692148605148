import React, { useState, useEffect, useRef } from "react";
import { MultiSelect } from "react-multi-select-component";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Button from "../../custom/button/Button";
import Dropdown from "../../custom/dropdown/Dropdown";
import InputField from "../../custom/inputfield/InputField";
import LoadingSpinner from "../../custom/loader/LoadingSpinner";
import { Validators } from "../../../utilities/Validator";
import useAxios from "../../../axiosinstance";
import Message from "../../custom/toster/Message";
import { useSelector, useDispatch } from "react-redux";
import { noteRefs } from "../../../redux/actions";
import DropDownOrgList from "../../custom/dropdown/DropDownOrgList";
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';


const EditChannelDetails = ({
  showModal,
  hideModal,
  confirmModal,
  id,
  type,
  name,
  role,
  all,
  org_id,
  orglist,
  channelId,
  usersData
}) => {

  const navigate = useNavigate();
  const axiosInstance = useAxios();
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true);
  const [channelid, setChannelId] = useState("")
  const [channelName, setChannelName] = useState("");
  const [orgModel, setOrgModel] = useState("");
  const [roleModel, setRoleModel] = useState("");
  const [publishModel, setPublishModel] = useState("");
  const [disbaled,setDisabled]=useState(false)
  const[patientErr,setpatienterr]=useState(false)

  let result = []

  const [orgapiData, setOrgData] = useState([]);
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [roleApiData, setRoleApiData] = useState([
    { value: "0", label: "Any User" },
    { value: "3", label: "Only HCP" },
    { value: "1", label: "Only Patient" },
  ]);
  const [publishApiData, setPublishApiData] = useState([
    { value: true, label: "All Users" },
    { value: false, label: "Selected Users" },
  ]);


  const[disble,setDisable]=useState(false)

  const [allUsersList, setAllUsersList] = useState([]);
  const [allSelectedUsers, setAllSelectedUsers] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);


  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
  const profile = useSelector((state) => state.mainReducer.loginData.arr);

  const akTokenOrg = localStorage.getItem("AKTOKENORG")

  const orgModelRef = useRef();
  const roleModelRef = useRef();
  const channelNameRef = useRef();
  const publishModelRef = useRef();
  const handleCloseEdit = (e) => {
    setChannelName("")
    setOrgModel("")
    setRoleModel("")
    setPublishModel("")
    setAllSelectedUsers([])
    setSelectedOption([])
    hideModal(false);
  };



  const handleOrgModle = (e) => {
    setOrgModel(e);
    setSelectedOption([])
    setAllUsersList([])
  };
  const handleRoleModle = (e) => {
    setRoleModel(e);
    setAllUsersList([])
    setSelectedOption([])

  };
  const handlePublishModle = (e) => {
    setPublishModel(e);
    setSelectedOption([])
    setAllUsersList([])
  };

  const channelNameChange = (e) => {
    setChannelName(e);
  };

  const handleAllUsersChange = (e) => {
    setAllSelectedUsers(e);
  };


  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  });


  useEffect(() => {
    if (usersData) {
      setSelectedOption(usersData)
    }
  }, [usersData])


  useEffect(() => {
    if(name || org_id || role || all || channelId){
      setChannelName(name)
      setOrgModel(org_id)
      setRoleModel(role==="" ? "0":role)
      setPublishModel(String(all))
      setChannelId(channelId)
    }

  }, [name,org_id,role,all,channelId])
  




  const handleEditRequest = async () => {
    if (!channelName) {
      channelNameRef.current.errUpdate();
    }
    if (!roleModel) {
      roleModelRef.current.errUpdate();
    }
    if (!publishModel) {
      publishModelRef.current.errUpdate();
    }
         
    console.log("comming 1",publishModel)

    if(publishModel===false || publishModel==="false"){
      if(selectedOption.length===0){
        setpatienterr(true)
        return 
      }
    }
    console.log("comming 2",publishModel,selectedOption)

    if (
      !channelName ||
      !roleModel ||
      !publishModel
    ) {
    }
    else {
      setDisable(true)
      let json = {
        akou_id: orgModel || null,
        name: channelName,
        role_id: roleModel === "0" ? "" : Number(roleModel),
        all: check(publishModel),
        ak_ids: result
      }
      console.log("json data", json)
      const response = await axiosInstance.current.put(
        `extorg/admin/updateChannel?id=${Number(channelid)}&ak_id=${akTokenOrg}`, json, {
        headers: {
          "Content-Type": "application/json",
        },
      }
      );
      setMessage("Channel Updated")
      setMessageType("success");
      setDisable(false)
      dispatch(noteRefs(!dataRefe))
      // setChannelName("")
      // setOrgModel("")
      // setRoleModel("")
      // setPublishModel("")
      setTimeout(() => {
        setMessage(false)
      }, 3000);

      hideModal(false);
    }
  }
  const handleSelect = (event, newValue) => {
    setSelectedOption(newValue);
  };
  selectedOption.map((ele) => {
    result.push(ele.value)
  })

  const check = (data) => {
    if (data === false || data === "false") {
      return false
    } else {
      return true
    }

  }

  const handleOnSearch = (value, results) => {
    // console.log(value);
    if (value.length > 2) {
      setTimeout(() => {
        axiosInstance.current.get(`extorg/admin/searchUser?ak_id=${akTokenOrg}&key=${value}&type=${Number(roleModel)}&akou_id=${orgModel}`)
          .then((response) => {
            const options = response.data.data.map((item) => {
              return {
                value: item.value,
                label: item.label
              };
            });
            setAllUsersList(options);
          })
          .catch((error) => {

            setAllUsersList([]);
          });
      }, 2000);

    } else {
      setAllUsersList([]);
    }
  };

  useEffect(() => {
    setOrgData(orglist)
  }, [])


  useEffect(() => {
    if(profile[0].type===2){
      setDisabled(true)
    }else{
      setDisabled(false)

    }
  }, [profile])
  

  console.log("selectedOptionselectedOption",selectedOption)

  return (
    <>
      {message && <Message message={message} type={messageType} />}

      <Modal
        show={showModal}
        onHide={hideModal}
        className={``}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-md patient_notes_popup channels_popup"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">Edit Channel</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <DropDownOrgList
            data={orgapiData}
            label="Organization"
            value={orgModel}
            onChange={handleOrgModle}
            placeholder="Select Organization"
            styleClass="br_10 mxw_100 mb_20"
            ref={orgModelRef}
            disabled={disbaled}
          // validators={[
          //   {
          //     check: Validators.required,
          //     message: "This field is required",
          //   },
          // ]}
          />
          <InputField
            label="Name"
            value={channelName}
            ref={channelNameRef}
            type="text"
            placeholder="Enter channel here"
            styleClass="br_10 mxw_100 mb_20"
            onChange={channelNameChange}
            validators={[
              {
                check: Validators.required,
                message: "This field is required",
              },
            ]}
          />
          <Dropdown
            data={roleApiData}
            label="Visible To"
            value={roleModel}
            onChange={handleRoleModle}
            placeholder="Select Role"
            styleClass="br_10 mxw_100 mb_20"
            ref={roleModelRef}
            validators={[
              {
                check: Validators.required,
                message: "This field is required",
              },
            ]}
          />
          <Dropdown
            data={publishApiData}
            label="Assigned To"
            value={publishModel}
            onChange={handlePublishModle}
            placeholder="Select"
            styleClass="br_10 mxw_100 mb_20"
            ref={publishModelRef}
            validators={[
              {
                check: Validators.required,
                message: "This field is required",
              },
            ]}
          />
          {publishModel === false || publishModel === "false" ? (
            //    <MultiSelect
            //      options={allUsersList}
            //     value={allSelectedUsers}
            //   onSelect={(event) => selectOption(event)}
            //   onRemove={(event) => selectOption(event)}
            //      onChange={handleAllUsersChange}
            //      displayValue="title"
            //    className="multi_select_main"
            //  showCheckbox
            //   />
            <>
              <label>Users</label>
              <div className="mr_15">
                <div className="row">
                  <div className="col-md-12 col-sm-12 mb_10  organization-drop">
                    <div className="dropdown_main_1">
                      <span className="white_shade"></span>
                      <Autocomplete
                        multiple
                        // freeSolo
                        id="free-solo-2-demo"
                        // disableClearable={true}
                        options={allUsersList}
                        value={selectedOption}
                        className="w-100"
                        onChange={handleSelect}
                        // disableClearable={disable}
                        renderInput={(params) => (
                          <TextField
                            placeholder={"Users Name"}
                            className=""
                            style={
                              {
                                // height: "52px",
                                // border: "2px solid #7867a8",
                                // borderColor: "#7867a8",
                                // borderRadius: "7px",
                                // backgroundColor: "white",
                                // boxShadow: "none",
                                // // hoverBackgroundColor: "#3f2783",
                                // hoverColor: "#fff",
                                // color: "black",
                                // fontSize: "12px",
                                // fontFamily: "Courier",
                                // iconColor: "#7867a8",
                                // // lineColor: "black",
                                // placeholderColor: "black",
                                // clearIconMargin: "3px 8px 0 0",
                              }
                            }
                            {...params}
                            onChange={(e) => handleOnSearch(e.target.value)}
                            // onKeyUp={(e) => handleOnSearch(e.target.value)}
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                          />
                        )}
                      />

                    </div>
                    {selectedOption.length === 0 && (
                            <>
                              {patientErr && (
                                <span className="text-danger mt-2">
                                  This field is required
                                </span>
                              )}
                            </>
                          )}

                  </div>
                </div>
              </div>




            </>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "flex-start" }}>
          <Button
            onClick={handleCloseEdit}
            value="Cancel"
            buttonStyle="btn_nill mr_20"
          />
          <Button
            onClick={handleEditRequest}
            value="Update"
            width="120px"
            enable={disble}
            buttonStyle="btn_fill ms-4 clk_btn_disbled"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditChannelDetails;
