import React, { useState, Suspense, useEffect } from "react";
import { useTable } from "react-table";
import TableSpinner from "../custom/spinner/TableSpinner";
import TableActionItems from "../custom/table-action-items/TableActionItems";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import Checkbox from "../custom/checkbox/Checkbox";

function RecentPatientTable({ patientdata, updatePagination, setSelectd, patient_ids}) {
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const [doctorID,] = useState(login.ing_ak_id);


  const data = patientdata;
  // const [data, setData] = useState(patientdata);
  const actionData = {
    view_url: "patient-view",
    edit_url: "patient-demographic",
    mark_url: "shoulder-treatment-form/step-1",
    delete_url: "",
  };
console.log("patient_idspatient_ids",patient_ids)
  // const [selectedIds, setSelectedIds] = useState(
  //   data.filter((item) => patient_ids.includes(item.ak_id)).map((item) => item.ak_id)
  // );
  // const [selectedIds, setSelectedIds] = useState(
  //   patient_ids.length > 0
  //     ? data
  //       .filter((item) => patient_ids.includes(item.ak_id))
  //       .map((item) => item.ak_id)
  //     : []
  // );
  const [selectedIds, setSelectedIds] = useState(() => {
    if (patient_ids === -1) {
      // All patients are selected
      return [-1];
    } else if (Array.isArray(patient_ids) && patient_ids.length > 0) {
      // Select specific patients based on patient_ids
      return patient_ids;
    } else {
      // No patients are selected
      return [];
    }
  });
  const handleCheckboxChange = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleSelectAll = () => {
    if (selectedIds.includes(-1)) {
      setSelectedIds([]);
      // patient_ids = []
    } else {
      setSelectedIds([-1]);
    }
  };

  useEffect(() => {
    setSelectd(selectedIds);
  }, [selectedIds, setSelectd]);

  console.log("selectedIds", selectedIds);

  const columns = React.useMemo(
    () => [
      {
        Header: "Action",
        accessor: "ak_idss",
        Cell: (row) => (
          <>
            <span className="custom-checkbox">
              <input
                type="checkbox"
                checked={selectedIds.includes(-1) || selectedIds.includes(row.row.original.ak_id)}
                onChange={() => handleCheckboxChange(row.row.original.ak_id)}
              />
            </span>
          </>
        ),
      },
      // {
      //   Header: "",
      //   id: "1",
      //   accessor: "name",
      //   Cell: ({row}) => (
      //     <span>
      //       <label className="user-letter small">
      //         {row.name.charAt(0).toUpperCase()}
      //       </label>
      //     </span>
      //   ),
      // },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "DOB",
        accessor: "dob",
      },
      {
        Header: "AKPT ID",
        accessor: "ak_id",
      },
      {
        Header: "Email",
        accessor: "email",
        className: "text-lowercase w-50",
      },
    ],
    [selectedIds]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <>
      <Suspense fallback={""}>
        {/* <label>
          <input
            type="checkbox"
            checked={selectedIds.includes(-1)}
            onChange={handleSelectAll}
          />
          Select All
        </label> */}
        <div className="row">
          <div className="" style={{
            width: '300px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',

          }}>
            <span className="custom-checkbox">
              <input
                type="checkbox"
                checked={selectedIds.includes(-1)}
                onChange={handleSelectAll}
              />
            </span>
            <label style={{
              marginLeft: '4px',
              marginTop: '1px'
            }} >Select All</label>
          </div>
        </div>
        <div className="tableContainer1 scroller_pt_list" id="rpScrollableDiv">
          <InfiniteScroll
            dataLength={rows.length}
            next={updatePagination}
            hasMore={true}
            scrollableTarget="rpScrollableDiv"
            // loader={<TableSpinner />}
          // loader={ patientdata.length >= 25 && patientdata.length !== totaldata  ? <TableSpinner /> : null}

          >
            {
              selectedIds.includes(-1) ? (
                <div className="text-center">
                <div class="no_data" style={{fontSize:"x-large"}}>All patients are selcted</div>
                </div>
              ):(
                <table {...getTableProps()} className="custom-tbl">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps([
                            { className: column.headerClassName },
                          ])}
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr key={row.ak_id} {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              key={cell.ak_id}
                              {...cell.getCellProps([
                                { className: cell.column.className },
                              ])}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              )
            }
           
          </InfiniteScroll>
        </div>
      </Suspense>
    </>
  );
}

export default RecentPatientTable;

