import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "../custom/button/Button";
import InputField from "../custom/inputfield/InputField";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import useAxios from "../../axiosinstance";
import { useSearchParams } from "react-router-dom";
import PlanListTable from "./PendingListTable";
import Message from "../custom/toster/Message";
import { Validators } from "../../utilities/Validator";
import { useSelector, useDispatch } from 'react-redux'
import { noteRefs } from "../../redux/actions";
import PendingListTable from "./PendingListTable";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import PendingApprovalTable from "./pendingApproval/PendingApprovalTable";
import PendingPendingTable from "./pendingApproval/PendingPendingTable";
import PendingRejectedTable from "./pendingApproval/PendingRejectedTable";
import Select from "react-select";


function PendingSpecialityList() {
  const navigate = useNavigate();
  const axiosInstance = useAxios();

  const login = useSelector(
    (state) =>
      state.mainReducer.loginData.arr && state.mainReducer.loginData.arr[0]
  );
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);

  const [isLoading, setIsLoading] = useState(true);
  const [key, setKey] = useState("pending");
  const [offset, setOffset] = useState(25);
  const [offsetApproval, setOffsetApproval] = useState(25);
  const [offsetReject, setOffsetReject] = useState(25);
  const [limit, setLimit] = useState(25);
  const [role, setRole] = useState("");
  const [akouId, setAkouId] = useState("");
  const [aLlOrgList, setALlOrgList] = useState([]);
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isRtl, setIsRtl] = useState(false);

  const [loader1, setloader1] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [loader3, setloader3] = useState(false);

  const [lastTypingTime, setLastTypingTime] = useState(null);


  const [orgPendingList, setOrgPendingList] = useState([]);
  const [orgApprovalList, setOrgApprovalList] = useState([]);
  const [orgRejectedList, setOrgRejectedList] = useState([]);
  const [search, setSerach] = useState("")

  const [totalData, setTotalData] = useState("")

  const handleOrgListData = (e) => {
    const akouIdValue = e !== null ? e.value : "";
    setAkouId(akouIdValue);
  };
  const handleSelect = (key) => {
    setSerach("")
    if (key === "pending") {
      setOffset(25)
      setKey(key);
      // getTabData("");
    } else if (key === "rejected") {
      setOffsetReject(25)
      setKey(key);
      // getTabData("");
    } else if (key === "approved") {
      setOffsetApproval(25)
      setKey(key);
      // getTabData("");
    }
  };
  useEffect(() => {
    const getAllOrgList = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/admin/orgs?ak_id=${login.ak_id}`
        );

        const newData = response.data.data.map((item) => ({
          value: item.id,
          label: item.o_nm,
          plan_id: item.plan_id,
        }));
        // setAkouId(newData[0].value === undefined ? "" : newData[0].value);
        setALlOrgList(newData);
      } catch (err) {
        console.log("An error occurred while fetching data.");
      }
    };
    getAllOrgList();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    const getData = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/org/doctorSpeciality?status=0&limit=${limit}&offset=${0}&keyword=${search}&org_id=${akouId}`
        );
        if (response.status === 200) {
          setloader1(true)
          setOrgPendingList(response.data.total);
          setTotalData(response.data.total_data)
        }
      } catch (error) {
        console.log("An error occurred while fetching data.");
      }
    };
    if (key === "pending")
      getData();
  }, [akouId, dataRefe, key,]);








  useEffect(() => {
    if (lastTypingTime) {
      const timer = setTimeout(() => {
        const getData = async () => {
          try {
            const response = await axiosInstance.current.get(
              `extorg/org/doctorSpeciality?status=0&limit=${limit}&offset=${0}&keyword=${search}&org_id=${akouId}`
            );
            if (response.status === 200) {
              setloader1(true)
              setOrgPendingList(response.data.total);
              setTotalData(response.data.total_data)
            }
          } catch (error) {
            console.log("An error occurred while fetching data.");
          }
        };
        if (key === "pending")
          getData();

      }, 1000);
      return () => clearTimeout(timer)
    }
  }, [search])


















  const addMoreData = async () => {
    setOffset(offset + 25);
    try {
      const response = await axiosInstance.current.get(
        `extorg/org/doctorSpeciality?status=0&limit=${limit}&offset=${offset}&keyword=${search}&org_id=${akouId}`
      );
      const resdata = response.data.total;

      setTimeout(() => {
        setOrgPendingList(orgPendingList.concat(resdata));
      }, 500);
    } catch (error) {
      console.log("An error occurred while fetching data.");
    }
  };
  const fetchMoreData = () => {
    if (key === "pending")
      addMoreData();
  };



  useEffect(() => {
    const getApprovalData = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/org/doctorSpeciality?status=1&limit=${limit}&offset=${0}&keyword=${search}&org_id=${akouId}`
        );
        if (response.status === 200) {
          setloader2(true)
          setOrgApprovalList(response.data.total);
          setTotalData(response.data.total_data)
        }
      } catch (error) {
        console.log("An error occurred while fetching data.");
      }
    };
    if (key === "approved")
      getApprovalData();
  }, [akouId, dataRefe, key]);








  useEffect(() => {
    if (lastTypingTime) {
      const timer = setTimeout(() => {
        const getApprovalData = async () => {
          try {
            const response = await axiosInstance.current.get(
              `extorg/org/doctorSpeciality?status=1&limit=${limit}&offset=${0}&keyword=${search}&org_id=${akouId}`
            );
            if (response.status === 200) {
              setloader2(true)
              setOrgApprovalList(response.data.total);
              setTotalData(response.data.total_data)
            }
          } catch (error) {
            console.log("An error occurred while fetching data.");
          }
        };
        if (key === "approved")
          getApprovalData();
      }, 1000);
      return () => clearTimeout(timer)
    }
  }, [search])










  const addMoreApprovalData = async () => {
    setOffsetApproval(offsetApproval + 25);
    try {
      const response = await axiosInstance.current.get(
        `extorg/org/doctorSpeciality?status=1&limit=${limit}&offset=${offsetApproval}&keyword=${search}&org_id=${akouId}`
      );
      const resdata = response.data.total;
      console.log("data", response.data.data);
      setTimeout(() => {
        setOrgApprovalList(orgApprovalList.concat(resdata));
      }, 500);
    } catch (error) {
      console.log("An error occurred while fetching data.");
    }
  };
  const fetchMoreApprovalData = () => {
    if (key === "approved")
      addMoreApprovalData();
  };












  useEffect(() => {
    const getRejectedData = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/org/doctorSpeciality?status=-1&limit=${limit}&offset=${0}&keyword=${search}&org_id=${akouId}`
        );
        if (response.status === 200) {
          setloader3(true)
          setOrgRejectedList(response.data.total);
          setTotalData(response.data.total_data)
        }
      } catch (error) {
        console.log("An error occurred while fetching data.");
      }
    };
    if (key === "rejected")
      getRejectedData();
  }, [akouId, dataRefe, key]);







  useEffect(() => {
    if (lastTypingTime) {
      const timer = setTimeout(() => {
        const getRejectedData = async () => {
          try {
            const response = await axiosInstance.current.get(
              `extorg/org/doctorSpeciality?status=-1&limit=${limit}&offset=${0}&keyword=${search}&org_id=${akouId}`
            );
            if (response.status === 200) {
              setloader3(true)
              setOrgRejectedList(response.data.total);
              setTotalData(response.data.total_data)
            }
          } catch (error) {
            console.log("An error occurred while fetching data.");
          }
        };
        if (key === "rejected")
          getRejectedData();

      }, 1000);
      return () => clearTimeout(timer)
    }
  }, [search])











  const addMoreRejectedData = async () => {
    setOffsetReject(offsetReject + 25);
    try {
      const response = await axiosInstance.current.post(
        `extorg/org/doctorSpeciality?status=-1&limit=${limit}&offset=${offsetReject}&keyword=${search}&org_id=${akouId}`
      );
      const resdata = response.data.total;
      console.log("data", response.data.data);
      setTimeout(() => {
        setOrgRejectedList(orgRejectedList.concat(resdata));
      }, 500);
    } catch (error) {
      console.log("An error occurred while fetching data.");
    }
  };
  const fetchMoreRejectedData = () => {
    if (key === "rejected")
      addMoreRejectedData();
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#D9D4E6" : "white",
      color: state.isSelected ? "#3f2783" : "#3f2783",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "rgba(63,39,131,.7)", // Set the color for the placeholder
    }),
  };


  const advsearch = (e) => {
    setSerach(e)
    setLastTypingTime(new Date().getTime())
  }

  useEffect(() => {
    if (search.length === 0 || akouId.length===0) {
      setOffset(25)
      setOffsetApproval(25);
      setOffsetReject(25);
    }


  }, [search,akouId])


  console.log("akouId", akouId)



  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="approve_org__list mb_100">
            <div className="panel">
              <div className="panel-heading  pannel_pd pt_20">
                <div className="row">
                  <div className="col-sm-12 col-md-12 d-flex">
                    <div className="">
                      <h4 className="mb-3 ml_25">{"Speciality List"}</h4>
                      <p className="mb_30 ml_25">
                        {totalData} {totalData > 1 ? "Results" : "Result"}
                      </p>
                    </div>

                    <div className="ms-auto">
                      <div className="Custom_re_se_drop">
                        <InputField
                          styleClass="search-org"
                          value={search}
                          type="text"
                          name="firstName"
                          placeholder="Search Keyword..."
                          onChange={advsearch}
                        />
                      </div>

                      <div className="Custom_re_se_drop ms-3">
                        {/* <label>Select Organisation</label> */}
                        <Select
                          className="basic-single_org"
                          classNamePrefix="select"
                          defaultValue={akouId}
                          isSearchable={isSearchable}
                          isClearable={isClearable}
                          onChange={handleOrgListData}
                          name="Org"
                          options={aLlOrgList}
                          styles={customStyles}
                          placeholder="Select an Organization"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel-body p_25">
                <>
                  <Tabs
                    id="approve-list-tabs"
                    activeKey={key}
                    onSelect={handleSelect}
                    // onSelect={(k) => setKey(k)}
                    className="mb-1"
                  >
                    <Tab eventKey="pending" title="Pending">

                      {!loader1 ? <div className="no_data">
                        loading...
                      </div> : (
                        <>
                          {
                            orgPendingList.length > 0 ? (
                              <PendingPendingTable
                                pendingOrgData={orgPendingList}
                                updatePagination={fetchMoreData}
                                orgId={akouId}
                                setOffset={setOffset}
                                totalData={totalData}

                              />
                            ) : (
                              <div className="no_data">No data found</div>
                            )}

                        </>
                      )
                      }


                    </Tab>
                    <Tab eventKey="approved" title="Approved">
                      {!loader2 ? <div className="no_data">
                        loading...
                      </div> : (
                        <>
                          {
                            orgApprovalList.length > 0 ? (
                              <PendingApprovalTable
                                pendingOrgData={orgApprovalList}
                                updatePagination={fetchMoreApprovalData}
                                orgId={akouId}
                                totalData={totalData}


                              />
                            ) : (
                              <div className="no_data">No data found</div>
                            )}

                        </>
                      )
                      }
                    </Tab>
                    <Tab eventKey="rejected" title="Rejected">

                      {!loader3 ? <div className="no_data">
                        loading...
                      </div> : (
                        <>
                          {
                            orgRejectedList.length > 0 ? (
                              <PendingRejectedTable
                                pendingOrgData={orgRejectedList}
                                updatePagination={fetchMoreRejectedData}
                                orgId={akouId}
                                totalData={totalData}


                              />
                            ) : (
                              <div className="no_data">No data found</div>
                            )}

                        </>
                      )
                      }

                      {/* {orgRejectedList.length > 0 ? (
                        <PendingRejectedTable
                          pendingOrgData={orgRejectedList}
                          updatePagination={fetchMoreRejectedData}
                          orgId={akouId}
                        />
                      ) : (
                        <div className="no_data">No data found</div>
                      )} */}
                    </Tab>
                  </Tabs>
                </>
                <></>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default PendingSpecialityList;
