import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { MultiSelect } from "react-multi-select-component";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../custom/button/Button";
import InputField from "../custom/inputfield/InputField";
import Dropdown from "../custom/dropdown/Dropdown";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import useAxios from "../../axiosinstance";
import { Validators } from "../../utilities/Validator";
import SortDropdown from "../custom/sortdropdown/SortDropdown";
import "./Groups.css";
import SharingListTable from "./SharingListTable";

function Sharings() {
  const navigate = useNavigate();
  const axiosInstance = useAxios();
  const groupTypeRef = useRef();
  const login = useSelector(
    (state) =>
      state.mainReducer.loginData.arr && state.mainReducer.loginData.arr[0]
  );

  const [isLoading, setIsLoading] = useState(true);
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
  const [loader1, setloader1] = useState(false);

  const [groupsData, setData] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [akouId, setAkouId] = useState("");
  const [startDate, setStartDate] = useState("");
  // const [startDate, setStartDate] = useState(new Date());

  const [offset, setOffset] = useState(25);
  const [limit, setLimit] = useState(25);
  const [sortType, setSortType] = useState("last_created");
  const [totaldata, setTotaldata] = useState("");

  const [orgapiData, setOrgapiData] = useState([]);
  const [groupTypeData, setGroupTypeData] = useState([]);


  console.log("groupsData", groupsData)

  const handleReset = (e) => {
    setFirstName("");
    setAkouId("");
  };
  const advsearchToFirstName = (event) => {
    setFirstName(event);
  };


  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);



    const getData = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/sharing/groupSharingList/${login.ak_id}?limit=${limit}&offset=0&akou_id=${akouId}&sort=${sortType}`
        );
        if (response.status === 200) {
          setloader1(true)
          setData(response.data.data);
          setTotaldata(response.data.totalCount);
        }
      } catch (error) {
        console.log("An error occurred while fetching data.");
      }
    };

    getData();
  }, [sortType, firstName, akouId, dataRefe]);




  const addMoreData = async () => {
    setOffset(offset + 25);
    const response = await axiosInstance.current.get(
      `extorg/group/groupSharingList/${login.ak_id}?limit=${limit}&offset=${offset}&akou_id=${akouId}&sort=${sortType}`
    );
    const resdata = response.data.data;
    setTimeout(() => {
      setData(groupsData.concat(resdata));
    }, 500);
  };

  useEffect(() => {
    const getAllOrgList = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/admin/orgs?ak_id=${login.ak_id}`
        );

        const newData = response.data.data.map((item) => ({
          value: item.id,
          label: item.o_nm,
          plan_id: item.plan_id,
        }));
        setOrgapiData(newData);
      } catch (err) {
        console.log("An error occurred while fetching data.");
      }
    };
    getAllOrgList();
  }, []);

  const fetchMoreData = () => {
    addMoreData();
  };
  const handleSort = (e) => {
    setSortType(e);
  };

  const handleOrgModle = (e) => {
    setAkouId(e);
  };

  useEffect(() => {
    if(akouId.length===0){
      setOffset(25)
    }
  }, [akouId])
  


  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="groups__list mb_100">
            <div className="panel mb_30">
              <div className="panel-heading  pannel_pd">
                <div className="row">
                  <div className="col-sm-6 col-md-6 pt_30">
                    <h4>Sharing List</h4>
                  </div>
                  <div className="col-sm-6 col-md-6 pt_30 text-right">
                    <Button
                      onClick={() => navigate("/create-sharing")}
                      value="+ Create Sharing"
                      buttonStyle="btn_fill w_180 mr_20"
                    />
                  </div>
                </div>
              </div>
              <div className="panel-body p_25">
                <>
                  <div className="adv_search_sec">
                    <h4 className="mb_30">{"Advanced Search"}</h4>
                    {/* <form onSubmit={handleSubmit}> */}

                    <div className="row">
                      {/* <div className="col-sm-6 col-md-3">
                        <InputField
                          label="Group Name"
                          value={firstName}
                          type="text"
                          name="firstName"
                          placeholder="Enter title"
                          onChange={advsearchToFirstName}
                        />
                      </div> */}
                      <div className="col-sm-6 col-md-3">
                        <Dropdown
                          data={orgapiData}
                          label="Organization"
                          value={akouId}
                          onChange={handleOrgModle}
                          placeholder="Select"
                          styleClass="mxw_285 mb_20"
                        />
                      </div>
                      <div className="col-sm-6 col-md-3 pt_30">
                        <Button
                          onClick={handleReset}
                          value="Reset"
                          buttonStyle="btn_fill mr_20"
                        />
                      </div>
                    </div>
                    {/* </form> */}
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6 col-md-6">
                      <h4 className="mb-3 ml_25">{"List of Sharings"}</h4>

                      <p className="mb_30 ml_25">
                        {totaldata===undefined ? 0 : totaldata} {totaldata > 1 ? "Results" : "Result"}
                      </p>
                    </div>
                    <div className="col-sm-6 col-md-6">
                      <SortDropdown onChange={handleSort} alp={false} />
                    </div>
                  </div>


                  {!loader1 ? <div className="no_data">
                    loading...
                  </div> : (
                    <>
                      {
                        groupsData.length > 0 ? (
                          <SharingListTable
                            groupsData={groupsData}
                            updatePagination={fetchMoreData}
                            totaldata={totaldata}
                          />
                        ) : (
                          <div className="no_data">No data found</div>
                        )}

                    </>
                  )
                  }
                </>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Sharings;
