import React, { useState, useEffect } from "react";
import { useTable } from "react-table";
import TableSpinner from "../custom/spinner/TableSpinner";
import TableActionItems from "../custom/table-action-items/TableActionItems";
import InfiniteScroll from "react-infinite-scroll-component";
import Switch from "../custom/switch/Switch";
import useAxios from "../../axiosinstance";
import { useSelector, useDispatch } from "react-redux";
import { noteRefs } from "../../redux/actions";
import Message from "../custom/toster/Message";

const TagsListTable = ({ tagsData, updatePagination, refs,totalData }) => {
  // const login = useSelector((state) => state.mainReducer.loginData.arr);
  //   const [doctorID, setDoctorID] = useState(login.ing_ak_id);
  const axiosInstance = useAxios();
  const dispatch = useDispatch();
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
  const [isChecked, setIsChecked] = useState(false);
  const [isDelete, setIsDelete] = useState(false)
  const [isUpdate, setUpdate] = useState(false)
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();

  console.log(refs, "nikhiol9")
  const handleSwitchChange = (status, id, sta) => {
    let s;
    if (status === 1) {
      s = 0
    } else {
      s = 1
    }

    let json = {
      enabled: s
    }
    axiosInstance.current.put(
      `extorg/admin/editTags?id=${id}`,
      json,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      setMessage("Status Updated")
      setMessageType("success");
      dispatch(noteRefs(new Date().getSeconds()))
      setTimeout(() => {
        setMessage(false)
      }, 2000);
    }).catch((err) => {
      dispatch(noteRefs(new Date().getSeconds()))
      setMessage("Status Not Update")
      setMessageType("error");
    });

  };


  // useEffect(() => {
  //   setRefresh1(isDelete)
  // }, [isDelete])
  const actionData = {
    view_url: "patient-view",
    edit_url: "edit-user-details",
    delete_url: "",
  };
  //   function handleEdit(row) {
  //     console.log(row);
  //   }
  const data = tagsData;


console.log("datadata",data)

  const columns = React.useMemo(
    () => [
      {
        Header: "S.no",
        accessor: "label",
      },
      {
        Header: "Title",
        accessor: "name",
      },
      {
        Header: "Status",
        accessor: "enabled",
        headerClassName: "col-status",
        className: "col-status",
        Cell: (row) => (
          //  console.log("row", row.row.original.sno),
          <span>
            <Switch
              name={`on-off-switch${row.row.original.id}`}
              tabIndex={1}
              checked={row.row.original.enabled}
              onChange={(e) => handleSwitchChange(row.row.original.enabled, row.row.original.id, refs)}
            />
          </span>
        ),
      },
      {
        Header: "Action",
        headerClassName: "col-action",
        accessor: "action",
        className: "col-action",
        Cell: (row) => (
          <span>
            <TableActionItems
              viewAction="false"
              editAction="false"
              editActionPopup="true"
              feedbackAction="false"
              deleteAction="true"
              arrowAction="false"
              rowName={"orgID"}
              rowId={row.row.original.ea_akpt_id}
              rowData={actionData}
              rowType="tags"
              notetitle={row.row.original.name}
              noteOrder={row.row.original.order}
              noteid={row.row.original.id}
              rowOrgId={row.row.original.ea_akpt_id}
              orgId={row.row.original.ea_org_id}
              setIsDelete={setIsDelete}
              setUpdate={setUpdate}
              deleteId={row.row.original.id}
              type="1"
            />
          </span>
        ),
      },
    ],
    []
  );




  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });
  return (
    <>
      {message && <Message message={message} type={messageType} />}

      {/* <Switch
        name="on-off-switch"
        tabIndex={1}
        checked={isChecked}
        onChange={handleSwitchChange}
      /> */}
      <div className="tableContainer" id="rpScrollableDiv">
        <InfiniteScroll
          dataLength={rows.length}
          next={updatePagination}
          hasMore={true}
          scrollableTarget="rpScrollableDiv"
          // loader={<TableSpinner />}
          loader={ tagsData.length >= 25 && tagsData.length !== totalData  ? <TableSpinner /> : null}

        >
          <table {...getTableProps()} className="custom-tbl">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps([
                        { className: column.headerClassName },
                      ])}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps([
                            { className: cell.column.className },
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </>
  );
}

export default TagsListTable;
