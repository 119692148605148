import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { MultiSelect } from "react-multi-select-component";
import Dropdown from "../custom//dropdown/Dropdown";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Button from "../custom/button/Button";
import InputField from "../custom/inputfield/InputField";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import useAxios from "../../axiosinstance";
import { Validators, spacialCharReplace } from "../../utilities/Validator";
import { noteRefs } from "../../redux/actions";
import { apiResponseMsgData, apiResponseMsgTypeData } from "../../redux/actions";

const EditResourceType = ({
  showModal,
  hideModal,
  confirmModal,
  typeId,
  type,
  message,
}) => {
  const navigate = useNavigate();
  const axiosInstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const [resourceName, setResourceName] = useState("");
  const [orgSelect, setOrgSelect] = useState([]);
  const [optionsRequried, setOptionsRequried] = useState([
    { value: 1, label: "option1" },
    { value: 2, label: "option2" },
  ]);
  const [akouId, setAkouId] = useState("");
  const [resourceTypeName, setresourceTypeName] = useState("");
  const [keyName, setKeyName] = useState("");
  const [ShowAddResource, setShowAddResource] = useState(false);
  const [organization, setOrganization] = useState("0");
  const [documentType, setDocumentType] = useState("");
  const [allOrgList, setAllOrgList] = useState([]);
  const[disble,setDisable]=useState(false)

  const login = useSelector(
    (state) =>
      state.mainReducer.loginData.arr && state.mainReducer.loginData.arr[0]
  );
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);

  const orgRef = useRef();
  const orgNameRef = useRef();
  const nameRef = useRef();
  const keyNameRef = useRef();
  const documentTypeRef = useRef();
  const dispatch = useDispatch()

  const handleCloseEdit = (e) => {
    hideModal(false);
  };

  const resourceNameChange = (e) => {
    setResourceName(e);
  };
  const keyNameChange = (e) => {
    setKeyName(e);
  };
  const handleEditRequest = () => {
    // hideModal(false);
    handleSave();
  };

  const handleOrgChange = (e) => {
    setOrganization(e);
  };
  const handleDocumentType = (e) => {
    setDocumentType(e);
  };
  const handleSelectOrgChange = (e) => {
    setOrgSelect(e);
  };
  const handleOrgListData = (e) => {
    setAkouId(e);
  };
  const resourceTypeNameChange = (e) => {
    setresourceTypeName(e);
  };

  const json = JSON.stringify({
    akou_id: akouId,
    name: resourceTypeName,
    alias: keyName,
    // document_type: documentType,
    // order: city,
    // medical_form: stateValue,
  });
  const handleSave = async () => {

    if (!resourceTypeName) {
      nameRef.current.errUpdate();
    }
    if (!keyName) {
      keyNameRef.current.errUpdate();
    }
    // if (!documentType) {
    //   documentTypeRef.current.errUpdate();
    // }

    if ( !resourceTypeName || !keyName ) {
    } else {
      setDisable(true)
      try {
        const result = await axiosInstance.current.patch(
          `extorg/org/update/resource_type/${typeId}?ak_id=${login.ak_id}`,
          json,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (result.status === 200) {
          hideModal(false);
          dispatch(apiResponseMsgData("Resource type added successfully"));
          dispatch(apiResponseMsgTypeData("success"));
          setDisable(false)
          dispatch(noteRefs(!dataRefe))
          setTimeout(() => {
            dispatch(apiResponseMsgData(false));
          }, 2000);
        }
      } catch (err) {
        hideModal(false);
        dispatch(apiResponseMsgData(err.message));
        dispatch(apiResponseMsgTypeData("error"));
      } finally {
        console.log("api response finally");
      }
    }
  };
  useEffect(() => {
    const getSingleResData = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/org/get_particular_resource_type/${typeId}?ak_id=${login.ak_id}`
        );
        console.log("Api fetched successfully");
        setAkouId(response.data.data.akou_id);
        setresourceTypeName(response.data.data.name);
        setKeyName(response.data.data.alias);

      } catch (err) {
        console.log("An error occurred while fetching data.");
      }
    };
    if (showModal === true) {
      getSingleResData();
    }
  }, [showModal]);

  return (
    <Modal
      show={showModal}
      onHide={hideModal}
      className={``}
      centered
      backdrop="static"
      keyboard={false}
      dialogClassName="modal-md patient_notes_popup resource__type_popup"
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-center">Edit Resource Type</Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
      <InputField
                label="Name*"
                value={resourceTypeName}
                type="text"
                ref={nameRef}
                placeholder="Enter name here"
                styleClass="br_10 mxw_100 mb_20"
                onChange={resourceTypeNameChange}
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                ]}
              />
              <InputField
                label="Key Name*"
                value={keyName}
                type="text"
                ref={keyNameRef}
                placeholder="Enter key name here"
                styleClass="br_10 mxw_100 mb_20"
                onChange={keyNameChange}
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                ]}
              />
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "flex-start" }}>
        <Button
          onClick={handleCloseEdit}
          value="Cancel"
          buttonStyle="btn_nill mr_20"
        />
        <Button
          onClick={handleEditRequest}
          value="Update"
          enable={disble}
          width="120px"
          buttonStyle="btn_outline mr_20"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default EditResourceType;
