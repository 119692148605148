import React, { useState, useEffect, useRef, useMemo } from "react";
// import {  useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Multiselect } from "multiselect-react-dropdown";
import "react-datepicker/dist/react-datepicker.css";
import InputField from "../custom/inputfield/InputField";
import Dropdown from "../custom/dropdown/Dropdown";
import { Validators, spacialCharReplace } from "../../utilities/Validator";
import Button from "../custom/button/Button";
import useAxios from "../../axiosinstance";
import Message from "../custom/toster/Message";
import ChunkUpload from "../custom/chunk-upload/ChunkUpload";
import Switch from "../custom/switch/Switch";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { noteRefs } from "../../redux/actions";
import useAxiosUpload from "../../axiosinstanceUpload";
import DropdownOrg from "../custom/dropdown/DropdownOrg";


function CreateEvent({ toNextTab, toData }) {

  const location = useLocation();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { id } = useParams();
  const receivedData = location.state;
  const videoData = receivedData ? receivedData.data : []
  // const editDataStatus = receivedData.edit
  const profile = useSelector((state) => state.mainReducer.loginData.arr);
  const orgchannels = useSelector((state) => state.mainReducer.channels.org_channel)


  console.log("receivedData", receivedData)

  const [arr, setArr] = useState([])
  const [thumbnail1, setThumbnail1] = useState(null);
  const [thumbnail2, setThumbnail2] = useState(null);
  const [largeloader, setLargeloader] = useState(false);

  const [large, setLarge] = useState(videoData.large_thumb || "");
  const [medium, setMedium] = useState(videoData.medium_thumb || "");
  const [small, setSmall] = useState(false);
  const [video, setVideo] = useState(null);


  let catids = []
  let channelIds = []
  const akTokenOrg = localStorage.getItem("AKTOKENORG")
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);

  const [isLoading, setIsLoading] = useState(true);
  const [publish_date, setPublishDate] = useState(new Date(videoData.publish_date || new Date()));
  const axiosInstance = useAxios();
  const axiosInstanceUpload = useAxiosUpload();

  const [videoTitle, setVideoTitle] = useState(videoData.title);
  const [vidId, setVidId] = useState(videoData.videoId)

  // const [video_key, setVideoKey] = useState(videoData.video_key || "");
  const [video_key, setTimes] = useState(videoData.video_key || "");

  const [type_id, setTypeId] = useState(videoData.typeId || "2");
  const [duration, setDuration] = useState(videoData.duration)
  const [link, setLink] = useState(videoData.link)
  const [video_size, setVideoSize] = useState(videoData.video_size)
  const [channelsData, setChannelsData] = useState([]);
  const [enabled, setEnabled] = useState(videoData.enabled)
  const [disabled, setDisabled] = useState(false)


  const [lastName, setLastName] = useState("");
  const [formVaild, setFormVaild] = useState(false);
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [load, setVideoload] = useState(false);
  const [categortyData, setCategoryData] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [description, setDescription] = useState(videoData.description);
  const[disble,setDisable]=useState(false)

  const [allOrgList, setAllOrgList] = useState([]);
  const [allSelectOrg, setAllSelectOrg] = useState(videoData.org_id || "");
  const [eventLink, setEventLink] = useState(videoData.link);
  const [startDate, setStartDate] = useState("");
  const [dobError, setDobError] = useState(false);
  const maxDate = new Date();
  const lastNameRef = useRef();
  const descriptionRef = useRef();
  const allOrgRef = useRef();
  const videoTitleRef = useRef()

  // const navigate = useNavigate();-

  let sessionId = localStorage.getItem("sessionId")


  const [categoryId, setCategory] = useState(videoData.tags || []);
  const [channelsId, setChannelsId] = useState(videoData.channels || []);



  useEffect(() => {
    if (id==="2"&&videoData.length===0) {
      navigate("/post-list")
    }
  }, [id,videoData])



  const getTagsList = async () => {
    try {
      const response = await axiosInstance.current.get(
        `extorg/admin/getAllTags`
      );
      setArr(response.data.data)
    } catch (error) {
      console.log("error", error)
    }
  }


  const channelList = async () => {
    const response = await axiosInstance.current.get(
      `extorg/admin/getChannelList?ak_id=${akTokenOrg}&keyword=${""}&orgKey=${""}&meta=${2}` //OrgAdd
    );
    // dispatch(channeldata(response.data.data))
    setCategoryData(response.data.data)
  }


  categoryId.map((ele) => {
    catids.push(ele.id)
  })

  channelsId.map((ele) => {
    channelIds.push(ele.id)
  })


  useEffect(() => {
    getTagsList()
    if(profile[0].type===6){
      channelList()
    }
  }, [])


  const handleVideoTitle = (e, err) => {
    // setFirstNameErr(err);
    setVideoTitle(spacialCharReplace(e));
    // setFormVaild(e.error ? e.error : false);
  };

  const handleDescription = (e) => {
    setDescription(e);
  };

  // const handleContinue = (e) => {
  //   setMessage(false);
  //   e.preventDefault();
  //   if (!lastName) {
  //     lastNameRef.current.errUpdate();
  //   }

  //   if (!videoTitle || !description) {
  //     // alert("form values not filled 1");
  //     setFormVaild(true);
  //   } else if (formVaild) {
  //     // alert("form values not filled 2");
  //   } else {
  //     // alert("form values not filled 3");
  //     const json = JSON.stringify({
  //       videoTitle: videoTitle,
  //       lastname: lastName,
  //     });
  //     axiosInstance.current
  //       .post(`extapp/doctors/new_registration`, json, {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       })
  //       .then((res) => {
  //         console.log(res.data.ak_id);
  //         toData(res.data.ak_id);
  //       })
  //       .catch((err) => {
  //         setMessage(err.response.data.message);
  //         setMessageType("error");
  //       });
  //   }
  // };
 
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  const [data, setData] = useState({
    filelength: "",
  });
  const sendData = (data) => {
    setData(data);
  };


  const selectOption = (event) => {
    const getID = [];
    for (let index = 0; index < event.length; index++) {
      getID.push(event[index]);
    }
    setCategory(getID);
    console.log("getID", getID)
  };

  const selectOption1 = (event) => {
    const getChannelId = [];
    for (let index = 0; index < event.length; index++) {
      getChannelId.push(event[index]);
    }
    setChannelsId(getChannelId);
    console.log("getChannelId", getChannelId)
  };



  const handleSwitchChange = (status) => {
    if (status === 1) {
      setEnabled(0)
    } else {
      setEnabled(1)
    }
  };


  const handleContinue = async () => {

    if (!videoTitle) {
      videoTitleRef.current.errUpdate()
    }
    if (!description) {
      descriptionRef.current.errUpdate()
    }


    if (
      !videoTitle ||
      !description
    ) {
    } else {
      setDisable(true)

      let json = {
        typeId: type_id,
        title: videoTitle,
        tags: catids,
        enabled: enabled,
        link: eventLink,
        large,
        medium,
        publish_date,
        created_on: new Date().toISOString(),
        description: description,
        file_size: "",
        org_id: allSelectOrg || "",
        channels: channelIds,
        video_key: video_key,
        created_at: new Date(),
        updated_at: new Date(),
      }

      if (id === "1") {
       
        const response = await axiosInstance.current.post(
          `extorg/admin/uploadVideos?video_key=${video_key}&large=${large}&medium=${medium}&ak_id=${profile[0].ak_id}&org_id=${allSelectOrg || ""}`, json, {
          headers: {
            "Content-Type": "application/json",
          },
        }
        ).then((res) => {
          setMessage("Live Event Create Successfully")
          setMessageType("success");
          dispatch(noteRefs(!dataRefe))
          navigate("/post-list")
          setTimeout(() => {
            setMessage(false)
          }, 3000);
        }).catch((err) => {
          setMessage("Live Event Not Create")
          setMessageType("error");
        })

      }

      if (id === "2") {
        console.log("for update",id)
        const response = await axiosInstance.current.put(
          `extorg/admin/updateContent?id=${vidId}&video_key=${video_key}&large=${large}&medium=${medium}&org_id=${allSelectOrg || ""}`, json, {
          headers: {
            "Content-Type": "application/json",
          },
        }
        ).then((res) => {
          setMessage("Live Event Update Successfully")
          setMessageType("success");
          dispatch(noteRefs(!dataRefe))
          navigate("/post-list")
          setTimeout(() => {
            setMessage(false)
          }, 3000);
        }).catch((err) => {
          setMessage("Live Event Not Update")
          setMessageType("error");
        })

      }



    }

  }


  // const handleContinue = async () => {

  //   if (!videoTitle) {
  //     videoTitleRef.current.errUpdate()
  //   }
  //   if (!description) {
  //     descriptionRef.current.errUpdate()
  //   }


  //   if (
  //     !videoTitle ||
  //     !description
  //   ) {
  //   } else {

  //     let json = {
  //       typeId:type_id,
  //       title: videoTitle,
  //       categoryId: catids,
  //       enabled: enabled,
  //       link: eventLink,
  //       large,
  //       medium,
  //       publish_date,
  //       created_on: new Date().toISOString(),
  //       description: description,
  //       file_size: "",
  //       // org_id: hcpDrop,
  //       channels: channelIds,
  //       video_key: video_key,
  //       created_at : new Date(),
  //       updated_at : new Date(),
  //       // sessionId: sessionId,

  //     }

  //     // console.log("jsonnssss", json)
  //     const response = await axiosInstance.current.post(
  //       `extorg/admin/uploadVideos?video_key=${video_key}&large=${large}&medium=${medium}&ak_id=${profile[0].ak_id}`, json, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     }
  //     ).then((res) => {
  //       setMessage("Live Event Create Successfully")
  //       setMessageType("success");
  //       dispatch(noteRefs(!dataRefe))
  //       navigate("/post-list")
  //       setTimeout(() => {
  //         setMessage(false)
  //       }, 3000);
  //     }).catch((err) => {
  //       setMessage("Live Event Not Create")
  //       setMessageType("error");
  //     })


  //   }

  // }






  const handleAllOrg = (e) => {
    setAllSelectOrg(e);
  };
  const handleEventLink = (e, err) => {
    setEventLink(e);
  };
  const handleTillDate = (e) => {
    setStartDate(e);
    setDobError(false);
  };





  useEffect(() => {
    if (thumbnail1) {
      const ext = thumbnail1.name.split(".").pop();
      let fileName = `720x405.${ext}`;
      setLargeloader(true);
      // console.log(fileName);
      setLarge(fileName);

      const formData = new FormData();
      formData.append("file", thumbnail1);
      axiosInstanceUpload.current
        .post(
          `uploadthumbnail?video_key=${video_key}&field_name=${fileName}`,
          formData
        )
        .then((response) => {
          setLargeloader(false);
          // console.log(response);
        });
    }
  }, [thumbnail1]);



  useEffect(() => {
    if (thumbnail2) {
      const ext = thumbnail2.name.split(".").pop();
      let fileName = `480x270.${ext}`;
      setSmall(true);
      setMedium(fileName);

      const formData = new FormData();
      formData.append("file", thumbnail1);
      axiosInstanceUpload.current
        .post(
          `uploadthumbnail?video_key=${video_key}&field_name=${fileName}`,
          formData
        )
        .then((response) => {
          setSmall(false);

          // console.log(response);
        });
    }
  }, [thumbnail2]);






  var date = new Date();

  useMemo(() => {
    var components = [
      date.getYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds(),
    ];
    var id = components.join("");
    setTimes(id);
  }, []);


  const channelListBySpeficOrg = async () => {
    const response = await axiosInstance.current.get(
      `extorg/admin/getChannelList?ak_id=${allSelectOrg}&keyword=${""}&orgKey=${""}&meta=${2}` //OrgAdd
    );
    setCategoryData(response.data.data)
  }

  useEffect(() => {
    if(allSelectOrg.length>0){
      channelListBySpeficOrg()
    }else{
      setCategoryData([])
    }
  }, [allSelectOrg])



  useEffect(() => {
    if (orgchannels) {
      setAllOrgList(orgchannels)
    }
  }, [orgchannels])



  useEffect(() => {
    if(id==="2"){
      setDisabled(true)
    }else{
      setDisabled(false)
    }
  }, [profile])
  
  console.log("categortyData",categortyData)


  return (
    <>
      <div className="create_video_screen mb_200">
        {message && <Message message={message} type={messageType} />}

        <div className="row page_header">
          <div className="col-sm-6 col-md-6">
            <h4 className="ph_title">{id==="2" ? "Edit Event" :"Create Event"}</h4>
          </div>
          <div className="col-sm-6 col-md-6 text-end"></div>
        </div>

        <div className="panel">
          {message && <Message message={message} type={messageType} />}
          {/* <form> */}
          <div className="panel-body p_25 pt_50 pb_50">
            <div className="dc_reg_inner">
              <div className="">
                {
                  profile[0].type === 2 ? (
                    <div className="row">
                    <div className="col-sm-10 col-md-10">
                      <DropdownOrg
                        data={allOrgList}
                        label="Organization"
                        value={allSelectOrg}
                        onChange={handleAllOrg}
                        placeholder="Select"
                        styleClass="mxwp_90_select mb_20 br_10"
                        ref={allOrgRef}
                        disabled={disabled}
                        //  
                      />
                    </div>
                  </div>
                  ):""
                }
               
                <div className="row">
                  <div className="col-sm-10 col-md-10 mb_20">
                    <InputField
                      label="Title"
                      value={videoTitle}
                      ref={videoTitleRef}
                      name="videoTitle"
                      type="text"
                      placeholder="Enter event title here"
                      onChange={handleVideoTitle}
                      validators={[
                        {
                          check: Validators.required,
                          message: "This field is required",
                        },
                      ]}
                      styleClass="br_10 mxwp_90"
                    />
                  </div>
                  <div className="col-sm-10 col-md-10 mb_20">
                    <InputField
                      label="Description"
                      value={description}
                      type="textarea"
                      placeholder="Enter description here"
                      rows="2"
                      cols="20"
                      onChange={handleDescription}
                      ref={descriptionRef}
                      styleClass="br_10 mxwp_90"
                      validators={[
                        {
                          check: Validators.required,
                          message: "This field is required",
                        },
                      ]}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-5 col-md-5 mb_20">
                    <label>Thumbnail image (image-size 720X405)</label>
                    <input
                      className="form-control f_14 l_height_38 br_10 mxwp_80"
                      type="file"
                      id="thumbnail1"
                      onChange={(event) =>
                        setThumbnail1(event.target.files[0])
                      }
                      name="thumbnail1"
                      required
                      accept=".jpg,.jpeg,.png"
                    />
                    {largeloader && (
                      <div class="clearfix text-center">
                        <div
                          class="spinner-border"
                          style={{ color: "#3f2783" }}
                          role="status"
                        >
                          <span class="visually-hidden">
                            Loading...
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-sm-5 col-md-5 mb_20">
                    <label>Thumbnail image (image-size 480X270)</label>
                    <input
                      className="form-control f_14 l_height_38 br_10 mxwp_80"
                      type="file"
                      id="thumbnail2"
                      onChange={(e) => setThumbnail2(e.target.files[0])}
                      name="thumbnail2"
                      required
                      accept=".jpg,.jpeg,.png"
                    />
                    {small && (
                      <div class="clearfix text-center">
                        <div
                          class="spinner-border"
                          role="status"
                          style={{ color: "#3f2783" }}
                        >
                          <span class="visually-hidden">
                            Loading...
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-5 col-md-5 mb_20">
                    <InputField
                      label="Event Link"
                      value={eventLink}
                      name="eventLink"
                      type="text"
                      placeholder="Add event link here"
                      onChange={handleEventLink}
                      validators={[
                        {
                          check: Validators.required,
                          message: "This field is required",
                        },
                      ]}
                      styleClass="br_10 mxwp_80"
                    />
                  </div>
                  <div className="col-sm-5 col-md-5 mb_20">
                    <label>Visible till</label>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      minDate={maxDate}
                      //   placeholderText="Please select a date"
                      className="form-control mb_0 br_10 mxwp_80"
                      placeholderText="DD/MM/YYYY"
                      selected={publish_date}
                      // onChange={(date) => handleTillDate(date)}
                      onChange={(date) => setPublishDate(date)}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-5 col-md-5 mb_20">
                    <label>Tags</label>
                    <Multiselect
                      selectedValues={categoryId}
                      options={arr}
                      onSelect={(event) => selectOption(event)}
                      onRemove={(event) => selectOption(event)}
                      displayValue="name"
                      className="multi_select_main"
                    //   showCheckbox
                    />
                  </div>
                  <div className="col-sm-5 col-md-5 mb_20">
                    <label>Assign Channels</label>
                    <Multiselect
                      selectedValues={channelsId}
                      options={categortyData}
                      onSelect={(event) => selectOption1(event)}
                      onRemove={(event) => selectOption1(event)}
                      displayValue="name"
                      className="multi_select_main"
                    // showCheckbox
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-5 col-md-5 mb_20 d_flex pt_30">
                    <label style={{ width: "25%" }}>Publish</label>
                    <Switch
                      name={`on-off-switch`}
                      tabIndex={1}
                      checked={enabled}
                      onChange={(e) => handleSwitchChange(enabled)}
                    // checked={row.row.original.status}

                    />
                  </div>
                </div>
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6"></div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <Button
                      onClick={handleContinue}
                      value="Submit"
                      enable={disble}
                      buttonStyle="btn_fill ms-4 clk_btn_disbled"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </form> */}
        </div>
      </div>
    </>
  );
}

export default CreateEvent;
