import React, { useState, useEffect, useRef } from "react";
// import {  useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputField from "../../custom/inputfield/InputField";
import { Validators, spacialCharReplace } from "../../../utilities/Validator";
import Button from "../../custom/button/Button";
import useAxios from "../../../axiosinstance";
import Dropdown from "../../custom/dropdown/Dropdown";
import Message from "../../custom/toster/Message";
import "./CreatePatient.css";

function CreatePatient({ toNextTab, toData }) {
  const [isLoading, setIsLoading] = useState(true);
  const axiosInstance = useAxios();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [formVaild, setFormVaild] = useState(false);
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [sex, setSex] = useState("");
  const [Organization, setOrganization] = useState("");
  const [startDate, setStartDate] = useState("");
  const [dobError, setDobError] = useState(false);
  const [email, setEmail] = useState("");
  const [SelectedCsv, setSelectedCsv] = useState();
  const [submitData, setSubmitData] = useState([]);
  const [notSubmitData, setNotSubmitData] = useState([]);
  const firstNameRef = useRef();
  const lastNameRef = useRef();

  const sexRef = useRef();
  const emailRef = useRef();
  const maxDate = new Date();
  const handleMail = (e) => {
    setEmail(e);
  };

  const handleFirstName = (e, err) => {
    setFirstNameErr(err);
    setFirstName(spacialCharReplace(e));
    setFormVaild(e.error ? e.error : false);
  };
  const handleLastName = (e, err) => {
    setLastNameErr(err);
    setLastName(spacialCharReplace(e));
    setFormVaild(e.error ? e.error : false);
  };
  const handleSex = (e) => {
    setSex(e);
  };
  const handleOrg = (e) => {
    setOrganization(e);
  };
  const handleDob = (e) => {
    setStartDate(e);
    setDobError(false);
  };

  const handleContinue = (e) => {
    setMessage(false);
    e.preventDefault();
    if (!firstName) {
      firstNameRef.current.errUpdate();
    }
    if (!lastName) {
      lastNameRef.current.errUpdate();
    }
    if (!email) {
      emailRef.current.errUpdate();
    }

    if (
      !Organization ||
      !firstName ||
      !lastName ||
      !sex ||
      firstNameErr ||
      lastNameErr
    ) {
      setFormVaild(true);
    } else if (formVaild) {
      // alert("form values not filled 2");
    } else {
      const json = JSON.stringify({
        Organization: Organization,
        firstname: firstName,
        lastname: lastName,
        sex: sex,
        dob: "~0",
      });
      axiosInstance.current
        .post(`extapp/doctors/new_registration`, json, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data.ak_id);
          toData(res.data.ak_id);
          toNextTab("organisation");
          // setMessage(res.data.message);
          // setMessageType("success");
        })
        .catch((err) => {
          setMessage(err.response.data.message);
          setMessageType("error");
        });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const handleClose = () => {
      // setSubmitData([]);
    // setSelectedCsv({})
  };
  const handleClose1 = () => {
    // alert(1)
    // setNotSubmitData([]);
  };
  return (
    <>
      <div className="create_patient_screen mb_200">
        <div className="row page_header">
          <div className="col-sm-6 col-md-6">
            <h4 className="ph_title">Add Patient</h4>
          </div>
          <div className="col-sm-6 col-md-6 text-end"></div>
        </div>

        <div className="panel">
          {message && <Message message={message} type={messageType} />}
          {/* <form> */}
          <div className="panel-body p_25 pt_50 pb_50">
            <div className="dc_reg_inner">
              <div className="">
                <div className="row">
                  <div className="col-sm-5 col-md-5 mb_20">
                    <InputField
                      label="First Name*"
                      value={firstName}
                      name="firstName"
                      type="text"
                      placeholder="First Name*"
                      onChange={handleFirstName}
                      ref={firstNameRef}
                      validators={[
                        {
                          check: Validators.required,
                          message: "This field is required",
                        },
                        {
                          check: Validators.spacialName,
                          message: "No special characters are allowed",
                        },
                      ]}
                      styleClass="br_10 mxwp_80"
                    />
                  </div>
                  <div className="col-sm-5 col-md-5 mb_20">
                    <InputField
                      label="Last Name*"
                      value={lastName}
                      name="lastName"
                      type="text"
                      placeholder="Last Name*"
                      onChange={handleLastName}
                      ref={lastNameRef}
                      validators={[
                        {
                          check: Validators.required,
                          message: "This field is required",
                        },
                        {
                          check: Validators.spacialName,
                          message: "No special characters are allowed",
                        },
                      ]}
                      styleClass="br_10 mxwp_80"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-5 col-md-5 mb_20">
                    <Dropdown
                      data={[
                        { value: 1, label: "Male" },
                        { value: 2, label: "Female" },
                        { value: 3, label: "Do not want to disclose" },
                      ]}
                      label={"Sex"}
                      styleClass="mxwp_80_select br_10"
                      value={sex}
                      ref={sexRef}
                      placeholder="Select"
                      onChange={handleSex}
                      validators={[
                        {
                          check: Validators.required,
                          message: "This field is required",
                        },
                      ]}
                    />
                  </div>
                  <div className="col-sm-5 col-md-5 mb_20">
                    <Form.Group className="mb-3" controlId="fromDate">
                      <Form.Label>
                        {"DOB"}{" "}
                        <i style={{ "font-style": "italic" }}>(DD/MM/YYYY)</i>
                      </Form.Label>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        maxDate={maxDate}
                        // placeholderText="Please select a date"
                        className="form-control mb_0 br_10 mxwp_80"
                        placeholderText="DD/MM/YYYY"
                        selected={startDate}
                        onChange={(date) => handleDob(date)}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-5 col-md-5 mb_20">
                    <InputField
                      label={"Email"}
                      value={email}
                      ref={emailRef}
                      type="text"
                      placeholder="Email"
                      onChange={handleMail}
                      validators={[
                        {
                          check: Validators.required,
                          message: "This field is required",
                        },
                      ]}
                      styleClass="br_10 mxwp_80"
                    />
                  </div>
                  <div className="col-sm-5 col-md-5 mb_20">
                    <Dropdown
                      data={[{ value: 1, label: "Test Org1" }]}
                      label="Organization"
                      styleClass="mxwp_80_select br_10"
                      value={Organization}
                      placeholder="Select"
                      onChange={handleOrg}
                      validators={[
                        {
                          check: Validators.required,
                          message: "This field is required",
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                      className="mxwp_90"
                    >
                      <hr style={{ flex: 1, borderColor: "#3f2783" }} />
                      <span
                        style={{
                          padding: "0 10px",
                          fontWeight: "bold",
                          color: "#3f2783",
                        }}
                      >
                        OR
                      </span>
                      <hr style={{ flex: 1, borderColor: "#3f2783" }} />
                    </div>
                    <div className="error">
                      {submitData.length !== 0 ? (
                        <>
                          <label> Patient Added</label>
                          <div className="alert alert-success alert-dismissible">
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="alert"
                              onClick={() => handleClose()}
                            />
                            <div className="patient_box scrollbar_vert">
                              {submitData.map((ele) => (
                                <p>
                                  <strong>{ele.data.email}</strong>{" "}
                                  {ele.message}
                                </p>
                              ))}
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {notSubmitData.length !== 0 ? (
                        <>
                          <label> Patient Not Added</label>
                          <div className="alert alert-danger alert-dismissible ">
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="alert"
                              onClick={() => handleClose1()}
                            />
                            <div className="patient_box scrollbar_vert">
                              {notSubmitData.map((ele) => (
                                <p>
                                  <strong>{ele.data.email}</strong>{" "}
                                  {ele.message}
                                </p>
                              ))}
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>

                    <span
                      class="ms-auto btn btn_org mxwp_90"
                      style={{
                        textAlign: "center",
                        width: "100%",
                        borderRadius: "10px",
                      }}
                    >
                      <p>Import .csv file</p>
                      <label className="browser_file" for="myfileCsv">
                        {" "}
                        Browser File
                      </label>
                      <input
                        type="file"
                        id="myfileCsv"
                        name="myfileCsv"
                        hidden
                        accept=".csv"
                        style={{ display: "none" }}
                        onChange={(event) => {
                          setSelectedCsv(event.target.files[0]);
                        }}
                        // style={{ width: "100%" }}
                      />
                    </span>
                  </div>
                </div>

                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6"></div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <Button
                      onClick={handleContinue}
                      value="Continue"
                      buttonStyle="next_btn btn_fill"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </form> */}
        </div>
      </div>
    </>
  );
}

export default CreatePatient;
