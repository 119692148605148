import React, { useState, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";

import { validateDropdown } from "../../../utilities/Validator";

const Dropdownno = forwardRef(({
  value,
  label,
  data,
  placeholder,
  styleClass,
  validators,
  onChange,
  disabled
}, ref) => {
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    const { value } = event.target;
    setError(validateDropdown(validators, value));
    onChange(value);
  };

  useImperativeHandle(ref, () => ({
    errUpdate(e) {
      setError(validateDropdown(validators, ""));
    }
  }))

  return (
    <div className={`form-group ${styleClass}`}>
      {label && <label htmlFor="app-input-field">{label}</label>}
      <select
        value={value}
        className="form-control "
        onChange={handleChange}
        onBlur={handleChange}
        disabled={disabled}
      >

        {data.map((item, key) => (
          <option key={key} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      {error && <span className="text-danger">{error.message}</span>}
    </div>
  );
});

Dropdownno.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.array.isRequired,
  styleClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

Dropdownno.defaultProps = {
  value: "",
  label: "",
  styleClass: "",
  placeholder: ""
};

export default Dropdownno;
