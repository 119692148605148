import {
  SHOW_LOADER,
  HIDE_LOADER,
  FETCH_SUBID_SUCCESS,
  FETCH_SUBID_FAIL,
  FETCH_MAIL_SUCCESS,
  FETCH_MAIL_FAIL,
  API_MESSAGE,
  API_MESSAGETYPE,
  GET_CHANNEL_LIST,
  GET_TAGS_LIST
} from "./actionTypes";

export const showLoader = () => {
  return {
    type: SHOW_LOADER,
  };
};
export const hideLoader = () => {
  return {
    type: HIDE_LOADER,
  };
};

export const fetchUserIdSuccess = (products) => {
  return {
    type: FETCH_SUBID_SUCCESS,
    data: products,
  };
};

export const fetchProductsFail = (error) => {
  return {
    type: FETCH_SUBID_FAIL,
    data: error,
  };
};

export const fetchMailSuccess = (user) => {
  return {
    type: FETCH_MAIL_SUCCESS,
    data: user,
  };
};

export const fetchMailFail = (error) => {
  return {
    type: FETCH_MAIL_FAIL,
    data: error,
  };
};

export const loginData = (e) =>{
  return {
    type: 'SET_LOGIN_DATA',
    data: e,
  };
}
export const timeZone = (e) =>{
  return {
    type: 'SET_TIME_ZONE',
    data: e,
  };
}
export const noteRefs = (e) =>{
  return {
    type: 'SET_REFRESH',
    data: e,
  };
}
export const chatRefresh = (e) =>{
  return {
    type: 'SET_CHATREFRESH',
    data: e,
  };
}

export const channeldata =(e,key)=>{
  return {
    type: 'GET_CHANNEL_LIST',
    data: e
  };
}

export const setChannelLists = datas =>{
  return {
      type: 'SET_CHANNEL_ORG_LIST',
      payload: datas
  }
}


export const channelKey =(e)=>{
  return {
    type: 'GET_CHANNEL_KEYWORD',
    key: e
  };
}
export const apiResponseMsgData =(e)=>{
  return {
    type: API_MESSAGE,
    data: e
  };
}
export const apiResponseMsgTypeData =(e)=>{
  return {
    type: API_MESSAGETYPE,
    data: e
  };
}


export const tagData =(e,key)=>{
  return {
    type: 'GET_TAGS_LIST',
    data: e
  };
}

export const accessToken = (e) =>{
  return {
    type: 'SET_TOKEN',
    data: e,
  };
}

export const platFormData =(e,key)=>{
  return {
    type: 'GET_PLATFORM_LIST',
    data: e
  };
}
