import React, { useState, useEffect } from "react";
// import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

import AppRouter from "./AppRouter";

import Welcome from "./Welcome";


function App() {
  const { keycloak, initialized } = useKeycloak();
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);

  }, []);
  
  if (!initialized) {
    return <h2 style={{textAlign: "center", marginTop: "100px"}}>Loading...</h2>;
  }

  return (
    <>

      {!keycloak.authenticated && <Welcome />}

      {!!keycloak.authenticated && <AppRouter />}
    </>
  );
}

export default App;
