import React from "react";
import {  useNavigate } from "react-router-dom";
import leftlogo from "../../../images/akunah-hr-logo.svg";
import './NotFound.css'

const NotFoundPage = () => {
  const Navigate = useNavigate();
  let akToken = localStorage.getItem("AKTOKENORG");
  const handelChange = () => {
    Navigate("/");
  };
  
  return (
    <>

    <section className="main-page">
      <div className="content mt-1">
        {!akToken ? (
          <div className="header">
            <a
              href="http://akdevapp.duckdns.org:40001"
              className="logo reg__logo"
            >
              <img className="img-fluid" src={leftlogo} alt="" />
            </a>
            {/* <a
           href="#"
           className="signout-link"
         >
           Sign In
         </a> */}
          </div>
        ) : (
          ""
        )}

        <div className="body-content inner-body-content">
          <div className="error-page">
            <h4>Oops! the data you requested was not found.</h4>
            <span onClick={() => handelChange()} className="outline-btn c_pointer">
              Go back to Home
            </span>
          </div>
        </div>
        {/* FOOTER START */}
        {!akToken ? (
          <div className="footer">
            <a href="">
              <span />
            </a>
          </div>
        ) : (
          ""
        )}
      </div>
    </section>
  </>
  );
};

export default NotFoundPage;
