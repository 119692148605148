import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "../custom/button/Button";
import InputField from "../custom/inputfield/InputField";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import useAxios from "../../axiosinstance";
import { useSearchParams } from "react-router-dom";
import PlanListTable from "./PlanListTable";
import Message from "../custom/toster/Message";
import { Validators } from "../../utilities/Validator";
import { useSelector, useDispatch } from 'react-redux'
import { noteRefs } from "../../redux/actions";


function PlanList() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const axiosInstance = useAxios();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(25)
  const [offset, setOffset] = useState(25)
  const [plansData, setPlansData] = useState([]);
  const [planName, setPlanName] = useState("");
  const [hcpNumber, setHcpNumber] = useState("");
  const [patientNumber, setPatientNumber] = useState("");
  const [duration, setDuration] = useState("");
  const [dataLimit, setDataLimit] = useState(1);
  const [ShowAddPlan, setShowAddPlan] = useState(false);
  const [enable, setEnable] = useState(true)
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [refresh, setRefresh] = useState(false)
  const [loader1, setloader1] = useState(false);
  const[disble,setDisable]=useState(false)
  const[totalData,setTotalData]=useState("")
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
  const handleCloseInvite = () => {
    setPlanName("")
    setHcpNumber("")
    setPatientNumber("")
    setDuration("")
    setDataLimit(1)
    setShowAddPlan(false)


  };

  const planNameRef = useRef();
  const hcpCountRef = useRef();
  const patientCountRefRef = useRef();
  const limitRef = useRef();
  const durationRef = useRef();




  const getPlanList = async () => {
    try {
      const response = await axiosInstance.current.get(
        `extorg/admin/listOfPlans?status=${""}&limit=${limit}&offset=${0}`
      );
      if (response.status === 200) {
        setloader1(true)
        setPlansData(response.data.data)
        setTotalData(response.data.total_data)
      }
    } catch (error) {
      console.log("error", error)
    }
  }


  useEffect(() => {
    getPlanList()
    setOffset(25)
  }, [dataRefe])


  const fetchMoreData = () => {
    addMoreData();
  };




  const planNameChange = (e) => {
    setPlanName(e);
  };
  const hcpNumberChange = (e) => {
    setHcpNumber(e.replace(/[^0-9]/g, ""));
  };
  const patientNumberChange = (e) => {
    setPatientNumber(e.replace(/[^0-9]/g, ""));
  };
  const durationChange = (e) => {
    setDuration(e.replace(/[^0-9]/g, ""));
  };
  const dataLimitChange = (e) => {
    setDataLimit(e.replace(/[^0-9]/g, ""));
  };
  const handleInviteModal = () => {
    setShowAddPlan(true);
  };


  const handleCreatePlan = async () => {
    try {
      if (!planName) {
        planNameRef.current.errUpdate();
      }
      if (!duration && Number(duration) < 1) {
        durationRef.current.errUpdate();
      }
      if (!hcpNumber) {
        hcpCountRef.current.errUpdate();
      }
      if (!patientNumber) {
        patientCountRefRef.current.errUpdate();
      }
      if (!limit) {
        limitRef.current.errUpdate();
      }
      // if(Number(duration)<1){
      //   durationRef.current.errUpdate();
      // }
      if (
        !planName ||
        !duration ||
        !hcpNumber ||
        !patientNumber ||
        !limit || !duration || Number(duration) < 1
      ) {
      } else {
        setDisable(true)
        let json = {
          plan_name: planName,
          duration: duration,
          hcp: hcpNumber,
          patient: patientNumber,
          limit: Number(dataLimit)
        }
        const response = await axiosInstance.current.post(
          "extorg/admin/createPlan", json, {
          headers: {
            "Content-Type": "application/json",
          },
        }
        );
        setMessage("Plan Created")
        setMessageType("success");
        setDisable(false)
        dispatch(noteRefs(!dataRefe))
        setPlanName("")
        setHcpNumber("")
        setPatientNumber("")
        setDuration("")
        setDataLimit(1)
        setTimeout(() => {
          setMessage(false)
        }, 3000);
        setRefresh(true)
        setTimeout(() => {
          setRefresh(false)
        }, 1500);
        setShowAddPlan(false);
      }
    } catch (error) {
      setMessage(error.response.data.message)
      setMessageType("error");
      setTimeout(() => {
        setMessage(false)
      }, 2000)
    }

  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  });





  const addMoreData = async () => {
    setOffset(offset + 25);
    const response = await axiosInstance.current.get(
      `extorg/admin/listOfPlans?status=${""}&limit=${limit}&offset=${offset}`
    );
    const resdata = response.data.data;
    setTimeout(() => {
      setPlansData(plansData.concat(resdata));
    }, 500);
  };







  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="plan__list mb_100">
            {message && <Message message={message} type={messageType} />}

            <div className="panel mb_30">
              <div className="panel-heading  pannel_pd">
                <div className="row">
                  <div className="col-sm-6 col-md-6 pt_30">
                    <h4>Plans List</h4>
                    <p className="mb_30">
                        {totalData} {totalData > 1 ? "Results" : "Result"}
                      </p>
                  </div>
                  <div className="col-sm-6 col-md-6 pt_30 text-right">
                    <Button
                      onClick={handleInviteModal}
                      value="+ Create Plan"
                      buttonStyle="btn_fill w_160"
                    />
                  </div>
                </div>
              </div>
              <div className="panel-body p_25">
                {!loader1 ? <div className="no_data">
                  loading...
                </div> : (
                  <>
                    {
                      plansData.length > 0 ? (
                        <PlanListTable
                          plansData={plansData}
                          updatePagination={fetchMoreData}
                          setRefresh={setRefresh}
                          refresh={refresh}
                          totalData={totalData}
                        />
                      ) : (
                        <div className="no_data">No data found</div>
                      )}

                  </>
                )
                }
                {/* <>
                  {plansData.length > 0 ? (
                    <PlanListTable
                      plansData={plansData}
                      updatePagination={fetchMoreData}
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  ) : (
                    <div className="no_data">No Data Found</div>
                  )}
                </> */}
              </div>
            </div>
          </div>
          <Modal
            show={ShowAddPlan}
            onHide={handleCloseInvite}
            backdrop="static"
            keyboard={false}
            dialogClassName="modal-md patient_notes_popup"
          >
            <Modal.Header closeButton>
              <Modal.Title className="text-center">Add Plan</Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
              <InputField
                label="Name"
                value={planName}
                ref={planNameRef}
                type="text"
                placeholder="Enter Plan here"
                styleClass="br_10 mxw_100 mb_20"
                onChange={planNameChange}
                validators={[
                  {
                    check: Validators.required,
                    message: "* Name is missing",
                  },
                ]}
              />

              <div className="row">
                <div className="col-md-6">
                  <InputField
                    label="Maximum Number Of HCP"
                    value={hcpNumber}
                    ref={hcpCountRef}
                    type="text"
                    placeholder="Enter Number"
                    styleClass="br_10 mxw_100 mb_20"
                    onChange={hcpNumberChange}
                    validators={[
                      {
                        check: Validators.required,
                        message: "* HCP is missing",
                      },
                    ]}
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                    label="Maximum Number Of Patient"
                    value={patientNumber}
                    ref={patientCountRefRef}
                    type="text"
                    placeholder="Enter Number"
                    styleClass="br_10 mxw_100 mb_20"
                    onChange={patientNumberChange}
                    validators={[
                      {
                        check: Validators.required,
                        message: "* Patient is missing",
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <InputField
                    label="Duration (in days)"
                    value={duration}
                    ref={durationRef}
                    type="text"
                    placeholder="Please add in days"
                    styleClass="br_10 mxw_100 mb_20"
                    onChange={durationChange}
                    validators={[
                      {
                        check: Validators.required,
                        message: "* Duration is missing",
                      },
                      {
                        check: Validators.durationRequired,
                        message: "* Days should be more than 1 ",
                      },
                    ]}
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                    label="Limit (in GB)"
                    value={dataLimit}
                    ref={limitRef}
                    type="text"
                    placeholder="Please add limit in GB"
                    styleClass="br_10 mxw_100 mb_20"
                    onChange={dataLimitChange}
                    validators={[
                      {
                        check: Validators.required,
                        message: "*Limit is missing",
                      },
                      {
                        check: Validators.limitRequired,
                        message: "*Limit should be greater than 1GB",
                      },
                    ]}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: "flex-start" }}>
              <Button
                onClick={handleCloseInvite}
                value="Cancel"
                buttonStyle="btn_nill mr_20"
              />
              <Button
                onClick={handleCreatePlan}
                value="Save"
                width="120px"
                enable={disble}
                buttonStyle="btn_fill ms-4 clk_btn_disbled"

              />
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}

export default PlanList;
