
const initialState = {
    platforms: []
  }
  
  const platFormData = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_PLATFORM_LIST':
        return { ...state, platforms: action.data };

      default:
        return state;
    }
  }
  
  export default platFormData;