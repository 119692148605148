
const initialState = {
    tagsData: [],
  }
  
  const tagData = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_TAGS_LIST':
        return { ...state, tagsData: action.data };
      default:
        return state;
    }
  }
  
  export default tagData;