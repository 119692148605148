import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTable } from "react-table";
import TableSpinner from "../custom/spinner/TableSpinner";
import TableActionItems from "../custom/table-action-items/TableActionItems";
import InfiniteScroll from "react-infinite-scroll-component";
function UsersListTable({ hcpData, updatePagination,totaldata }) {
  const loginType = useSelector(
    (state) =>
      state.mainReducer.loginData.arr && state.mainReducer.loginData.arr[0]
  );
  // const login = useSelector((state) => state.mainReducer.loginData.arr);
  //   const [doctorID, setDoctorID] = useState(login.ing_ak_id);

  const actionData = {
    view_url: "patient-view",
    edit_url: "edit-user-details",
    delete_url: "",
  };
  //   function handleEdit(row) {
  //     console.log(row);
  //   }
  const data = hcpData;

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        id: "1",
        headerClassName: "col-img",
        className: "col-img",
        accessor: "firstname",
        Cell: (row) => (
          <span>
            <label className="user-letter small">
              {row.value && row.value.trim() !== '' ? row.value.charAt(0).toUpperCase() : 'A'}
            </label>
          </span>
        ),
      },
      {
        Header: "User ID",
        accessor: "ak_id",
      },
      {
        Header: "User Name",
        accessor: "hcpName",
        Cell: (row) => {
          const { firstname, lastname } = row.row.original;

          let fullName = "Not Yet Joined";

          if (
            (firstname || lastname) &&
            firstname !== null && lastname !== null &&
            firstname !== undefined && lastname !== undefined
          ) {
            fullName = `${firstname || ""} ${lastname || ""}`.trim();
          }

          return <span>{fullName}</span>;
        },
      },
      // {
      //   Header: "Organization",
      //   accessor: "akou_id",
      //   Cell: ({ cell }) => (
      //     <>
      //       {cell.value.map((id) => (
      //         <span key={id} style={{ display: "flex" }}>
      //           {id}
      //         </span>
      //       ))}
      //     </>
      //   ),
      // },
      {
        Header: "Type",
        headerClassName: "col-type",
        accessor: "type",
        className: "col-type",
      },
      {
        Header: "Email",
        accessor: "email",
        className: "text-lowercase",
        Cell: (row) => (
          <>
          {
            <p className="c_pointer org_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.row.original.email}>{row.row.original.email}</p>
          }
          </>
        )
      },
      {
        Header: "Account Status",
        accessor: "account_status",
        headerClassName: "col-acstatus",
        className: "col-acstatus",
        Cell: ( row ) => (
          <>
            {row.row.original.account_status === 1 ? (
              <span>Active</span>
            ) : (
              <span>InActive</span>
            )}
          </>
        ),
      },
      {
        Header: "Action",
        headerClassName: "col-action",
        accessor: "action",
        className: "col-action",
        Cell: (row) => (
          <span>
            <TableActionItems
              viewActionModal="true"
              editAction="true"
              feedbackAction="false"
              deleteAction={loginType.type === 2 ? false : true}
              arrowAction="false"
              rowName={row.row.original.ak_id}
              rowId={row.row.original.ak_id}
              rowData={actionData}
              rowType="usersList"
              notetitle={row.row.original.ea_name}
              rowOrgId={row.row.original.ea_akpt_id}
              orgId={row.row.original.ea_org_id}

              user_ak_id = {row.row.original.ak_id}
              user_first_name={row.row.original.firstname}
              user_last_name={row.row.original.lastname}
              user_email={row.row.original.email}
              user_type ={row.row.original.type}
              user_organziation={row.row.original.akou_id}
              user_status={row.row.original.account_status}

            />
          </span>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });
  return (
    <>
      <div className="tableContainer" id="rpScrollableDiv">
        <InfiniteScroll
          dataLength={rows.length}
          next={updatePagination}
          hasMore={true}
          scrollableTarget="rpScrollableDiv"
          // loader={<TableSpinner />}
          loader={ hcpData.length >= 25 && hcpData.length !== totaldata  ? <TableSpinner /> : null}

        >
          <table {...getTableProps()} className="custom-tbl table3">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps([
                        { className: column.headerClassName },
                      ])}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps([
                            { className: cell.column.className },
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </>
  );
}

export default UsersListTable;
