const regex = {
  email: new RegExp(
    "^(([^<>()\\[\\]\\\\.,;:\\s@]+(\\.[^<>()\\[\\]\\\\.,;:\\s@]+)*)|(.+))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$"
  ),
  password: new RegExp("^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{2,}$"),
  number: new RegExp("^[0-9]+$"),
};

export class Validators {
  static email(value, message) {
    if (value) {
      const result = regex.email.test(value);
      if (!result) return { error: true, message };
    }
    // return false;

    if (!value || !value.toString().trim().length) {
      return { error: true, message };
    }
    return false;
  }
  static password(value, message) {
    if (value) {
      const result = regex.password.test(value);
      if (!result) return { error: true, message };
    }
    // return false;

    if (!value || !value.toString().trim().length) {
      return { error: true, message };
    }
    return false;
  }

  static required(value, message) {
    if (!value || !value.toString().trim().length) {
      return { error: true, message };
    }
    return false;
  }

  static durationRequired(value, message) {
    if (Number(value)<1) {
      return { error: true, message };
    }
    return false;
  }



  static limitRequired(value, message) {
    if (value<=0) {
      return { error: true, message };
    }
    return false;
  }











  static requiredR(value, message) {
    if (!value || !value.toString().trim().length) {
      return { setErrorValR: true, message };
    }
    return false;
  }
  static requiredL(value, message) {
    if (!value || !value.toString().trim().length) {
      return { setErrorValL: true, message };
    }
    return false;
  }

  static number(value, message) {
    const length = value ? value.toString().length : 0;

    if (length > 0) {
      const result = regex.number.test(value);
      if (!result) {
        return { error: true, message };
      }
    }

    return false;
  }
  static homephone(value, message) {
    const expDateRegex = new RegExp(/^[\d\(\)]{0,10}$/);

    if (expDateRegex.test(value)) {
      return { setErrorValR: true, message };
    }
    return false;
  }

  static text(value, message) {
    var RegExpression = new RegExp(/^[a-zA-Z\s]*$/);
    const length = value ? value.length : 0;

    if (length > 0) {
      const result = RegExpression.test(value);
      if (!result) {
        return { error: true, message };
      }
    }

    return false;
  }

  static textNum(value, message) {
    var RegExpression = new RegExp(/^[a-zA-Z\s\-\/,\/'\/_]*$/);
    const length = value ? value.length : 0;

    if (length > 0) {
      const result = RegExpression.test(value);
      if (!result) {
        return { error: true, message };
      }
    }

    return false;
  }
  static maxfifty(value, message) {
    if (value.toString().trim().length >= 50) {
      return { error: true, message };
    }
    return false;
  }
  static max150(value, message) {
    if (value.toString().trim().length >= 150) {
      return { error: true, message };
    }
    return false;
  }
  static max4(value, message) {
    if (value.toString().trim().length >= 5) {
      return { error: true, message };
    }
    return false;
  }
  static max(value, message) {
    if (value.toString().trim().length >= 14) {
      return { error: true, message };
    }
    return false;
  }
  static max_refnumber(value, message) {
    if (value.toString().trim().length > 1) {
      return { error: true, message };
    }
    return false;
  }
  static maxPostalCode(value, message) {
    if (value.toString().trim().length >= 7) {
      return { error: true, message };
    }
    return false;
  }
  static postcode(value, message) {
    if (value.toString().trim().length >= 11) {
      return { error: true, message };
    }
    return false;
  }
  static min(value, message) {
    if (value.toString().trim().length <= 14) {
      return { error: true, message };
    }
    return false;
  }

  static maxmobile(value, message) {
    if (value.toString().trim().length >= 11) {
      return { error: true, message };
    }
    return false;
  }
  static homemaxmobile(value, message) {
    if (value.toString().trim().length >= 13) {
      return { error: true, message };
    }
    return false;
  }
  static homespacial(value, message) {
    const onlyNumbersAndParentheses = /^[\d()]+$/;
    if (onlyNumbersAndParentheses.test(value)) {
      return { setErrorValR: true, message };
    }
    return false;
  }
  static minmobile(value, message) {
    if (value.toString().trim().length <= 9) {
      return { error: true, message };
    }
    return false;
  }
  static spacial(value, message) {
    if (/[^a-zA-Z0-9\-\/ ]/.test(value)) {
      return { setErrorValR: true, message };
    }
    return false;
  }
  static spacialName(value, message) {
    if (/[^a-zA-Z0-9\-\/ ,\/'\/-\/_]/.test(value)) {
      return { setErrorValR: true, message };
    }
    return false;
  }
  static expirydate(value, message) {
    // console.log(typeof(parseInt(value)),"val ......................");

    // console.log(x);
    const expDateRegex = new RegExp(
      `/^(0[${value.toString()}]|1[${value.toString()}])\/?([${value.toString()}]{2}|[${value.toString()}]{3})\s*$/`
    );

    if (expDateRegex.test(value)) {
      return { setErrorValR: true, message };
    }
    return false;
  }

  //   static expirydate(value, message) {
  //     const length = value ? value.length : 0;

  //     if (length > 0 || (/[^0-9/]/).test(value)) {
  //       const result = regex.number.test(value);
  //       if (!result) {
  //         return { error: true, message };
  //       }
  //     }

  //     return false;
  //   }
}

export const validateInput = (validators, value) => {
  if (validators && validators.length) {
    for (let i = 0; i < validators.length; i++) {
      const error = validators[i].check(value, validators[i].message);
      if (error) {
        return error;
      }
    }
  }
  return false;
};

export const validateDropdown = (validators, value) => {
  if (validators && validators.length) {
    for (let i = 0; i < validators.length; i++) {
      const error = validators[i].check(value, validators[i].message);
      if (error) {
        return error;
      }
    }
  }
  return false;
};

export const validateCheckbox = (validators, value) => {
  if (validators && validators.length) {
    for (let i = 0; i < validators.length; i++) {
      const error = validators[i].check(value, validators[i].message);
      if (error) {
        return error;
      }
    }
  }
  return false;
};

export const validateRadioSwitch = (validators, value) => {
  if (validators && validators.length) {
    for (let i = 0; i < validators.length; i++) {
      const error = validators[i].check(value, validators[i].message);
      if (error) {
        return error;
      }
    }
  }
  return false;
};

export const spacialCharReplace = (e) => {
  return e.replace(/-|'|,|_/g, " ");
};
