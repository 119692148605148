import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./DeleteConfirmation.css";
import DeleteIcon from "../../../images/delete-red.svg";

const DeleteConfirmation = ({
  showModal,
  hideModal,
  confirmModal,
  id,
  type,
  message,
}) => {
  return (
    <Modal
      show={showModal}
      onHide={hideModal}
      className={`delete_modal_main`}
      centered
    >
      <Modal.Body>
        <div className="text-center">
          <img src={DeleteIcon} className="delete_pp_icon" alt="icon" />
          <h4 className="center_title">
            Are you sure you want to delete <strong>{message}</strong>?
          </h4>
        </div>
      </Modal.Body>
      <Modal.Footer className="text-center">
        <Button variant="default" className="btn_outline" onClick={hideModal}>
          Cancel
        </Button>
        <Button
          variant="danger"
          className="btn_danger"
          onClick={() => confirmModal(type, id)}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmation;
