import { API_MESSAGETYPE } from "../actions/actionTypes";

const initialState = { 
    arrMessageType : ""
}
  
  const apiMessageTypeReducer = (state = initialState, action) => {
    switch (action.type) {
      case API_MESSAGETYPE :
        return { ...state, arrMessageType: action.data };

      default:
        return state;
    }
  }

  export default apiMessageTypeReducer;