export default {
  Next:"F-Next",
  Back:"F-Back",
  Continue:"F-Continue",
  myProfile: "F- My Profile",
  visitClinic:"F-Visit Clinic",
  myVisit:"Past Visits",
  doctorOrganization:"F-Doctor Organization",
  areyousurewanttocontinueyourform:"F-Are you sure want to continue your form",
  no:"F-No",
  yes:"F-Yes",
  List_of_my_profile:"F-List of my profile",
  upcoming_visits: "F-Upcoming",
  No_data_found:"F-No data found",
  Radiology:"F-Radiology",
  Pathology:"F-Pathology",
  akmed:"F- Akunah Med",
  settings:"F-Settings",
  AppVersion:"F-App Version",
  language:"F-Language & Region",
  timezone:"F-Timezone",
  Timeformat:"F-Time format",
  Organization:"F-Organization*",
  Select_Doctor:"F-Select Doctor*",
  Select_Treatment_Category:"F-Select Treatment Category*",
  Doctor_Name:"F-Doctor Name",
  Appointment_Date_Time:"F-Appointment Date & Time",
  Clinic_Name:"F-Clinic Name",
  Clinic_Address:"f-Clinic Address",
  Message:"F-Message",
  View_score:"F-View score",
  First_Name:"F-First Name*",
  Last_Name:"F-Last Name*",
  DOB:"F-Date of Birth*",
  Sex:"F-Sex*",
  Address:"F-Address*",
  Postcode:"F-Postcode*",
  Suburb:"F-Suburb*",
  Country_Code:"F-Country Code*",
  Mobile_Phone:"F-Mobile Phone*",
  Home_Phone:"F-Home Phone",
  Email:"F-Email*",
  Emergency_Contact:"F-Emergency Contact*",
  Phone:"F-Phone*",
  Relationship:"F-Relationship*",
  Regular_GP_Name:"F-Regular GP Name*",
  Practice_Name:"F-Practice Name*",
  Medicare_Card_Number:"F-Medicare Card Number",
  Ref:"F-Ref",
  Expiry:"F-Expiry",
  Private_Health_Fund:"F-Private Health Fund",
  Membership_Number:"F-Membership Number",
  Claim_Type:"F-Claim Type",
  Work_Cover:"F-Work Cover",
  Employer:"F-Employer",
  Third_Party_Claim_Number:"F-Third Party Claim Number",
  Third_Party_Name:"F-Third Party Name",
  DVA_Concession_Card:"F-DVA Concession Card",
  Number:"F-Number",
  Defence_Personnel:"F-Defence Personnel",
  I_agree :"F-I agree to the processing of my personal data and have read the ",
  Privacy_Policy:"F-Privacy Policy.",
  Select_Existing_Profile:"F-Select Existing Profile",
  Get_In:"F-Enter",
  Create_new_patient:"F-Create a new patient",
  Complete_personal_info:"F-Complete personal info",
  To_complete_the_sign_up:"F-To complete the sign up please fill in your info below.",









};
