import React, { useState } from "react";
import { useTable } from "react-table";
import useAxios from "../../axiosinstance";
import TableSpinner from "../custom/spinner/TableSpinner";
import TableActionItems from "../custom/table-action-items/TableActionItems";
import InfiniteScroll from "react-infinite-scroll-component";
import Switch from "../custom/switch/Switch";
import { useSelector,useDispatch } from "react-redux";
import { noteRefs } from "../../redux/actions";
import Message from "../custom/toster/Message";
function GroupListTable({ groupsData, updatePagination,totaldata }) {
  const login = useSelector(
    (state) =>
      state.mainReducer.loginData.arr && state.mainReducer.loginData.arr[0]
  );
  const [isChecked, setIsChecked] = useState(false);
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
  const axiosInstance = useAxios();
  const dispatch = useDispatch();
  const actionData = {
    view_url: "patient-view",
    edit_url: "edit-group-details",
    delete_url: "",
  };
  //   function handleEdit(row) {
  //     console.log(row);
  //   }
  const data = groupsData;

 

  const handleSwitchChange = async (status, id) => {
    console.log("status switch group list--", status, id);
    let s;
    if(Number(status) === 1){
      s = 0
    }else{
      s = 1
    }
    let res = await axiosInstance.current.delete(
      `extorg/group/deleteGroup/${login.ak_id}/${id}?status=${s}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res)=>{
      setMessage("Status Updated");
      setMessageType("success");
      dispatch(noteRefs(new Date().getSeconds()));
      setTimeout(() => {
        setMessage(false)
      }, 2000);
    }).catch((err)=>{
      setMessage("Status Not Update")
      setMessageType("error");
    });
    
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S No.",
        // accessor: "serial_no",
        accessor: (row, index) => index + 1,
        headerClassName: "col-sno",
        className: "col-sno",
      },
      {
        Header: "Id",
        accessor: "group_id",
        headerClassName: "col-id",
        className: "col-id",
      },
      {
        Header: "Created By",
        accessor: "created_by",
      },
      {
        Header: "Group Name",
        accessor: "group_name",
      },
      {
        Header: "Organization ID",
        accessor: "akou_id",
      },
      {
        Header: "Group Type",
        accessor: "group_type",
      },
      {
        Header: "Status",
        accessor: "status",
        headerClassName: "col-status",
        className: "col-status",
        Cell: (row) => (
          //  console.log("row", row.row.original.sno),
          <span>
            <Switch
              name={`on-off-switch${row.row.original.group_id}`}
              tabIndex={1}
              checked={row.row.original.status}
              onChange={(e) => handleSwitchChange(row.row.original.status, row.row.original.group_id)}
            />
          </span>
        ),
      },
      {
        Header: "Action",
        headerClassName: "col-action",
        accessor: "action",
        className: "col-action",
        Cell: (row) => (
          <span>
            <TableActionItems
              viewAction="false"
              editAction="true"
              feedbackAction="false"
              deleteAction="true"
              arrowAction="false"
              rowName={"videoID"}
              rowId={row.row.original.group_id}
              rowData={actionData}
              rowType="groupsList"
              notetitle={row.row.original.ea_name}
              rowOrgId={row.row.original.status}
              orgId={row.row.original.group_id}
            />
          </span>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });
  return (
    <>
     {message && <Message message={message} type={messageType} />}
      <div className="tableContainer" id="rpScrollableDiv">
        <InfiniteScroll
          dataLength={rows.length}
          next={updatePagination}
          hasMore={true}
          scrollableTarget="rpScrollableDiv"
          // loader={<TableSpinner />}
          loader={ groupsData.length >= 25 && groupsData.length !== totaldata  ? <TableSpinner /> : null}

        >
          <table {...getTableProps()} className="custom-tbl custom-tbl4">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps([
                        { className: column.headerClassName },
                      ])}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps([
                            { className: cell.column.className },
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </>
  );
}

export default GroupListTable;
