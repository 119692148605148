const initialState = { 
    arr : {}
}
  
  const accessTokens = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_TOKEN':
        return { ...state, arr: action.data };

      default:
        return state;
    }
  }

  export default accessTokens;