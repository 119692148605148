import React, { Component, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Dropdown from "../custom/dropdown/Dropdown";
import Button from "../custom/button/Button";
import { useSelector, useDispatch } from "react-redux";
import { Validators } from "../../utilities/Validator";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import useTranslation from "../customHooks/translations";
import Message from "../custom/toster/Message";
// import App from "../../App";
import useAxios from "../../axiosinstance";
// import { loginData } from "../../redux/actions/index";
import { loginData } from "../../redux/actions/index";
import { s3UrlLink } from "../../utilities/S3Url";
import plus from "../../images/user_demo.jpg";



function DoctorMap() {
  const translation = useTranslation();
  const dispatch = useDispatch();
  const axiosInstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const login = useSelector((state) => state.mainReducer.userDetails.user);
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [doctor, setDoctor] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const options = login.data.map((item) => {
    return { value: item.akoa, label: item.name + " (" + item.akoa + ")" , image: item.ixmxg,
    name: item.sname};
  });

  const handleDoctor = (e) => {
    setDoctor(e);
  };

  const redirectToHome = () => {
    navigate("/", { replace: true });
    // window.location.reload(false);
  };


console.log("options",options)

  // useEffect(() => {
  const handleConnectMe = async (values) => {
    try {
  
      const result = await axiosInstance.current.get(
        `extorg/org/userProfile?ak_id=${values}`
      );
      if (result.status == "200") {
        if (result.data.status) {
          dispatch(loginData(result.data.data));
          localStorage.setItem("AKTOKENORG", values);
          redirectToHome();
        } else {
          // redirectToError();
        }
      }
    } catch (err) {
      setMessage(err.message || "Unexpected Error!");
      console.log(err.Messages);
      setMessageType("error");
    } finally {
      console.log("api response finally");
    }
  };
  // getData();
  // }, []);
  // console.log(login.count)
  const handleSubmit = (e) => {
    e.preventDefault();

    if (doctor.trim().length === 0) {
      console.log("form without values âœ…");
      return;
    }
    handleConnectMe();
  };


  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
      // <>
      //  <div className="container">
      //       {message && <Message message={message} type={messageType} />}
      //       <h2
      //         className="f_600 f_24 mt_40 mb_60 text-center"
      //         style={{ marginBottom: "50px" }}
      //       >
      //         {translation.List_of_my_profile}
      //       </h2>
      //       <div className="row justify-content-center">
      //         {/* <div className="col-12 d-flex"> */}
      //         {options.map((res) => (
      //           <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
      //             <div
      //               className="card-border"
      //               onClick={() => handleConnectMe(res.value)}
      //             >
      //               {res.image ? (
      //                 <img
      //                   className="object-fit-contain"
      //                   style={{ "max-width": "100%", height: "184px", minHeight: "180px" }}
      //                   src={s3UrlLink(res.image)}
      //                   alt=""
      //                 ></img>
      //               ) : (
      //                 <div className="patient-map-name">
      //                   <p style={{ "font-size": "80px" }}>{res.name}</p>
      //                 </div>
      //               )}
      //             </div>
      //             <span className="ms-1 ">{res.label}</span>
      //           </div>
      //         ))}

      //         {/* <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
      //           <div
      //             className="card-border c_pointer patient-map-app"
      //             onClick={() => continueRedirect()}
      //           >
      //             <p>
      //               <img className="" src={plus} alt=""></img>
      //             </p>
      //           </div>
      //           <span>{translation.Create_new_patient}</span>
      //         </div> */}
      //         {/* </div> */}
      //       </div>
      //       {/* <div class="add-btn row justify-content-center">
      //         <div className="col-sm-2 card-border">
      //           <img className="" src={plus} style={{ height: "100%" }} alt=""></img>
      //         </div>
      //       </div> */}
      //     </div>
      // </>
      <>
          <div className="container">
            {message && <Message message={message} type={messageType} />}
            <h2
              className="f_600 f_24 mt_40 mb_60 text-center"
              style={{ marginBottom: "50px" }}
            >
              My Profiles
            </h2>

            <div className=" h-screen flex flex-col items-center justify-content-center d-flex ">
              <div className="flex flex-row flex-wrap gap-5 mt-8 d-flex justify-content-center">
                {options.map((res) => (
                  <div
                    className="flex flex-col items-center group gap-2  patient_imgS c_pointer"
                    onClick={() => handleConnectMe(res.value)}
                  >
                    {res.image ? (
                      <img
                        className="rounded border-2 border-transparent group-hover:border-2 group-hover:border-gray-300 object-fit-scale patient_imgSS "
                        // style={{ "max-width": "100%", height: "170px" }}
                        width="150"
                        height="150"
                        src={s3UrlLink(res.image)}
                      />
                    ) : (
                      <img
                        className="rounded border-2 border-transparent group-hover:border-2 group-hover:border-gray-300 object-fit-scale patient_imgSS"
                        width="150"
                        height="150"
                        src={plus}
                      />
                    )}
                    <p
                      className="text-gray-500 group-hover:text-gray-300 text-center mt-3 "
                      style={{
                        width: "150px",
                        color: "#3f2783",
                        fontWeight: 500,
                      }}
                    >
                      {" "}
                      {res.label}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            {/* <div class="add-btn row justify-content-center">
              <div className="col-sm-2 card-border">
                <img className="" src={plus} style={{ height: "100%" }} alt=""></img>
              </div>
            </div> */}
          </div>
        </>
      )}
    </>
  );
}

export default DoctorMap;
