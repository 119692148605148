import React from "react";
import PlanList from "./PlanList";
import  "./Plans.css";

function Plans() {
  return (
    <>
      <div>
        <PlanList />
      </div>
    </>
  );
}

export default Plans;
