import React, { useEffect, useState, useRef } from "react";
// import { ProgressBar, Jumbotron, Button, Form } from "react-bootstrap";
// import { v4 as uuidv4 } from "uuid";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import HttpService from "../../services/HttpService";
import useAxios from "../../../axiosinstance";
import useAxiosUpload from "../../../axiosinstanceUpload";

// import axios from "axios";
function ChunkUpload(data2) {
  const axiosinstance = useAxios();
  const axiosInstanceUpload = useAxiosUpload();

  const ref = useRef();
  const chunkSize = 1048576 * 5; //its 5MB, increase the number measure in mbfunction App() {
  const [showProgress, setShowProgress] = useState(false);
  const [counter, setCounter] = useState(1);
  const [fileToBeUpload, setFileToBeUpload] = useState({});
  const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0);
  const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize);
  const [progress, setProgress] = useState(0);
  const [fileGuid, setFileGuid] = useState("");
  const [fileSize, setFileSize] = useState(0);
  const [chunkCount, setChunkCount] = useState(0);

  const [theArray, setTheArray] = useState([]);
  const [file, setFile] = useState("");
  const [uploadid, setUploadid] = useState("");
  const [sessionId, setsessionId] = useState("");
  const [s3Key, sets3Key] = useState("");
  const user = {
    filelength: fileSize,
  };
  const onClick = () => {
    data2.sendData(user);
  };

  useEffect(() => {
    onClick();
  }, [fileSize]);
  // console.log(file)
  console.log(file);
  // const [load, setVideoload] = useState(false);
  const [last, setLast] = useState({});
  // let viId = 1234;
  const progressInstance =
    // <ProgressBar animated now={progress} label={`${progress}%`} />
    progress;

  useEffect(() => {
    if (fileSize > 0) {
      setShowProgress(true);
      fileUpload(counter);
    }
  }, [uploadid, progress]);

  const getFileContext = (e) => {
    document.getElementById("vid_disable").disabled = true;

    data2.setVideoload(true);
    // Call initUpload
    const _file = e.target.files[0];
    setFile(_file.name);
    const _fileID = "vid" + "." + _file.name.split(".").pop();
    initUpload(_fileID);
    setFileSize(_file.size);
    const _totalCount =
      _file.size % chunkSize === 0
        ? _file.size / chunkSize
        : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
    setChunkCount(_totalCount);
    setFileToBeUpload(_file);
    // const _fileID = uuidv4() + "." + _file.name.split(".").pop();
    setFileGuid(_fileID);
  };

  const initUpload = async (q) => {
    try {
      // Call the Init API
      setProgress(0);

      const response = await axiosInstanceUpload.current.get(
        `extglobal/init-upload/${q}/${data2.video_key}`
      );
      // console.log(response)
      setUploadid(response.data.data.UploadId);
      setsessionId(response.data.data.sessionId);
      localStorage.setItem("sessionId",response.data.data.sessionId)
      sets3Key(response.data.data.s3Key);
    } catch (error) {
      console.log("error", error);
    }
  };
 
  
  const fileUpload = () => {
    setCounter(counter + 1);
    if (counter <= chunkCount) {
      var chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
      uploadChunk(chunk);
    }
  };
  const uploadChunk = async (chunk) => {
    try {
      // let body = {
      // file_name: fileGuid,
      // upload_id: uploadid,
      // chunk_id: counter,
      // bytes: chunk,
      // video_key: data2.video_key
      // };

      const formData = new FormData();
      formData.append("sessionId", sessionId);
      formData.append("uploadId", uploadid);
      formData.append("key", s3Key);
      formData.append("partNum", counter);
      formData.append(`size`, chunk.size);
      formData.append(`chunk`, chunk);

      const response = await axiosInstanceUpload.current.post(
        `extglobal/upload-chunk`,
        formData,
        {
          headers: {
            // 'Accept': 'application/json',
            "Content-Type": "multipart/form-data",
          },
        }
      );
      let data = response.data;

      setTheArray([...theArray, data.data]);

      if (data.PartNumber !== 0) {
        setBeginingOfTheChunk(endOfTheChunk);
        setEndOfTheChunk(endOfTheChunk + chunkSize);
        if (counter == chunkCount) {
          setLast(data.payload);
          // setTheArray([...theArray, data.payload]);
          console.log("Process is complete, counter", counter);
        } else {
          var percentage = (counter / chunkCount) * 100;
          setProgress(percentage);
        }
      } else {
        console.log("Error Occurred:", data.errorMessage);
      }
    } catch (error) {
      console.log("error", error);
    }
    // setTheArray([...theArray, last]);
  };

  function resetChunkProperties() {
    const data = {
      uploadId: uploadid,
      key: s3Key,
    };
    axiosInstanceUpload.current.post(`extglobal/abort-upload`, data).then((response) => {
      console.log(response);
    });
    ref.current.value = "";
    document.getElementById("vid_disable").disabled = false;
    data2.setVideoload(false);
    setShowProgress(true);
    setProgress(0);
    setFileToBeUpload({});
    setCounter(1);
    setBeginingOfTheChunk(0);
    setEndOfTheChunk(chunkSize);
    setChunkCount(0);
    setFileSize(0);
    setUploadid("");
    setTheArray([]);
    setFileGuid("");
  }


console.log("theArray",theArray)

  const uploadCompleted = async () => {
    const params = {
      Key: s3Key,
      UploadId: uploadid,
      MultipartUpload: {
        Parts: theArray,
      },
    };




    const response = await axiosInstanceUpload.current.post(`extglobal/end-upload`, params);

    const data1 = await response.data;

    const response1 = await axiosInstanceUpload.current.get(
      `extglobal/get_size?uploadId=${params.UploadId}`
    );
    data2.setfileSize(response1.data.size);
    console.log("response1", response1.data.size);

    if (data1) {
      data2.setVideoload(false);
      setProgress(100);
      document.getElementById("vid_disable").disabled = true;
    }
  };

  useEffect(() => {
    if (chunkCount !== 0) {
      if (chunkCount === theArray.length) {
        // console.log("hhhhh");
        uploadCompleted();
      }
    }
  }, [theArray]);


  const handleVideoMetadata = (event) => {
    const videoElement = event.target;
    const videoDuration = videoElement.duration; // duration in seconds
  
    console.log(`Video duration: ${videoDuration} seconds`,videoElement);
  };
  return (
    <>
      <div className="text-center">
        {/* <button onClick={onClick}>vvvv</button> */}

        <input
          type="file"
          onChange={getFileContext}
          className="form-control f_14 l_height_38 br_10 mxwp_80"
          accept=".mp4"
          id="vid_disable"
          ref={ref}
          onLoadedMetadata={handleVideoMetadata}
        ></input>

        {data2.load && ( 
          <div className="progressing-bar">
            <button
              type="button"
              class="btn premium-btn mt-3 position-relative"
            >
              Uploading... {Math.ceil(progressInstance)}%
              <span
                onClick={resetChunkProperties}
                class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
              >
                X
              </span>
            </button>
          </div>
        )}
      </div>
    </>
  );
}
export default ChunkUpload;