import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputField from "../../custom/inputfield/InputField";
import LoadingSpinner from "../../custom/loader/LoadingSpinner";
import useAxios from "../../../axiosinstance";
import Dropdown from "../../custom/dropdown/Dropdown";
import Message from "../../custom/toster/Message";
import { useSelector, useDispatch } from "react-redux";
import { Validators, spacialCharReplace } from "../../../utilities/Validator";
import { dateChangeByTimezone } from "../../../utilities/DateFormate";

function EditUserDetails() {
  const login = useSelector(
    (state) =>
      state.mainReducer.loginData.arr && state.mainReducer.loginData.arr[0]
  );
  const [isLoading, setIsLoading] = useState(true);
  const axiosInstance = useAxios();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [formVaild, setFormVaild] = useState(false);
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [sex, setSex] = useState("");
  const [dateErr, setDateErr] = useState(false);
  const [Organization, setOrganization] = useState("");
  const [startDate, setStartDate] = useState("");
  const [dobError, setDobError] = useState(false);
  const [email, setEmail] = useState("");
  const[disble,setDisable]=useState(false)
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const { akou } = useParams();
  const sexRef = useRef();
  const emailRef = useRef();
  const maxDate = new Date();
  const minDate = new Date(1900, 0, 1);
  const navigate = useNavigate();
  const handleMail = (e) => {
    setEmail(e);
  };
  const handleFirstName = (e, err) => {
    setFirstNameErr(err);
    setFirstName(spacialCharReplace(e));
    setFormVaild(e.error ? e.error : false);
  };
  const handleLastName = (e, err) => {
    setLastNameErr(err);
    setLastName(spacialCharReplace(e));
    setFormVaild(e.error ? e.error : false);
  };
  const handleSex = (e) => {
    setSex(e);
  };
  const handleOrg = (e) => {
    setOrganization(e);
  };
  const handleDob = (e) => {
    setStartDate(e);
    setDobError(false);
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
    setDateErr(false);
  };

 
  const getEditData = async () => {
    try {
      const result = await axiosInstance.current.get(
        `extorg/admin/getUserDetails/${login.ak_id}/${akou}`
      );
      if (result.status === 200) {
        if (result.data.status) {
          setFirstName(result.data.user.firstname);
          setLastName(result.data.user.lastname);
          setSex(result.data.user.gender);

          const dob = result.data.user.dob;
          function isEmpty(value) {
            return value === 0 || value === "0" || value === null || value === "" || typeof value === "undefined";
          }
          const startDategot = isEmpty(dob) ? "" : new Date(dob);
          setStartDate(startDategot);
        }
      }
    } catch (err) {
      console.log(err.Messages);
    } finally {
      console.log("api response finally");
    }
  };
  useEffect(() => {
    getEditData();
  }, []);
  const continueRedirect = (e) => {
    setMessage(false);
    e.preventDefault();
    if (!firstName) {
      firstNameRef.current.errUpdate();
    }
    if (!lastName) {
      lastNameRef.current.errUpdate();
    }
    if (!sex) {
      sexRef.current.errUpdate();
    }
    if (!startDate) {
      setDateErr(true)
    }
    if (!firstName || !lastName || !sex || firstNameErr || lastNameErr || !startDate) {
      setFormVaild(true);
    } else if (formVaild) {
      // alert("form values not filled 2");
    } else {
      setDisable(true)
      const json = JSON.stringify({
        firstname: firstName,
        lastname: lastName,
        gender: sex,
        dob: new Date(startDate).toISOString(),
        // dob: dateChangeByTimezone(startDate, login.patient.timezone),
      });
      console.log("--json data", json);
      axiosInstance.current
        .put(`extorg/admin/updateUser/${login.ak_id}/${akou}`, json, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setMessageType("success");
          setMessage("User Updated");
          setTimeout(() => {
            navigate("/users-list");
            setMessage(false);
          }, 1500);
        })
        .catch((err) => {
          setMessage(err.response.data.message);
          setMessageType("error");
          setDisable(false)
        });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="edit_hcp_screen mb_100">
          {message && <Message message={message} type={messageType} />}
          <div className="row page_header">
            <div className="col-sm-6 col-md-6">
              <h4 className="ph_title">Edit User</h4>
            </div>
          </div>

          <div className="panel">
            <div className="panel-body p_25">
              <div className="row">
                <div className="col-sm-5 col-md-5 mb_20">
                  <InputField
                    label="First Name*"
                    value={firstName}
                    name="firstName"
                    type="text"
                    placeholder="First Name*"
                    onChange={handleFirstName}
                    ref={firstNameRef}
                    validators={[
                      {
                        check: Validators.required,
                        message: "This field is required",
                      },
                      {
                        check: Validators.spacialName,
                        message: "No special characters are allowed",
                      },
                      // {
                      //   check: Validators.textNum,
                      //   message: "Numbers are Not allowed",
                      // },
                      {
                        check: Validators.maxfifty,
                        message: "Maximum 50 char are allowed",
                      },
                    ]}
                    styleClass="br_10 mxw_285"
                  />
                </div>
                <div className="col-sm-5 col-md-5 mb_20">
                  <InputField
                    label="Last Name*"
                    value={lastName}
                    name="lastName"
                    type="text"
                    placeholder="Last Name*"
                    onChange={handleLastName}
                    ref={lastNameRef}
                    validators={[
                      {
                        check: Validators.required,
                        message: "This field is required",
                      },
                      {
                        check: Validators.spacialName,
                        message: "No special characters are allowed",
                      },
                      // {
                      //   check: Validators.textNum,
                      //   message: "Numbers are Not allowed",
                      // },
                      {
                        check: Validators.maxfifty,
                        message: "Maximum 50 char are allowed",
                      },
                    ]}
                    styleClass="br_10 mxw_285"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-5 col-md-5 mb_20">
                  <Dropdown
                    data={[
                      { value: 1, label: "Male" },
                      { value: 2, label: "Female" },
                      { value: 3, label: "Do not want to disclose" },
                    ]}
                    label={"Sex*"}
                    styleClass="mxw_285 br_10"
                    value={sex}
                    ref={sexRef}
                    placeholder="Select"
                    onChange={handleSex}
                    validators={[
                      {
                        check: Validators.required,
                        message: "This field is required",
                      },
                    ]}
                  />
                </div>
                <div className="col-sm-5 col-md-5 mb_20">
                  <Form.Group className="mb-3" controlId="fromDate">
                    <Form.Label>
                      {"DOB"}{" "}
                      <i style={{ "font-style": "italic" }}>(DD/MM/YYYY)*</i>
                    </Form.Label>
                   
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      minDate={minDate}
                      maxDate={maxDate}
                      className="form-control mb_10 br_10 mxw_285"
                      selected={startDate}
                      onChange={(date) => handleStartDateChange(date) }
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      validators={[
                        {
                          check: Validators.required,
                          message: "This field is required",
                        },

                        {
                          check: Validators.number,
                          message: "No special characters are allowed",
                        },
                      ]}
                    />
                    {dateErr && <span className="text-danger">This field is reqired</span>}
                  </Form.Group>
                </div>
              </div>
            </div>
            <div className="row mb_30 button_box">
              <div className="col-md-6 col-sm-6">
                <Link to="/" className="btn_outline edit_btn_back">
                  <span>{"Back"}</span>
                </Link>
              </div>
              <div className="col-md-6 col-sm-6 text-end">
                <button
                  className="next_btn btn_fill clk_btn_disbled"
                  disabled={disble}
                  onClick={continueRedirect}
                >
                  {"Update"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EditUserDetails;
