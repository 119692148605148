import React, { useMemo, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Validators } from "../../utilities/Validator";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../custom/button/Button";
import InputField from "../custom/inputfield/InputField";
import Dropdown from "../custom/dropdown/Dropdown";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import useAxios from "../../axiosinstance";
import { useSearchParams } from "react-router-dom";
import useTranslation from "../customHooks/translations";
import OrganizationListTable from "./OrganizationListTable";
import logo from "../../images/dashboard-logo.svg";
import userIcon from "../../images/Union.svg";
import homeIcon from "../../images/Home.svg";
import contactIcon from "../../images/contact.svg";
import "./Organization.css";
import SortDropDownAlphabet from "../custom/sortdropalphabet/SortDropDownAlphabet";
import Message from "../custom/toster/Message";
// import Message from "../../custom/toster/Message";

function Organizations() {
  const navigate = useNavigate();
  const translation = useTranslation();
  const axiosInstance = useAxios();
  const orgModelRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [orgModel, setOrgModel] = useState("");
  let searchData = searchParams.get("q");
  const [qData, setqData] = useState("");
  let searchType = searchParams.get("search");

  const [isLoading, setIsLoading] = useState(true);
  const login = useSelector(
    (state) =>
      state.mainReducer.loginData.arr && state.mainReducer.loginData.arr[0]
  );
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);

  const [orgData, setData] = useState([]);
  const [orgName, setOrgName] = useState("");
  const [email, setEmail] = useState("");
  // const [status, setStatus] = useState("");
  const [searchEnable, setsearchEnable] = useState("N");
  const[disble,setDisable]=useState(false)

  const [sort, setSort] = useState(1);

  const [status, setStatus] = useState("");

  const [offset, setOffset] = useState(25);
  const [limit, setLimit] = useState(25);
  const [sortType, setSortType] = useState("last_created");
  const [totaldata, setTotaldata] = useState("");
  const [inviteEmail, setInviteEmail] = useState("");
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [orgapiData, setOrgData] = useState([]);
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [subject, setSubject] = useState("");
  const [messageChat, setMessageChat] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [lastTypingTime, setLastTypingTime] = useState(null);
  const subjectRef = useRef();
  const messageChatRef = useRef();

  const handleClose = () => {
    setShowInviteModal(false)
    setSubject("")
    setMessageChat("")
  };
  const handleCloseInvite = () => {
    setShowInviteModal(false)
    setSubject("")
    setMessageChat("")
  };

  const titleChange = (e) => {
    setSubject(e);
  };
  const descriptionChange = (e) => {
    setMessageChat(e);
  };


  useEffect(() => {
    setqData(searchData);
    setsearchEnable(searchType);
  }, [searchData, searchType]);

  const removeQueryParams = () => {
    const param = searchParams.get("q");

    if (param) {
      // 👇️ delete each query param
      searchParams.delete("q");
      searchParams.delete("search");

      // 👇️ update state after
      setSearchParams(searchParams);
    }
  };

  const handleReset = (e) => {
    setOrgName("");
    if (searchType === "Y") {
      setsearchEnable("Y");
    }
  };
  const advsearchToOrgName = (event) => {
    console.log("eventevent",event.length)
    setLastTypingTime(new Date().getTime())
      setOrgName(event);
    removeQueryParams();
    if (event === "") {
      // setsearchEnable("Y");
    } else {
      setsearchEnable("N");
    }
    setOffset(25);
  };



  const inviteEmailChange = (e) => {
    console.log("test", e);
    // };


  }
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    const getData = async () => {
      try {

        const response = await axiosInstance.current.get(
          `extorg/org/listOfOrgs?ak_id=${login.ak_id}&offset=0&limit=${limit}&key=${orgName}&status=${status}&sortBy=${sort}`
        );
        setData(response.data.data);
        setTotaldata(response.data.total_data);
      } catch (error) {
        console.log("An error occurred while fetching data.");
      }
    };
    getData();
  }, [dataRefe]);


  useEffect(() => {
    if (lastTypingTime && orgName.length>2 ) {
      const timer = setTimeout(() => {
        const getData = async () => {
          try {
            const response = await axiosInstance.current.get(
              `extorg/org/listOfOrgs?ak_id=${login.ak_id}&offset=0&limit=${limit}&key=${orgName}&status=${status}&sortBy=${sort}`
            );
            setData(response.data.data);
            setTotaldata(response.data.total_data);
          } catch (error) {
            console.log("An error occurred while fetching data.");
          }
        };

        getData();



      }, 1000);
      return () => clearTimeout(timer)
    }
  }, [orgName])

useEffect(()=>{
  const getData = async () => {
    if(orgName.length===0){
      try {
        const response = await axiosInstance.current.get(
          `extorg/org/listOfOrgs?ak_id=${login.ak_id}&offset=0&limit=${limit}&key=${orgName}&status=${status}&sortBy=${sort}`
        );
        setData(response.data.data);
        setTotaldata(response.data.total_data);
      } catch (error) {
        console.log("An error occurred while fetching data.");
      }
    }
    
  };

  getData();
},[orgName.length])










  useEffect(() => {
    const getDataSearch = async () => {
      try {
        if (orgName.length > 2 || orgName.length === 0) {
          setTimeout(async () => {
            const response = await axiosInstance.current.get(
              `extorg/org/listOfOrgs?ak_id=${login.ak_id}&offset=${offset}&limit=${limit}&key=${orgName}&status=${status}&sortBy=${sort}`
            );
            setData(response.data.data);
            setTotaldata(response.data.total);
          }, 2000);
        }

      } catch (error) {
        console.log("An error occurred while fetching data.");
      }
    };
    if (searchEnable === "Y") {
      setTimeout(() => {
        getDataSearch();
      }, 1000);
    }
  }, [qData, searchEnable]);

  const addMoreData = async () => {
    setOffset(offset + 25);
    try {
      const response = await axiosInstance.current.get(
        `extorg/org/listOfOrgs?ak_id=${login.ak_id}&offset=${offset}&limit=${limit}&key=${orgName}&status=${status}&sortBy=${sort}`
      );
      const resdata = response.data.data;
      setTimeout(() => {
        setData(orgData.concat(resdata));
      }, 500);
    } catch (error) {
      console.log("An error occurred while fetching data.");
    }
  };
  const addMoreDataSearch = async () => {
    setOffset(offset + 25);
    try {
      const response = await axiosInstance.current.get(
        `extapp/doctors/global_search?keyword=${searchData}&ak_id=${login.ak_id}&limit=${limit}&offset=${offset}`
      );
      const resdata = response.data.data;
      setTimeout(() => {
        setData(orgData.concat(resdata));
      }, 500);
    } catch (error) {
      console.log("An error occurred while fetching data.");
    }
  };
  const fetchMoreData = () => {
    if (searchEnable === "Y") {
      addMoreDataSearch();
    } else {
      addMoreData();
    }
  };
  const handleInviteModal = () => {
    setShowInviteModal(true);
  };
  const handleInvRequest = () => {
    alert("api code here.");
    setShowInviteModal(false);
  };
  const handleOrgModle = (e) => {
    setOrgModel(e);
  };
  const handleSort = (e) => {
    setSort(e);
  };
  const handleStatus = (e) => {
    setStatus(e);
  };
  const sendMsg = () => {
    // setShow(false);
    if (!subject) {
      subjectRef.current.errUpdate();
    }
    if (!messageChat) {
      messageChatRef.current.errUpdate();
    }

    if (!subject || !messageChat) {
    } else {
      setDisable(true)
      const msg = {
        subject: subject,
        content: messageChat,

      };

      axiosInstance.current
        .post(`extorg/org/connect_us/${login.ak_id}`, msg)
        .then((res) => {
          setMessage("Successfully Sent");
          setMessageType("success");
          setSubject("");
          setMessageChat("");
          setDisable(false)
          handleClose();
        })
        .catch((err) => {
          setMessage("Failed !!");
          setMessageType("error");
          setSubject("");
          setMessageChat("");
          handleClose();
        });
    }
  };



  useEffect(() => {

    if (orgName.length === 0) {
      setOffset(25)
    }
  }, [orgName])


  const [platFormData, setPlatFormData] = useState([]);

  useEffect(() => {
    const getPlatFormData = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/admin/getPlatform`
        );
        // setPlatFormData(response.data.data);
        let data = response.data.data.map((item) => {
          return {
            value: item.plat_id,
            label: item.name
          }
        })
        setPlatFormData(data)
      } catch (error) {
        console.log("An error occurred while fetching data.");
      }
    };
    getPlatFormData();
  }, []);



console.log("platdatatas",platFormData)




  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="org__list mb_100">
            {message && <Message message={message} type={messageType} />}

            <div className="row page_header">
              <div className="col-sm-6 col-md-6">
                {/* <h4 className="ph_title">Home</h4> */}
              </div>
              <div className="col-sm-6 col-md-6 text-end"></div>
            </div>
            <div className={`text-center db_welcome_section`}>
              <img src={logo} className={`App_logo`} alt="logo" />
              <h4 className="text-capitalize">
                Welcome, Dr {login.firstname} {login.lastname}
              </h4>
              <p className="">Let’s have a great day</p>
            </div>
            <div className={`row card_individual_sec`}>
              <div className={`col-sm-6`}>
                <div className={`whitebox text-center whitebox`}>
                  <div className="">
                    <img src={homeIcon} alt="user-logo" style={{ width: '36px' }} />
                    <h4 className="">Create an Organisation</h4>
                    <p className="">
                      Create your organisation and send for review here
                    </p>
                    <Button
                      border=""
                      color=""
                      minHeight="40px"
                      height=""
                      onClick={() => navigate("/create-organization")}
                      radius="20px"
                      width="140px"
                      fontSize="14px"
                      buttonStyle="btn_outline f_600"
                      value="Start"
                    >
                      Start
                    </Button>
                  </div>
                </div>
              </div>
              {/* <div className={`col-sm-6`}>
                <div className={`whitebox text-center whitebox`}>
                  <div className="">
                    <img src={userIcon} alt="user-logo" />
                    <h4 className="">Organization joining requests</h4>
                    <p className="">
                      Approve or reject your organisation requests
                    </p>
                    <Button
                      border=""
                      color=""
                      minHeight="40px"
                      height=""
                      onClick={() => navigate("/org-approve-list")}
                      radius="20px"
                      width="140px"
                      fontSize="14px"
                      buttonStyle="btn_outline f_600"
                      value="View"
                    >
                      View
                    </Button>
                  </div>
                </div>
              </div> */}
              <div className={`col-sm-6`}>
                <div className={`whitebox text-center whitebox`}>
                  <div className="">
                    <img src={contactIcon} alt="user-logo" />
                    <h4 className="">Contact us</h4>
                    <p className="">
                      Contact us if you have any feedback or questions
                    </p>
                    <Button
                      border=""
                      color=""
                      minHeight="40px"
                      height=""
                      onClick={handleInviteModal}
                      radius="20px"
                      width="140px"
                      fontSize="14px"
                      buttonStyle="btn_outline f_600"
                      value="Start"
                    >
                      Contact
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <br />

            <div className="panel mb_30">
              <div className="panel-heading  pannel_pd">
                <h4>Organization Details</h4>
              </div>
              <div className="panel-body p_25">
                <>
                  <div className="adv_search_sec">
                    <h4 className="mb_30">{"Advanced Search"}</h4>
                    {/* <form onSubmit={handleSubmit}> */}

                    <div className="row">
                      <div className="col-sm-6 col-md-3">
                        <InputField
                          label="Organization Name"
                          value={orgName}
                          type="text"
                          name="orgName"
                          placeholder="Organization Name"
                          onChange={advsearchToOrgName}
                        />
                        <i style={{ color: "#3f2783" }}>Search with minimum of three characters</i>
                      </div>

                      <div className="col-sm-6 col-md-3 pt_30">
                        <Button
                          onClick={handleReset}
                          value="Reset"
                          buttonStyle="btn_fill mr_20"
                        />
                      </div>
                    </div>
                    {/* </form> */}
                  </div>
                  <hr className="mt-3" />
                  <div className="row">
                    <div className="col-sm-6 col-md-6">
                      <h4 className="mb-3 ml_25">{"List of Organizations"}</h4>

                      <p className="mb_30 ml_25">
                        {totaldata} {totaldata > 1 ? "Results" : "Result"}
                      </p>
                    </div>
                    <div className="col-sm-6 col-md-6">
                      <SortDropDownAlphabet onChange={handleSort} />
                    </div>
                  </div>
                  {orgData.length > 0 ? (
                    <OrganizationListTable
                      orgData={orgData}
                      updatePagination={fetchMoreData}
                      totaldata={totaldata}
                      platFormData={platFormData}
                    />
                  ) : (
                    <div className="no_data">{translation.No_data_found}</div>
                  )}
                </>
              </div>
            </div>
          </div>
          <Modal
            show={showInviteModal}
            onHide={handleCloseInvite}
            backdrop="static"
            keyboard={false}
            dialogClassName="modal-md patient_notes_popup"
          >
            <Modal.Header closeButton>
              <Modal.Title className="text-center">Contact us</Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
              <InputField
                label="Subject"
                value={subject}
                ref={subjectRef}
                type="text"
                placeholder="Enter subject here"
                styleClass="br_10 mxw_100 mb_20"
                onChange={titleChange}
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                ]}
              />
              <InputField
                label="Message"
                value={messageChat}
                ref={messageChatRef}
                type="textarea"
                placeholder="Enter message note here"
                rows="4"
                cols="20"
                styleClass="br_10"
                onChange={descriptionChange}
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                ]}
              />
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: "flex-start" }}>
              <Button
                onClick={handleClose}
                value="Cancel"
                buttonStyle="btn_nill mr_20"
              />
              <Button
                value="Save"
                width="120px"
                enable={disble}
                buttonStyle="btn_fill ms-4 clk_btn_disbled"
                onClick={sendMsg}
              />
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}

export default Organizations;
