import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { Multiselect } from "multiselect-react-dropdown";
import { useSelector } from "react-redux";
import { MultiSelect } from "react-multi-select-component";
import "react-datepicker/dist/react-datepicker.css";
import InputField from "../custom/inputfield/InputField";
import { Validators, spacialCharReplace } from "../../utilities/Validator";
import Button from "../custom/button/Button";
import Dropdown from "../custom/dropdown/Dropdown";
import useAxios from "../../axiosinstance";
import Message from "../custom/toster/Message";
import Checkbox from "../custom/checkbox/Checkbox";

function EditSharingDetails() {
  const [isLoading, setIsLoading] = useState(true);
  const axiosInstance = useAxios();
  const [groupTitle, setGroupTitle] = useState("");
  const [formVaild, setFormVaild] = useState(false);
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [requried, setRequried] = useState([]);
  const [requried1, setRequried1] = useState([]);

  const [orgapiData, setOrgapiData] = useState([]);
  const [sendingApiData, setSendingApiData] = useState([]);
  const [reciveApiData, setRecieveApiData] = useState([]);
  let sharing_resource_type = []

  const { share_id } = useParams()

  // const [orgapiTargetData, setOrgapiTargetData] = useState([]);
  const [groupTypeData, setGroupTypeData] = useState([
    {
      value: 0,
      label: "Source",
    },
    {
      value: 1,
      label: "Target",
    },
  ]);
  const [groupTypeSelected, setGroupTypeSelected] = useState("");
  const [akouId, setAkouId] = useState("");
  const [sendId, setSendid] = useState("");
  const [recieveId, setRecieve] = useState("");

  const [akouIdTarget, setAkouIdTarget] = useState("");
  const [userRole, setUserRole] = useState("");
  const [assignTo, setAssignTo] = useState(-1);
  const [usersErr, setUsersErr] = useState(false);
  const [resourceErr, setResourceErr] = useState(false);
  const [shareIds, setShareId] = useState("")

  const [assignToData, setAssignToData] = useState([]);
  const [selectedResourceData, setSelectedResourceData] = useState([]);
  const [resourceData, setResourceData] = useState([]);
  const [externalGroup, setExternalGroup] = useState();
  const [resources, setResources] = useState("")
  const[disble,setDisable]=useState(false)

  const [selected, setSelected] = useState([]);
  const orgIdRef = useRef();
  const sendIdRef = useRef();
  const recieveIdRef = useRef();

  const navigate = useNavigate();
  let patient_ids = [];
  let hcp_ids = [];
  let new_patient_ids = [];
  const login = useSelector(
    (state) =>
      state.mainReducer.loginData.arr && state.mainReducer.loginData.arr[0]
  );

  // const handleGroupTitle = (e, err) => {
  //   setGroupTitle(e);
  // };

  const handleContinue = (e) => {
    // setMessage(false);let json;
    e.preventDefault();

    if (!akouId) {
      orgIdRef.current.errUpdate();
    }
    if (!sendId) {
      sendIdRef.current.errUpdate();
    }
    if (!recieveId) {
      recieveIdRef.current.errUpdate();
    }
    if (
      !akouId ||
      !sendId ||
      !recieveId
    ) {

    } else {
      setDisable(true)
      let json;
      json = {
        source_group_id: sendId,
        target_group_id: recieveId,
        sharing_resource_type: sharing_resource_type,
        akou_id:akouId
      }
      console.log("jsonn", json)
      axiosInstance.current
        .put(`extorg/sharing/updateGroupSharing/${login.ak_id}/${share_id}`, json, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          setMessageType("success");
          setMessage(" Shared Data Update successfully");
          setTimeout(() => {
            navigate("/sharing-list");
            setMessage(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err.response.data.message);
          setMessage(err.message);
          setMessageType("error");
        });




    }

  };

  selected.map((ele) => {
    sharing_resource_type.push(ele.value)
  })
console.log("sharing_resource_type",sharing_resource_type)
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const handleRequired = (e) => {
    setRequried(e);
    patient_ids.push([])
    hcp_ids.push([])
  };
  const handleRequired1 = (e) => {
    setRequried1(e);
    new_patient_ids.push([])
    hcp_ids.push([])
  };




  const handleResourceData = (e) => {
    setSelectedResourceData(e);
  };
  const handleOrgModle = (e) => {
    setAkouId(e);
    
  };

  const handleSendingGropSelect = (e) => {
    setSendid(e);
  };

  const handleRecieveGropSelect = (e) => {
    setRecieve(e);
  };


  const handleTargetOrgModle = (e) => {
    setAkouIdTarget(e);
  };
  const handleGroupType = (e) => {
    setGroupTypeSelected(e);
  };
  const handleUserRole = (e) => {
    setUserRole(e);
  };
  const handleAssignTo = (e) => {
    setAssignTo(e);
    patient_ids = []
    hcp_ids = []

  };
  const handleGroupChange = (e) => {
    setExternalGroup(e);
  };



  useEffect(() => {
    const getAllOrgs = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/admin/orgs?ak_id=${login.ak_id}`
        );

        const newData = response.data.data.map((item) => ({
          value: item.id,
          label: item.o_nm,
          plan_id: item.plan_id,
        }));
        setOrgapiData(newData);
      } catch (err) {
        console.log("An error occurred while fetching data.");
      }
    };
    getAllOrgs();
  }, []);


  useEffect(() => {
    const getGropDetails = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/sharing/getGroupSharingDetails/${login.ak_id}/${share_id}?limit=10&offset=0&name=&akou_id=&sort=`
        );
        // resssssssssss = response.data.data[0].sharing_resource_type
        setResources(response.data.data[0].sharing_resource_type)
        setAkouId(response.data.data[0].akou_id)
        setSendid(response.data.data[0].source_group_id)
        setRecieve(response.data.data[0].target_group_id)


      } catch (err) {
        console.log("An error occurred while fetching data.");
      }
    };
    getGropDetails();
  }, []);







  useEffect(() => {
    const getAllSendingGroups = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/group/getIntExtGroups/${login.ak_id}?limit=&offset=&akou_id=${akouId}&int_grp=1&ext_grp=0`
        );

        const newData = response.data.data.map((item) => ({
          value: item.value,
          label: item.label,

        }));
        setSendingApiData(newData);
      } catch (err) {
        console.log("An error occurred while fetching data.");
      }
    };
    if (akouId)
      getAllSendingGroups();
  }, [akouId]);



  useEffect(() => {
    const getAllRecievingGroups = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/group/getIntExtGroups/${login.ak_id}?limit=&offset=&akou_id=${akouId}&int_grp=1&ext_grp=1`
        );

        const newData = response.data.data.map((item) => ({
          value: item.value,
          label: item.label,

        }));
        setRecieveApiData(newData);
      } catch (err) {
        console.log("An error occurred while fetching data.");
      }
    };
    if (akouId)
      getAllRecievingGroups();
  }, [akouId]);



  useEffect(() => {
    const getAllResList = async () => {
      let resAkouId = akouId === "" ? null : akouId;
      try {
        const response = await axiosInstance.current.get(
          `extorg/res_type/getResourceTypeList/${login.ak_id}?akou_id=${resAkouId}`
        );

        const newData = response.data.data.map((item) => (
          {
            value: item.value,
            label: item.label === null || item.label === undefined || item.label === "undefined" || item.label === "null" || item.label === "" ? "Not yet joined" : item.label + ` (${item.value})`,
          }));
        setResourceData(newData);
        
      } catch (err) {
        console.log("An error occurred while fetching data.");
      }
    };
    getAllResList();
  }, [akouId]);

  useEffect(() => {
    const selectedItems = resourceData.filter(item => resources.includes(item.value));
    setSelected(selectedItems);
  }, [resourceData]);




  useEffect(() => {

    // if (Number(userRole) === -1) {
    //   setAssignToData([
    //     { value: -1, label: "All Users" },
    //     { value: 2, label: "Selected Users" },
    //   ]);
    // }
    if (Number(userRole) === 3) {
      setAssignToData([
        { value: -1, label: "All Users" },
        { value: 2, label: "Selected HCPs" },
      ]);
    }
    if (Number(userRole) === 1) {
      setAssignToData([
        { value: -1, label: "All Patients" },
        { value: 2, label: "Selected Patients" },
      ]);
    }
  }, [userRole]);

  const customStrings = {
    selectSomeItems: 'Resource types...',
    allItemsAreSelected: `${selectedResourceData.length} Items selected`,
  };
  const customUserStrings = {
    selectSomeItems: 'Users...',
    allItemsAreSelected: `${requried.length} Users selected`,
  };

  requried.map((ele) => {
    patient_ids.push(ele.value)
    hcp_ids.push(ele.value)
  })

  requried1.map((ele) => {
    new_patient_ids.push(ele.value)
  })



  useEffect(() => {
    if (akouId) {
      setSendid("")
      setRecieve("")
    }
  }, [akouId])

  return (
    <>
      {message && <Message message={message} type={messageType} />}
      <div className="create_group_screen mb_200">
        <div className="row page_header">
          <div className="col-sm-6 col-md-6">
            <h4 className="ph_title">Edit Sharing</h4>
          </div>
          <div className="col-sm-6 col-md-6 text-end"></div>
        </div>

        <div className="panel">
          {message && <Message message={message} type={messageType} />}
          <div className="panel-body p_25 pt_50 pb_50">
            <div className="dc_reg_inner">
              <div className="">
                <div className="row">
                  <div className="col-sm-5 col-md-5 mb_20">
                    <InputField
                      label="Share Id"
                      value={share_id}
                      name="Shareid"
                      type="text"
                      disabled={true}
                      placeholder="Share Id"

                      // ref={groupTitleRef}

                      styleClass="br_10 mxw_285"
                    />
                  </div>
                  <div className="col-sm-5 col-md-5 mb_20">
                    <Dropdown
                      data={orgapiData}
                      label="Select Organization*"
                      value={akouId}
                      onChange={handleOrgModle}
                      placeholder="Select Organization"
                      styleClass="br_10 mxw_285 mb_20"
                      ref={orgIdRef}
                      validators={[
                        {
                          check: Validators.required,
                          message: "This field is required",
                        },
                      ]}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-5 col-md-5 mb_20">
                    <Dropdown
                      data={sendingApiData}
                      label="Sending Groups*"
                      value={sendId}
                      onChange={handleSendingGropSelect}
                      placeholder="Sending Groups"
                      styleClass="br_10 mxw_285 mb_20"
                      ref={sendIdRef}
                      validators={[
                        {
                          check: Validators.required,
                          message: "This field is required",
                        },
                      ]}
                    />
                  </div>
                  <div className="col-sm-5 col-md-5 mb_20">
                    <Dropdown
                      data={reciveApiData}
                      label="Recieving Groups*"
                      value={recieveId}
                      onChange={handleRecieveGropSelect}
                      placeholder="Recieving Groups"
                      styleClass="br_10 mxw_285 mb_20"
                      ref={recieveIdRef}
                      validators={[
                        {
                          check: Validators.required,
                          message: "This field is required",
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-5 col-md-5 mb_20">
                    <label>Share Resource Type*</label>

                    <MultiSelect
                      options={resourceData}
                      value={selected}
                      onChange={setSelected}
                      labelledBy={"Select"}
                      isCreatable={true}
                      displayValue="title"
                      className="multi_select_main"
                    />
                    {resourceErr && (
                      <span className="text-danger">
                        This field is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6"></div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <Button
                      onClick={handleContinue}
                      value="Submit"
                      enable={disble}
                      buttonStyle="btn_fill ms-4 clk_btn_disbled"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditSharingDetails;
