import React from 'react';
import deleteIcon from "../../../images/delete-red.svg";
import { DateFormate } from "../../../utilities/DateFormate";
import "./EditOrg.css"

const SelectedPlans = ({ selectedPlans, onClickDeletePlan, totalHcp, totalPatients }) => {

const handleDelPlan = (planId) =>{
  onClickDeletePlan(planId);
}

  return (
    <div className='selected_plans_table'>
      <div className='row'>
        <div className='col-md-5'>
          <label>Assigned Plans:</label>
        </div>
        <div className='col-md-7 text-end'>
          <span className='pr_20 default_c'><label>Total Hcp: </label> {totalHcp}</span>
          <span className='default_c'><label>Total Patients: </label> {totalPatients}</span>
        </div>
      </div>
      
      <table>
      <thead>
        <tr>
            <th>S No</th>
            <th>Plan Name</th>
            <th>Expiry Date</th>
            <th>Start Date</th>
            <th>Action</th>
        </tr>
        </thead>
        <tbody>
        {selectedPlans.map((plan, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{plan.plan_name}</td>
            <td>{DateFormate(plan.expiry_date)}</td>
            <td>{DateFormate(plan.start_date)}</td>
            <td><img className="ml_15" src={deleteIcon} alt="icon" onClick={() => handleDelPlan(plan.id)} /></td>
          </tr>
          
        ))}
        </tbody>
    </table>
    </div>
  );
};

export default SelectedPlans;