import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTable } from "react-table";
import TableSpinner from "../../custom/spinner/TableSpinner";
import TableActionItems from "../../custom/table-action-items/TableActionItems";
import InfiniteScroll from "react-infinite-scroll-component";
function OrganizationUserPatientTable({ usersData, updatePagination ,totalData}) {
  const loginType = useSelector(
    (state) =>
      state.mainReducer.loginData.arr && state.mainReducer.loginData.arr[0]
  );
  // const login = useSelector((state) => state.mainReducer.loginData.arr);
  //   const [doctorID, setDoctorID] = useState(login.ing_ak_id);

  const actionData = {
    view_url: "patient-view",
    edit_url: "edit-user-details",
    delete_url: "",
  };
  //   function handleEdit(row) {
  //     console.log(row);
  //   }
  const data = usersData;

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        id: "1",
        headerClassName: "col-img",
        className: "col-img",
        accessor: "firstname",
        Cell: (row) => (
          <span>
            <label className="user-letter small">
              {row.value && row.value.trim() !== '' ? row.value.charAt(0).toUpperCase() : 'A'}
            </label>
          </span>
        ),
      },
      {
        Header: "User ID",
        accessor: "ak_id",
      },
      {
        Header: "User Name",
        accessor: "hcpName",
        Cell: (row) => {
          const { firstname, lastname } = row.row.original;

          let fullName = "Not Yet Joined";

          if (
            (firstname || lastname) &&
            firstname !== null && lastname !== null &&
            firstname !== undefined && lastname !== undefined
          ) {
            fullName = `${firstname || ""} ${lastname || ""}`.trim();
          }

          return <span>{fullName}</span>;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        className: "text-lowercase",
       },
      // {
      //   Header: "Platform",
      //   accessor: "platform",
        
      //   Cell: ( row ) => (
      //     <>
      //       {
      //         row.row.original.platform.length === 1 ? (
      //           <>
      //           {
      //             row.row.original.platform[0]===1 ? <span>CLINICIAN</span>:<span>MED</span>
      //           }
      //           </>
      //         ):(
      //           <span>CLINICIAN , MED</span>
      //         )
      //       }
      //     </>
      //   ),
      // },
      {
        Header: "Account Status",
        accessor: "account_status",
        headerClassName: "col-acstatus",
        className: "col-acstatus",
        Cell: ( row ) => (
          <>
            {row.row.original.account_status === 1 ? (
              <span>Active</span>
            ) : (
              <span>InActive</span>
            )}
          </>
        ),
      },
      // {
      //   Header: "Action",
      //   headerClassName: "col-action",
      //   accessor: "action",
      //   className: "col-action",
      //   Cell: (row) => (
      //     <span>
      //       <TableActionItems
      //         viewAction="false"
      //         editAction="true"
      //         feedbackAction="false"
      //         deleteAction={loginType.type === 2 ? false : true}
      //         arrowAction="false"
      //         rowName={row.row.original.ak_id}
      //         rowId={row.row.original.ak_id}
      //         rowData={actionData}
      //         rowType="usersList"
      //         notetitle={row.row.original.ea_name}
      //         rowOrgId={row.row.original.ea_akpt_id}
      //         orgId={row.row.original.ea_org_id}
      //       />
      //     </span>
      //   ),
      // },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });
  return (
    <>
      <div className="tableContainer" id="rpScrollableDiv">
        <InfiniteScroll
          dataLength={rows.length}
          next={updatePagination}
          hasMore={true}
          scrollableTarget="rpScrollableDiv"
          // loader={<TableSpinner />}
          loader={ usersData.length >= 25 && usersData.length !== totalData  ? <TableSpinner /> : null}

        >
          <table {...getTableProps()} className="custom-tbl">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps([
                        { className: column.headerClassName },
                      ])}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps([
                            { className: cell.column.className },
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </>
  );
}

export default OrganizationUserPatientTable;
