import React from "react";
import TagsList from "./TagsList";

function Tags() {
  return (
    <>
      <div>
        <TagsList />
      </div>
    </>
  );
}

export default Tags;
