import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../custom/button/Button";
import InputField from "../custom/inputfield/InputField";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import useAxios from "../../axiosinstance";
import { useSearchParams } from "react-router-dom";
import PatientsTable from "./PatientsTable";
import "./Patients.css";

function Patients() {
  const navigate = useNavigate();
  const axiosInstance = useAxios();
  const [searchParams, setSearchParams] = useSearchParams();
  let searchData = searchParams.get("q");
  const [qData, setqData] = useState("");
  // console.log('search data ', qData)
  let searchType = searchParams.get("search");

  const [isLoading, setIsLoading] = useState(true);
  // const login = useSelector((state) => state.mainReducer.loginData.arr);
  const login = "";
  const dataRefe = "";
  // const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
  const [patientsData, setData] = useState([
    {
      patientId: "AKPT000001",
      patientName: "Patient1",
      email: "test@Patient1.com",
    },
    {
      patientId: "AKPT000002",
      patientName: "Patient2",
      email: "test1@gmail.com",
    },
    {
      patientId: "AKPT000003",
      patientName: "Patient3",
      email: "test@Patient3.com",
    },
  ]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  // const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  // const [status, setStatus] = useState("");
  const [searchEnable, setsearchEnable] = useState("N");
  const [startDate, setStartDate] = useState("");
  // const [startDate, setStartDate] = useState(new Date());

  const [offset, setOffset] = useState(10);
  const [limit, setLimit] = useState(10);
  const [sortType, setSortType] = useState("last_created");
  const [totaldata, setTotaldata] = useState("");

  useEffect(() => {
    setqData(searchData);
    setsearchEnable(searchType);
  }, [searchData, searchType]);

  const removeQueryParams = () => {
    const param = searchParams.get("q");

    if (param) {
      // 👇️ delete each query param
      searchParams.delete("q");
      searchParams.delete("search");

      // 👇️ update state after
      setSearchParams(searchParams);
    }
  };

  const handleReset = (e) => {
    setFirstName("");
    setLastName("");
    setStartDate("");
    setEmail("");
    if (searchType === "Y") {
      setsearchEnable("Y");
    }
  };
  const advsearchToFirstName = (event) => {
    setFirstName(event);
    removeQueryParams();
    if (event === "") {
      // setsearchEnable("Y");
    } else {
      setsearchEnable("N");
    }
    setOffset(10);
  };
  const inviteEmailChange = (e) => {
    console.log("test", e);
  };
  const advsearchToMail = (event) => {
    setEmail(event);
    if (event === "") {
      // setsearchEnable("Y");
    } else {
      setsearchEnable("N");
    }
    setOffset(10);
  };
  const handleDob = (event) => {
    if (event === "") {
      // setsearchEnable("Y");
    } else {
      setsearchEnable("N");
    }
    setOffset(10);
    setStartDate(event);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    const getData = async () => {
      const response = await axiosInstance.current.get(
        `extapp/doctors/getAllPatient/${login.ing_ak_id}?offset=0&firstname=${firstName}&lastname=${lastName}&dob=${startDate}&email=${email}&sortType=${sortType}&limit=${limit}`
      );
      setData(response.data.data);
      setTotaldata(response.data.total);
    };
    if (searchEnable !== "Y") {
      getData();
    }
  }, [firstName, lastName, email, sortType, startDate, searchEnable, dataRefe]);

  useEffect(() => {
    const getDataSearch = async () => {
      const response = await axiosInstance.current.get(
        `extapp/doctors/global_search?keyword=${qData}&ak_id=${login.ing_ak_id}&limit=${limit}&offset=0`
      );
      setData(response.data.data);
      setTotaldata(response.data.total);
    };
    if (searchEnable === "Y") {
      setTimeout(() => {
        getDataSearch();
      }, 1000);
    }
  }, [qData, searchEnable]);

  const addMoreData = async () => {
    setOffset(offset + 10);
    const response = await axiosInstance.current.get(
      `extapp/doctors/getAllPatient/${login.ing_ak_id}?offset=${offset}&firstname=${firstName}&lastname=${lastName}&dob=${startDate}&email=${email}&sortType=${sortType}&limit=${limit}`
    );
    const resdata = response.data.data;
    setTimeout(() => {
      setData(patientsData.concat(resdata));
    }, 500);
  };
  const addMoreDataSearch = async () => {
    setOffset(offset + 10);
    const response = await axiosInstance.current.get(
      `extapp/doctors/global_search?keyword=${searchData}&ak_id=${login.ing_ak_id}&limit=${limit}&offset=${offset}`
    );
    const resdata = response.data.data;
    setTimeout(() => {
      setData(patientsData.concat(resdata));
    }, 500);
  };
  const fetchMoreData = () => {
    if (searchEnable === "Y") {
      addMoreDataSearch();
    } else {
      addMoreData();
    }
  };
  const handleSort = (e) => {
    setSortType(e);
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="patients__list mb_100">
            <div className="panel mb_30">
              <div className="panel-heading  pannel_pd">
                <div className="row">
                  <div className="col-sm-6 col-md-6 pt_30">
                    <h4>Patients Details</h4>
                  </div>
                  <div className="col-sm-6 col-md-6 pt_30 text-right">
                    <Button
                      onClick={() => navigate("/create-patient")}
                      value="+ Add Patient"
                      buttonStyle="btn_fill w_160"
                    />
                  </div>
                </div>
              </div>
              <div className="panel-body p_25">
                <>
                  <div className="adv_search_sec">
                    <h4 className="mb_30">{"Advanced Search"}</h4>
                    {/* <form onSubmit={handleSubmit}> */}

                    <div className="row">
                      <div className="col-sm-6 col-md-3">
                        <InputField
                          label="Patient Name"
                          value={firstName}
                          type="text"
                          name="firstName"
                          placeholder="Patient Name"
                          onChange={advsearchToFirstName}
                        />
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <InputField
                          label="Email"
                          value={email}
                          type="text"
                          placeholder="Email Address"
                          onChange={(e) => advsearchToMail(e)}
                        />
                      </div>
                      <div className="col-sm-6 col-md-3 pt_30">
                        <Button
                          onClick={handleReset}
                          value="Reset"
                          buttonStyle="btn_fill mr_20"
                        />
                      </div>
                    </div>
                    {/* </form> */}
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6 col-md-6">
                      <h4 className="mb-3 ml_25">{"List of Patients"}</h4>

                      <p className="mb_30 ml_25">
                        {totaldata} {totaldata > 1 ? "Results" : "Result"}
                      </p>
                    </div>
                    <div className="col-sm-6 col-md-6">
                      {/* <SortDropdown onChange={handleSort} alp={true} /> */}
                    </div>
                  </div>
                  {patientsData.length > 0 ? (
                    <PatientsTable
                      patientsData={patientsData}
                      updatePagination={fetchMoreData}
                    />
                  ) : (
                    <div className="no_data">No Data Found</div>
                  )}
                </>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Patients;
