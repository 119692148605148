import {
  UPDATE_ADULT_KNEE_DATA,
  UPDATE_ADULT_KNEE_PAIN_DATA,
  UPDATE_ADULT_KNEE_PAIN_FIELD_DATA,
  UPDATE_ADULT_KNEE_SYMPTOMS_DATA_SINGLE,
  UPDATE_ADULT_KNEE_SYMPTOMS_DATA,
  UPDATE_ADULT_KNEE_FUNCTION_FIELD_DATA,
  UPDATE_ADULT_KNEE_FUNCTION_DATA,
  UPDATE_ADULT_KNEE_QOL_FIELD_DATA,
  UPDATE_ADULT_KNEE_QOL_DATA,
  UPDATE_ADULT_KNEE_SATISFACTION_FIELD_DATA,
  UPDATE_ADULT_KNEE_SATISFACTION_DATA,
  UPDATE_ADULT_KNEE_TOTAL_SCORES,
} from "../actions/actionTypes";

const initialState = {
  painScore: [
    {
      describePainR: "0",
      describePainL: "0",
      painTodayRatingR: "0",
      painTodayRatingL: "0",
      bestStatmentForPainR: "0",
      bestStatmentForPainL: "0",
      howSevereR: "0",
      howSevereL: "0",
      kneeInterferedR: "0",
      kneeInterferedL: "0",
      twistingR: "0",
      twistingL: "0",
      straighteningR: "0",
      straighteningL: "0",
      bendingR: "0",
      bendingL: "0",
      walkingSurfaceR: "0",
      walkingSurfaceL: "0",
      howLongWalkR: "0",
      howLongWalkL: "0",
      useStepsR: "0",
      useStepsL: "0",
      whileBedR: "0",
      whileBedL: "0",
      painAtNightR: "0",
      painAtNightL: "0",
      sittingR: "0",
      sittingL: "0",
      standingR: "0",
      standingL: "0",
      standingFromChairR: "0",
      standingFromChairL: "0",
    },
  ],
  symptomsScore: [
    {
      swellingR: "0",
      swellingL: "0",
      grindingR: "0",
      grindingL: "0",
      catchingR: "0",
      catchingL: "0",
      limpingR: "0",
      limpingL: "0",
      significantSwellingR: "0",
      significantSwellingL: "0",
      awakeningR: "0",
      awakeningL: "0",
      restingLaterR: "0",
      restingLaterL: "0",
      instabilityR: "0",
      instabilityL: "0",
      supportR: "0",
      supportL: "0",
    },
  ],
  functionScore: [
    {
      goUpStairsR: "0",
      goUpStairsL: "0",
      goDownStairsR: "0",
      goDownStairsL: "0",
      kneelFrontR: "0",
      kneelFrontL: "0",
      straighteningR: "0",
      straighteningL: "0",
      bendingKneeR: "0",
      bendingKneeL: "0",
      squatR: "0",
      squatL: "0",
      sitKneeBentR: "0",
      sitKneeBentL: "0",
      riseFromChairR: "0",
      riseFromChairL: "0",
      riseFromBedR: "0",
      riseFromBedL: "0",
      lyingInBedR: "0",
      lyingInBedL: "0",
      bendingToFloorR: "0",
      bendingToFloorL: "0",
      puttingOnSocksR: "0",
      puttingOnSocksL: "0",
      takingOffSocksR: "0",
      takingOffSocksL: "0",
      standingR: "0",
      standingL: "0",
      walkingR: "0",
      walkingL: "0",
      runStraightAheadR: "0",
      runStraightAheadL: "0",
      jumpAndLandR: "0",
      jumpAndLandL: "0",
      stopAndStartR: "0",
      stopAndStartL: "0",
      twistingR: "0",
      twistingL: "0",
      publicTransportR: "0",
      publicTransportL: "0",
      gettingBathR: "0",
      gettingBathL: "0",
      gettingToiletR: "0",
      gettingToiletL: "0",
      washingR: "0",
      washingL: "0",
      shoppingR: "0",
      shoppingL: "0",
      heavyDutiesR: "0",
      heavyDutiesL: "0",
      lightDutiesR: "0",
      lightDutiesL: "0",
      rateKneeFunctionR: "0",
      rateKneeFunctionL: "0",
      activityLevelR: "0",
      activityLevelL: "0",
      highestActivity: "0",
      patellarInstability: "0"
    },
  ],
  qolScore: [
    {
      howOftenR: "0",
      howOftenL: "0",
      lifestyleR: "0",
      lifestyleL: "0",
      confidenceR: "0",
      confidenceL: "0",
      difficultyR: "0",
      difficultyL: "0",
      anxious: "0",
      howGood: "0",
    },
  ],
  satisfactionScore: [
    {
      overallR: "0",
      overallL: "0",
      improvingPainR: "0",
      improvingPainL: "0",
      improvingHomeR: "0",
      improvingHomeL: "0",
      improvingActivitiesR: "0",
      improvingActivitiesL: "0",
    },
  ],
  TotalScores: [
    {
      ikdcScoreR: 0,
      ikdcScoreL: 0,
      koosPainScoreR: 0,
      koosPainScoreL: 0,
      koosSymptomsScoreR: 0,
      koosSymptomsScoreL: 0,
      koosAdlScoreR: 0,
      koosAdlScoreL: 0,
      koosSportsScoreR: 0,
      koosSportsScoreL: 0,
      qolScoreR: 0,
      qolScoreL: 0,
      ckrsScoreR: 0,
      ckrsScoreL: 0,
      lysholmScoreR: 0,
      lysholmScoreL: 0,
      oksPainScoreR: 0,
      oksPainScoreL: 0,
      oksFuncScoreR: 0,
      oksFuncScoreL: 0,
      womacPainScoreR: 0,
      womacPainScoreL: 0,
      womacStiffScoreR: 0,
      womacStiffScoreL: 0,
      womacFuncScoreR: 0,
      womacFuncScoreL: 0,
      kssScoreR: 0,
      kssScoreL: 0,
    },
  ],
  evlAdultKnee: "1",
};

const adultKneeScoreReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ADULT_KNEE_DATA:
      return { ...state, evlAdultKnee: action.data };
    case UPDATE_ADULT_KNEE_PAIN_FIELD_DATA:
      const painFieldVal = action.data.value;
      const painFieldKey = action.data.key;
      const painNewArr = state.painScore.map((object) => {
        return { ...object, [painFieldKey]: painFieldVal };
      });
      return { ...state, painScore: painNewArr };
    case UPDATE_ADULT_KNEE_PAIN_DATA:
      return { ...state, painScore: action.data };
    case UPDATE_ADULT_KNEE_SYMPTOMS_DATA_SINGLE:
      const fieldVal = action.data.value;
      const fieldKey = action.data.key;
      const newArr = state.symptomsScore.map((object) => {
        return { ...object, [fieldKey]: fieldVal };
      });
      return { ...state, symptomsScore: newArr };
    case UPDATE_ADULT_KNEE_SYMPTOMS_DATA:
      return { ...state, symptomsScore: action.data };
    case UPDATE_ADULT_KNEE_FUNCTION_FIELD_DATA:
      const fieldValF = action.data.value;
      const fieldKeyF = action.data.key;
      const newArrF = state.functionScore.map((object) => {
        return { ...object, [fieldKeyF]: fieldValF };
      });
      return { ...state, functionScore: newArrF };
    case UPDATE_ADULT_KNEE_FUNCTION_DATA:
      return { ...state, functionScore: action.data };
    case UPDATE_ADULT_KNEE_QOL_FIELD_DATA:
      const qolVal = action.data.value;
      const qolKey = action.data.key;
      const qolNewArr = state.qolScore.map((object) => {
        return { ...object, [qolKey]: qolVal };
      });
      return { ...state, qolScore: qolNewArr };
    case UPDATE_ADULT_KNEE_QOL_DATA:
      return { ...state, qolScore: action.data };
    case UPDATE_ADULT_KNEE_SATISFACTION_FIELD_DATA:
      const satVal = action.data.value;
      const satKey = action.data.key;
      const satNewArr = state.satisfactionScore.map((object) => {
        return { ...object, [satKey]: satVal };
      });
      return { ...state, satisfactionScore: satNewArr };
    case UPDATE_ADULT_KNEE_SATISFACTION_DATA:
      return { ...state, satisfactionScore: action.data };
    case UPDATE_ADULT_KNEE_TOTAL_SCORES:
      return { ...state, TotalScores: action.data };
    default:
      return state;
  }
};

export default adultKneeScoreReducer;
