export default {
  Next: "Next",
  Back: "Back",
  Continue: "Continue",
  myProfile: "My Profile",
  visitClinic: "Visit Clinic",
  myVisit: "Past Visits",
  doctorOrganization: "Doctor Organization",
  areyousurewanttocontinueyourform: "Your previous form is pending. Do you want to continue ?",
  no: "No",
  yes: "Yes",
  my_profile: "My Profiles",
  List_of_my_profile: "List of my profile",
  upcoming_visits: "Upcoming",
  No_data_found: "No data found",
  Radiology: "Radiology",
  Pathology: "Pathology",
  akmed: "Akunah Med",
  settings: "Settings",
  AppVersion: "App Version",
  language: "Language & Region",
  timezone: "Timezone",
  Timeformat: "Time format",
  Organization: "Organization*",
  Select_Doctor: "Select Doctor*",
  Select_Treatment_Category: "Select Treatment Category*",
  Doctor_Name: "Doctor Name",
  Appointment_Date_Time: " Appointment Date & Time",
  Clinic_Name: "Clinic Name",
  Clinic_Address: "Clinic Address",
  View_score: "View score",
  First_Name: "First Name*",
  Last_Name: "Last Name*",
  DOB: "Date of Birth*",
  Sex: "Sex*",
  Address: "Address*",
  Postcode: "Postcode*",
  Suburb: "Suburb*",
  Country_Code: "Country Code*",
  Mobile_Phone: "Mobile Phone*",
  Home_Phone: "Home Phone",
  Email: "Email*",
  Emergency_Contact: "Emergency Contact*",
  Phone: "Phone*",
  Relationship: "Relationship*",
  Regular_GP_Name: "Regular GP Name*",
  Practice_Name: "Practice Name*",
  Medicare_Card_Number: "Medicare Card Number",
  Ref: "Ref",
  Message: "Message",
  Expiry: "Expiry",
  Private_Health_Fund: "Private Health Fund",
  Membership_Number: "Membership Number",
  Claim_Type: "Claim Type",
  Work_Cover: "Work Cover",
  Employer: "Employer",
  Third_Party_Claim_Number: "Third Party Claim Number",
  Third_Party_Name: "Third Party Name",
  DVA_Concession_Card: "DVA Concession Card",
  Number: "Number",
  Defence_Personnel: "Defence Personnel",
  I_agree: "I agree to the processing of my personal data and have read the ",
  Privacy_Policy: "Privacy Policy.",
  Select_Existing_Profile: "Select Existing Profile",
  Get_In: "Enter",
  Create_new_patient: "Create a new patient",
  Complete_personal_info: "Complete personal info",
  To_complete_the_sign_up: "To complete the sign up please fill in your info below.",
  All_Tags: "All Tags",
  Platform: "Platform",
  No_Data_Found: "No Data Found",
  Add_Tag: "Add Tag",
  tag_name: "Enter tag here",
  Tags: "Tags",
  Order: "Order",
  order_name: "Enter order here",
  Cancel: "Cancel",
  Save: "Save",
  Select: "Select",
  add_tag: "+ Add Tag",
  Edit_Tag: "Edit Tag",
  Update: "Update",
  Tag_Updated_message: "Tag Updated",
  Are_you_sure_you_want_to_delete: "Are you sure you want to delete",
  Delete: "Delete",
  tag_delete_message: "Tag Deleted",
  tag_not_delete: "Tag Not Delete",
  s_no: "S No",
  title: "Title",
  status: "Status",
  action: "Action",
  Plans_List: "Plans List",
  create_plan_btn: "+ Create Plan",
  No_Data_Found: "No Data Found",
  Add_Plan: "Add Plan",
  Name: "Name",
  Enter_Plan_here: "Enter Plan here",
  Maximum_Number_Of_HCP: "Maximum Number Of HCP",
  Enter_Number: "Enter Number",
  Maximum_Number_Of_Patient: "Maximum Number Of Patient",
  duration: "Duration (in days)",
  add_days: "Please add in days",
  limit: "Limit (in GB)",
  add_limit: "Please add limit in GB",
  missing_name: "* Name is missing",
  missing_order: "Order is missing",
  missing_hcp: "* HCP is missing",
  patient_missing: "* Patient is missing",
  duration_missing: "* Duration is missing",
  limit_missing1: "*Limit is missing",
  limit_missing2: "*Limit should be greater than 1GB",
  s_no: "S.no",
  plan_name: "Plan Name",
  no_of_hcp: "No Of HCP",
  no_of_patient: "No Of Patient",
  duration1: "Duration",
  limit1: "Limit",
  status_update: "Status Update",
  status_not_update: "Status not update",
  plan_delete_message: "Plan Deleted",
  plan_not_delete: "Plan Not Delete",
  channel_delete_message: "Channel Deleted",
  channel_not_delete: "Channel Not Delete",
  video_delete_message: "Video Deleted",
  video_not_delete: "Video Not Delete",
  edit: "Edit",
  delete: "Delete",
  active: "Active",
  deactive: "Inactive",
  channel_details: "Channels Details",
  advance_search: "Advanced Search",
  channel_name: "Channel Name",
  org_name: "Organization Name",
  reset: "Reset",
  list_of_channels: "List of Channels",
  results: " Results",
  user_type: "User Type",
  select_org: "Select Organization",
  enter_channel_here: "Enter channel here",
  field_reqrd: "This field is required",
  visble_to: "Visible To",
  select_role: "Select Role",
  assign_to: "Assigned To",
  select: "Select",
  users: "Users",
  user_name: "Users Name",
  add_channel: "Add Channel",
  Are_you_sure_you_want_to_delete: "Are you sure you want to delete ?",
  edit_channel: "Edit Channel",
  edit_plan: "Edit Plan",
  video_list: "Videos List",
  type: "Type",
  posted_by: "Posted By",
  channels: "Channels",
  enter_title: "Enter title",
  list_of_vidoes: "List of Videos",
  create_an_event: "+ Create an event",
  upload_videos: "+ Upload Videos",
  id: "Id",
  description: "Description",
  publish: "Publish",
  video_size: "Video Size",
  enter_event_title: "Enter event title here",
  enter_description_here: "Enter description here",
  thumbnail1: "Thumbnail image (image-size 720X405)",
  thumbnail2: "Thumbnail image (image-size 480X270)",
  loading: "Loading...",
  event_link: "Event Link",
  Add_video_link_here: "Add event link here",
  visible_till: "Visible till",
  assign_channels: "Assign Channels",
  submit: "Submit",
  video: "Video",
  enter_video_title: "Enter video title here",
  uplaoding: "Uploading...",
  edit_video: "Edit Video",
  edit_event: "Edit Event",
  Home: "Home",
  Let_have_a_great_day: "Let’s have a great day",
  craete_org: "Create Organisation",
  craete_org1: "Create your organisation and send for review here",
  join_org: "Join an Organization",
  join_org1: "Approve or reject your organisation requests",
  start: "Start",
  view: "View",
  contact_us: "Contact us",
  contact_us1: "Contact us if you have any feedback or questions",
  org_details: "Organization Details",
  list_of_org: "List of Organizations",
  org_id: "Organization ID",
  speciality_name: "Specility Name",
  enter_address: "Enter Address",
  city: "*City/Subrub",
  state: "*State",
  invalid_character: "Invalid character",
  Fields_only_contain_10_character: "Fields only contain 10 character",
  phone: "*Phone",
  org_features: "Accessible features *",
  speciality: "Treatment specialities *",
  go_to_paln: "Go to Plan",
  assign_plan: "Assign a Plan*",
  select_plan: "Select Plan",
  post_code: "*Post Code",
  approval_list: "Approval List",
  user_name: "User Name",
  approve: "Approve",
  reject: "Reject",
  subj: "Subject",
  enter_subj_here: "Enter Subject Here",
  messge: "Message",
  enter_message_here: "Enter Message Here",
  expiry_date: "Expiry Date",
  start_date: "Start Date",
  Invite_an_Organization_Admin: "Invite an Organization Admin",
  Admin_User_Email: "Admin User Email*",
  Enter_email_here: "Enter email here",
  add_email: "+Add Email",
  Invite_an_HCP: "Invite an HCP",
  Invite_an_Patient: "Invite an Patient",
  sort: "Sort",
  ascending: "Ascending",
  descending: "Dscending",
  All_Resource_Types: "All Resource Types",
  Add_Resource_Type: "+ Add Resource Type",
  Edit_Resource_Type: "Edit Resource Type",
  key_name: "Key Name*",
  Enter_name_here: "Enter name here",
  doc_type: "Document Type*",
  document: "Document",
  non_document: "Non Document",
  resource_type_add_message: "Resource type added successfully",
  Organization_successfully_created: "Organization successfully created",
  User_Details: "User Details",
  list_of_users: "List of Users",
  user_id: "User ID",
  account_status: "Account Status",
  add_user: "+Add User",
  dob: "DOB*",
  sex: "Sex *",
  male: "Male",
  Female: "Female",
  do_not_disclose: "Do not want to disclose",
  patient_added: "Patient Added",
  patient_not_added: "Patient Not Added",
  import_csv: "Import .csv file",
  browser_file: "Browser File",
  User_created_successfully: "User created successfully",
  edit_user: "Edit User",
  user_updated: "User Updated",
  group_list: "Groups List",
  crate_grp: "+ Create Group",
  list_of_groups: "List of Groups",
  created_by: "Created By",
  group_name: "Group Name",
  Last_Created: "Last Created",
  Alphabetically: "Alphabetically",
  Last_Modified: "Last Modified",
  Frequently_used: "Frequently used",
  Last_modified: "Last modified",
  target_user_role: "Target user role*",
  share_resource_type: "Share Resource Type*",
  assign_to: "Assign To*",
  All_Users:"All Users",
  Selected_Users:"Selected Users",
  Any_User:"Any User",
  Only_HCP:"Only HCP",
  Only_Patient:"Only Patient",
  grp_create_message:"Group created successfully",
  grp_update_message:"Group updated successfully",
  Organizations:"Organizations",
  Resource_Types:"Resource Types",
  Groups:"Groups",
  Plans:"Plans",
  video_events:"Videos / Events",
  Akunah_Organization:"Akunah Organization",
  Please_select_at_least_one_option:"Please select at least one option",
  Rejected_Successfully:"Rejected Successfully",
  Approved:"Approved",
  Approve:"Approve",
  Reject:"Reject",
  Successfully_Sent:"Successfully Sent",
  Organization_Updated:"Organization Updated",
  Invite_organization_admin_body:"You are invited as a Org admin",
  Invite_organization_doctor_body:"You are invited as a Clinician",
  Invite_organization_patient_body:"You are invited as a Patient",
  Invite_temp_admin_sub_line:"Akunah Invitation - Organization Admin Account",
  Invite_temp_hcp_sub_line:"Akunah Invitation - HCP (Healthcare Professional) Account",
  Invite_temp_patient_sub_line:"Akunah Invitation ",
  Invite_temp_dear_line:"Dear Valued Akunah Member,",
  Invite_temp_org_congratulation_line1:"Congratulations! You have been designated as an Organization Admin for",
  Invite_temp_hcp_congratulation_line1:"Congratulations! You have been designated as a HCP apart of",
  Invite_temp_patient_congratulation_line1:"Congratulations! You have been added as a patient apart of",
  Invite_temp_congratulation_line2:"s Organization.",
  Invite_temp_here_line:"Here's how to login:",
  Invite_temp_signin_title_line:"Sign In:",
  Invite_temp_org_signin_line1:"Please access the Organization Platform at",
  Invite_temp_hcp_signin_line1:"Please access the Clinician Platform at",
  Invite_temp_patient_signin_line1:"Please access the Patient Platform at",
  Invite_temp_signin_line2:"Sign in using your current account details. Make sure to use the email address to which this invitation was sent.",
  Invite_temp_signup_title:"Register :",
  Invite_temp_signup_line1:"Haven't registered for an Akunah account yet?",
  Invite_temp_signup_line21:"Simply visit",
  Invite_temp_signup_line22:"and click on the 'Register' button.",
  Invite_temp_signup_line3:"Follow the straightforward sign-up process. Make sure to use the email address to which this invitation was sent.",
  Invite_temp_org_signup_line4:"Once your registration is complete, you will automatically gain access to your new roles as an Organization Administrator.",
  Invite_temp_hcp_signup_line4:"Once your registration is complete, you will automatically be a part of this organization.",
  Invite_temp_patient_signup_line4:"Once your registration is complete, you will be able to 'Check In' to this doctor's organization.",
  Invite_temp_experience_line:"If you experience any challengers while signing in or signing up, our Akunah support team is available to assist you. Feel free to reach out to them by sending an email to",
  Invite_temp_thrilled_line:"We are thrilled to have you a part of the Akunah team!",
  Invite_temp_regards_line:"Warm regards,",
  Invite_temp_team_line:"Akunah Team",

  Helpdesk_contactus_body:"Help desk request",
}; 