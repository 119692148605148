import React, { useState, useSelector } from "react";
import { useTable } from "react-table";
import TableSpinner from "../custom/spinner/TableSpinner";
import TableActionItems from "../custom/table-action-items/TableActionItems";
import InfiniteScroll from "react-infinite-scroll-component";
import Switch from "../custom/switch/Switch";
function ResourceTypeListTable({ resourceData, updatePagination,totalData }) {
  // const login = useSelector((state) => state.mainReducer.loginData.arr);
  //   const [doctorID, setDoctorID] = useState(login.ing_ak_id);
  const [isChecked, setIsChecked] = useState(false);
  const actionData = {
    view_url: "patient-view",
    edit_url: "edit-user-details",
    delete_url: "",
  };
  //   function handleEdit(row) {
  //     console.log(row);
  //   }
  const data = resourceData;

  const columns = React.useMemo(
    () => [
      {
        Header: "S.no",
        accessor: "label",
        headerClassName: "col-sno",
        className: "col-sno",
      },
      {
        Header: "Title",
        accessor: "name",
        headerClassName: "col-title",
        className: "col-title",
      },
      {
        Header: "Action",
        headerClassName: "col-action",
        accessor: "action",
        className: "col-action",
        Cell: (row) => (
          
          <span>
            {
              row.row.original.edit ? (
                <TableActionItems
                viewAction="false"
                editAction="false"
                editActionPopup="true"
                feedbackAction="false"
                deleteAction="false"
                arrowAction="false"
                rowName={"resourceId"}
                rowId={row.row.original.label}
                rowData={actionData}
                rowType="resourceType"
                notetitle={row.row.original.ea_name}
                rowOrgId={row.row.original.ea_akpt_id}
                orgId={row.row.original.ea_org_id}
              />
              ):"System Default"
            }
        
          </span>
        ),
      },
    ],
    []
  );

  const handleSwitchChange = () => {
    setIsChecked(!isChecked);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });
  return (
    <>
      <div className="tableContainer" id="rpScrollableDiv">
        <InfiniteScroll
          dataLength={rows.length}
          next={updatePagination}
          hasMore={true}
          scrollableTarget="rpScrollableDiv"
          // loader={<TableSpinner />}
          loader={ resourceData.length >= 25 && resourceData.length !== totalData  ? <TableSpinner /> : null}

        >
          <table {...getTableProps()} className="custom-tbl">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps([
                        { className: column.headerClassName },
                      ])}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps([
                            { className: cell.column.className },
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </>
  );
}

export default ResourceTypeListTable;
