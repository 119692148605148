import { API_MESSAGE } from "../actions/actionTypes";

const initialState = {
    arrMessage: false,
  };
  
  const apiMessageReducer = (state = initialState, action) => {
    switch (action.type) {
      case API_MESSAGE:
        return { ...state, arrMessage: action.data };
  
      default:
        return state;
    }
  };
  
  export default apiMessageReducer;