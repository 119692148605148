import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { useSelector, useDispatch } from "react-redux";
import useTranslation from "../../customHooks/translations";
import { noteRefs } from "../../../redux/actions";
import useAxios from "../../../axiosinstance";
import Message from "../../custom/toster/Message";

const SelectPlatForm = ({ platFormData, editPlatformList, org_id, ak_id, doctor_id, email, orgName, platform_id }) => {

  console.log("organization_name4", orgName)

  const [selectedOption, setSelectedOption] = useState([]);
  const [arr2, setArr2] = useState([]);
  const [totalData, setTotalData] = useState([])
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [error, setError] = useState(false)
  const translation = useTranslation();
  const dispatch = useDispatch()
  const axiosInstance = useAxios();

  const [arr, setArr] = useState(
    platFormData.map((option) => ({ ...option, status: 0 }))
  );

  console.log("platFormDataplatFormDataccxcc",)
  console.log("arr333333333333333", arr)

  useEffect(() => {
    // Set totalData based on platform_id
    const updatedTotalData = platform_id.map((id) => {
      const platformData = platFormData.find((data) => data.value === id);
      return platformData || {};
    });
    setTotalData(updatedTotalData);

    // Set selected options based on editPlatformList
    setSelectedOption(
      platFormData.filter((option) => editPlatformList.includes(option.value))
    );
  }, [editPlatformList, platFormData, platform_id]);

  useEffect(() => {
    if (arr2.length > 0) {
      setError(false)
    }
  }, [arr2])



  const hanldeCall = (arr2) => {
    let apiUrl;
    let json;
    let newUpdatedArr = arr2.map((item) => {
      return {
        id: item.value,
        status: item.status
      };
    });


      json = {
        clinician: {
          akou_id: org_id,
          org_name: orgName,
          email: email,
          role_type: 3,
          sub_line_txt: translation.Invite_temp_hcp_sub_line,
          dear_line_txt: translation.Invite_temp_dear_line,
          congrats_line1_txt: translation.Invite_temp_hcp_congratulation_line1,
          platform_type_txt: "Clinician",
          congrats_line2_txt: translation.Invite_temp_congratulation_line2,
          here_line_txt: translation.Invite_temp_here_line,
          signin_line_title_txt: translation.Invite_temp_signin_title_line,
          signin_line1_txt: translation.Invite_temp_hcp_signin_line1,
          platform_url: "https://clinicians.akunah.com/",
          platform_url_txt: "clinicians.akunah.com",
          signin_line2_txt: translation.Invite_temp_signin_line2,
          signup_line_title_txt: translation.Invite_temp_signup_title,
          signup_line1_txt: translation.Invite_temp_signup_line1,
          signup_line21_txt: translation.Invite_temp_signup_line21,
          signup_line22_txt: translation.Invite_temp_signup_line22,
          signup_line3_txt: translation.Invite_temp_signup_line3,
          signup_line4_txt: translation.Invite_temp_hcp_signup_line4,
          experience_line_txt: translation.Invite_temp_experience_line,
          thrilled_line_txt: translation.Invite_temp_thrilled_line,
          regards_line_txt: translation.Invite_temp_regards_line,
          team_line_txt: translation.Invite_temp_team_line,
        },
        med: {
          akou_id: org_id,
          org_name: orgName,
          email: email,
          role_type: 3,
          sub_line_txt: translation.Invite_temp_hcp_sub_line,
          dear_line_txt: translation.Invite_temp_dear_line,
          congrats_line1_txt: translation.Invite_temp_hcp_congratulation_line1,
          platform_type_txt: "MED",
          congrats_line2_txt: translation.Invite_temp_congratulation_line2,
          here_line_txt: translation.Invite_temp_here_line,
          signin_line_title_txt: translation.Invite_temp_signin_title_line,
          signin_line1_txt: translation.Invite_temp_hcp_signin_line1,
          platform_url: "https://med.akunah.com/",
          platform_url_txt: "med.akunah.com",
          signin_line2_txt: translation.Invite_temp_signin_line2,
          signup_line_title_txt: translation.Invite_temp_signup_title,
          signup_line1_txt: translation.Invite_temp_signup_line1,
          signup_line21_txt: translation.Invite_temp_signup_line21,
          signup_line22_txt: translation.Invite_temp_signup_line22,
          signup_line3_txt: translation.Invite_temp_signup_line3,
          signup_line4_txt: translation.Invite_temp_hcp_signup_line4,
          experience_line_txt: translation.Invite_temp_experience_line,
          thrilled_line_txt: translation.Invite_temp_thrilled_line,
          regards_line_txt: translation.Invite_temp_regards_line,
          team_line_txt: translation.Invite_temp_team_line,
        },
        platforms: newUpdatedArr
      };

      console.log("jsonjsonjson for paltforms",json)
      apiUrl = `extorg/users/updatePlatform/${doctor_id}/${org_id}/${ak_id}`
      axiosInstance.current.put(
        apiUrl, json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then((res) => {
        setMessage("Platform Updated")
        setMessageType("success");
        dispatch(noteRefs(new Date().getSeconds()))
        setTimeout(() => {
          setMessage(false)
        }, 2000);
      }).catch((err) => {
        dispatch(noteRefs(new Date().getSeconds()))
        setMessage("Platform Not Updated")
        setMessageType("error");
      });
    
  }











  const handleSelectChange = (selectedValues) => {
    console.log("selectedValues for platt", selectedValues)

    if(selectedValues.length===0){
      setSelectedOption(selectedValues);
      setError(true)
      return
    }else{
      setSelectedOption(selectedValues);
      setArr2(selectedValues.map((ele) => ele.value))
  
      const newArr = arr.map((item) => {
        const newOptions = selectedValues.find(
          (option) => option.value === item.value
        );
  
        if (newOptions) {
          return { ...item, status: 1 };
        } else {
          return { ...item, status: 0 };
        }
      });
  
      setArr(newArr);
      hanldeCall(newArr)
    }

   
  };

  console.log("arr2", arr2)





console.log("totalData_new_total",totalData,platform_id)


  return (
    <>
      {message && <Message message={message} type={messageType} />}

      <Select
        value={selectedOption}
        isMulti
        onChange={handleSelectChange}
        options={totalData}
        className="multi_select_drp_select"
        placeholder="Select Platforms"
      />
      {
        error ? (
          <span className="text-danger mt-2">
            Please Select at least one platform</span>
        ) : ("")
      }
    </>
  )
}

export default SelectPlatForm
