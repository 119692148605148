import React, { useState, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";

import { validateCheckbox } from "../../../utilities/Validator";

const Checkbox = forwardRef(
  ({ label, selected, styleClass, validators, onChange, id , link,disabled}, ref) => {
    const [error, setError] = useState(false);

    const handleChange = (event) => {
      const { checked } = event.target;
      setError(validateCheckbox(validators, checked));
      onChange(checked);
      console.log("checkbox", event.target);
    };
    useImperativeHandle(ref, () => ({
      errUpdate(e) {
        setError(validateCheckbox(validators, ""));
        console.log("err", validators, selected);
      }
    }))
    

    return (
      <div className={`form-group ${styleClass}`}>
        <input
          type="checkbox"
          className="mr-2"
          value={selected}
          // defaultChecked={selected}
          checked={selected}
          onChange={handleChange}
          id={id}
          disabled={disabled}
        />
        <label htmlFor={id}>{label}  
        {link}
         </label>
        {error && <span className="text-danger">{error.message}</span>}
      </div>
    );
  }
);

Checkbox.propTypes = {
  styleClass: PropTypes.string,
  selected: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

Checkbox.defaultProps = {
  styleClass: "",
};

export default Checkbox;
