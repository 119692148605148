import React, { useState } from "react";
import "./Spinner.css";
const LoadingSpinner = () => {
  return (
      <div className="loader-box">
        <div className="loading"></div>
      </div>
  );
};

export default LoadingSpinner;
