// import { useSelector } from "react-redux";
import moment from "moment-timezone";
export const UserDateFormat = (date, dateformate) => {
  // const login = useSelector((state) => state.mainReducer.loginData.arr);
  if (dateformate == "1") {
    // "DD/MM/YYYY"
    return new Date(date).toLocaleDateString("en-GB", { timeZone: "UTC" });
  }
  if (dateformate == "2") {
    let mmdd = new Date(date).toLocaleDateString("en-GB", { timeZone: "UTC" });
    // "MM/DD/YYYY"
    let y = mmdd.slice(6, Infinity);
    let m = mmdd.slice(3, 6);
    let d = mmdd.slice(0, 3);
    return `${m}${d}${y}`;
  } else {
    let mmdd = new Date(date).toLocaleDateString("en-GB", { timeZone: "UTC" });
    // "YYYY/MM/DD"
    let y = mmdd.slice(6, Infinity);
    let m = mmdd.slice(3, 6);
    let d = mmdd.slice(0, 2);
    return `${y}/${m}${d}`;
  }
};

export const DateFormate = (item) => {
  return `${
    new Date(item).getDate() + 1 >= 10
      ? `${new Date(item).getDate()}`
      : `0${new Date(item).getDate()}`
  }/${
    new Date(item).getMonth() + 1 >= 10
      ? `${new Date(item).getMonth() + 1}`
      : `0${new Date(item).getMonth() + 1}`
  }/${new Date(item).getFullYear()}`;
};

export const yearDate = (item) => {
  return new Date().getFullYear() - new Date(item).getFullYear();
};

export const DobFormeat = (dobformeat) => {
  let y = dobformeat.slice(0, 4);
  let m = dobformeat.slice(5, 7);
  let d = dobformeat.slice(8, 10);
  return `${y}-${m}-${d}T10:00:00.000Z`;
};

const dateChange = (i, timeZone) => {
  return moment(i)
    .tz(timeZone)
    .format("YYYY-MM-DDTHH:mm:ss");
};

export const dateChangeByTimezone = (i, timeZone) => {
  return moment(i)
    .tz(timeZone)
    .format("YYYY-MM-DDTHH:mm:ss");
};
export const HoursMinutesFormat = (d, s, timeZone) => {
  const timeString = dateChange(d, timeZone);
  const date = new Date(timeString);
  const time = date.toLocaleString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: s,
  });
  return time;
};

export const dateChangeUtc = (da) => {
  let strStartdate = String(da);
  let startdateConv = `${strStartdate.slice(0, 3)}, ${strStartdate.slice(
    4,
    7
  )} ${strStartdate.slice(8, 10)}, ${strStartdate.slice(11, 15)}`;
  return startdateConv;
};
